import React, { useContext } from 'react'
import { Col, Image, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import { requestData } from '../../utils/baseUrl';
import { AuthContext } from '../../context/AllContext';
import LoadingSpinner from '../LoadingSpinner';
import HeadingNew from '../AboutUs/HeadingNew';

function ComboImageSection() {

    const { isDesktopOrLaptop, isBigScreen, isTablet, isMobile } = useContext(AuthContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [courses, setCourses] = useState([])
    const getCourses = async () => {
        setLoading(true)
        const res = await requestData("latestCourseList", "POST", {
            start_index: 0,
            no_of_records: 20
        });
        setLoading(false)
        if (res && res.error === false) {
            setCourses(res.data.
                filter((course, i) => course.course_id !== course.course_id !== "unstress-toolbox")?.
                sort((a, b) => Number(a.precedence) - Number(b.precedence)));
        }
    }


    useEffect(() => {
        getCourses()
    }, [])


    if (loading) {
        return (
            <LoadingSpinner />
        )
    }
    return (
        <div className='container d-flex flex-column justify-content-center align-items-center align-content-center' style={{ marginTop: isMobile ? "60px" : "100px" }}>
            {/* <p className='text-center' style={{fontSize:isDesktopOrLaptop?"3.5rem":"2rem",fontWeight:"700",color:"black"}}>Silva Method Membership</p> */}
            <HeadingNew title={"Explore Silva Mind Control Courses"} />
            <div className='mt-2'>
                <HeadingNew title={"Get Full Access - All Silva Method "} colored={"Home Study Courses"} small />
            </div>
            {/* <h2
                style={{
                    fontWeight: "800",
                    fontSize: isBigScreen ? "40px" : isDesktopOrLaptop ? "40px" : isTablet ? "30px" : "20px",
                    margin: "0 0 0 20px",
                    textAlign: "center",
                    marginTop: "10px",
                    color:"#000"
                }}
            >

                <span style={{ color: "#801cb6", fontWeight: "800", fontFamily: "Poppins" }}>Get Full Access</span>{" "} 
            </h2> */}

            <h5 className="mt-3 mb-3 text-center robotofont" style={{
                width: isMobile ? "95%" : "80%",
                lineHeight: isMobile ? "1.3rem" : "1.5rem",
            }}>
                America’s 1st Personal Growth Program Since 1966: Discover the New You!
            </h5>

            {/* <p className='text-center mt-2' style={{ fontSize: isDesktopOrLaptop ? "1.5rem" : "1.1rem", fontWeight: "700", color: "black" }}>
               
            </p> */}

            <div className='container mt-3 mb-5'>
                <Row className='justify-content-center align-items-center gy-2'>

                    {
                        courses.filter((course, i) => course?.course_id !== 'top-mental-training').map((val, i) => (
                            <Col xs={6} sm={6} md={4} key={i}>
                                <div className="card-image-container2" style={{ animationDelay: `${i * 0.5}s` }}>

                                    <img className='imghover' src={val.web_image || "https://silvamethod.com/manage/assets/uploads/noimg.jpg"}
                                        fluid rounded
                                        style={{
                                            width: "98%",
                                            borderRadius: "8px"
                                        }}
                                        onClick={() => navigate("/store/course/" + val.course_id)} />
                                </div>

                            </Col>
                        ))
                    }
                </Row>
            </div>
        </div>
    )
}

export default ComboImageSection