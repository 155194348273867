import React, { useMemo } from 'react'
import HeadingNew from '../../AboutUs/HeadingNew'
import { Badge, Card, Col, Row } from 'react-bootstrap'

function BenefitsFree() {
    const data = useMemo(()=>{
      return [
        {
            day:1,
            title:"Relaxation Meditation",
        },
        {
            day:2,
            title:"Gratitude Meditation",
        },
        {
            day:3,
            title:"Imagination/ Visualisation meditation"
        },
        {
            day:4,
            title:"De-stress/ Stress-relief Meditation"
        },
        {
            day:5,
            title:"Mindfulness Meditation"
        },
        {
            day:6,
            title:"Manifestation Meditation"
        },
        {
            day:7,
            title:"Healing Meditation"
        }
      ]
    },[])
    return (
        <div className='d-flex justify-content-center align-items-center'>
            <div className='container'>
                <HeadingNew small title={"Meditations of"} />
                <HeadingNew title={"7 Day Free Masterclass"} />

                <Row className='justify-content-center align-items-center my-5 gy-3'>
                    {
                        data.map((val,i)=>(
                            <Col xs={12} md={6} lg={4}>
                            <Card style={{
                                width: "95%",
                                margin:"auto",
                                borderRadius:"15px"
                            }}
                            className='benefitsfreecard'
                            >
                                <Card.Body style={{
                                    width: "100%",
                                    borderRadius:"15px"
                                }}>
                                    <Badge bg='warning' className='dancing-script2 mb-2' style={{
                                        color: "black",
                                        fontSize:"20px"
    
                                    }}>
                                        Day {val.day}
                                    </Badge>
                                    <Card.Title className='mt-2' style={{
                                        color: "white",
                                        fontWeight: "bold",
                                        fontSize: "1.5rem"
                                    }}>
                                        {
                                            val.title
                                        }
                                    </Card.Title>
                                  
                                  
                                </Card.Body>
                            </Card>
    
                        </Col>
                        ))
                    }

                </Row>


            </div>

        </div>
    )
}

export default BenefitsFree