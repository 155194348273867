import React, { useContext } from 'react'
import { Container, Row, Col, Form } from 'react-bootstrap';
import { AuthContext } from '../../../context/AllContext';
import ParaComp from '../../SilvaManifestationProgram/ParaComp';
import { MdCheckCircle } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import { scrollIntoView  } from "seamless-scroll-polyfill";
import { polyfill } from "seamless-scroll-polyfill";

function VideoBackground() {
   const { isDesktopOrLaptop, isBigScreen,isMobile } = useContext(AuthContext)
   
   const handleScroll = () => {
      polyfill()
      const cardscroll = document.querySelector(".newsec");

      if (cardscroll) {
         //  cardscroll.scrollIntoView({
         //      behavior: "smooth"
         //  })
         setTimeout(()=>{
            scrollIntoView(cardscroll,{
               behavior: "smooth",
               block: "end", inline: "nearest"
           });
         },300)
      }
  }
   return (
      <div className='herosectioncombo2' style={{
         paddingTop: isMobile?"150px":"100px",
         paddingBottom: "50px"
      }}>
         <div className='container m-auto' >
            <Row className='justify-content-center align-items-center' style={{


            }}>
               <Col xs={12} md={7} lg={8} style={{

                  padding: "0px",
                  margin: "0px",

                  height: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
               }}>


                  <div style={{
                     paddingLeft: isMobile?"20px":"50px",
                     paddingRight:isMobile?"20px":"50px",
                  }}>
                     <h1 className='mb-3' style={{ fontWeight: "800", fontSize: (isDesktopOrLaptop || isBigScreen) ? "2.3rem" : "1.5rem", textAlign: "start", color: "#fff" }}>
                        7 Day FREE Transformation Challenge  <br />  with <span style={{ color: "#ffeb00", fontWeight: "800", fontFamily: "Poppins" }}>The Silva Method</span>
                     </h1>
                     <ParaComp
                        data={[
                           "Join our 7-Day Challenge and commit yourself to self-growth and personal transformation. Receive a free guided meditation each day and kickstart your journey to transformation today with Silva Method."
                        ]}
                     />

                     <div className='d-flex flex-column gap-3'>
                        <div className='d-flex gap-2 flex-wrap'>
                           <span>
                              <FaCheckCircle color='#22bb33' size={25} />
                           </span>
                           <p style={{
                              margin: "0px",
                              padding: "0px",
                              width:isMobile?"80%":"auto",
                              color: "white",
                              fontSize:isMobile?"0.85rem":"1rem",
                              lineHeight:isMobile?"20px":"auto"

                           }}>
                              Submit the form to receive your first guided meditation instantly.
                           </p>


                        </div>
                        <div className='d-flex gap-2 flex-wrap'>
                           <span>
                              <FaCheckCircle color='#22bb33' size={25} />
                           </span>
                           <p style={{
                              margin: "0px",
                              padding: "0px",
                              width:isMobile?"80%":"auto",
                              color: "white",
                              fontSize:isMobile?"0.85rem":"1rem",
                              lineHeight:isMobile?"20px":"auto"

                           }}>
                              Track your daily transformation with our provided tracker sheet.

                           </p>



                        </div>
                        <div className='d-flex gap-2 flex-wrap'>
                           <span>
                              <FaCheckCircle color='#22bb33' size={25} />
                           </span>
                           <p style={{
                              margin: "0px",
                              padding: "0px",
                              width:isMobile?"80%":"auto",
                              color: "white",
                              fontSize:isMobile?"0.85rem":"1rem",
                              lineHeight:isMobile?"20px":"auto"

                           }}>
                              Reach out to us at digital@silvamethod.com for any difficulties during your journey.


                           </p>



                        </div>

                     </div>
                     <button className='btnnew22 mt-4' onClick={handleScroll}>
                        <svg
                           height="24"
                           width="24"
                           viewBox="0 0 24 24"
                           xmlns="http://www.w3.org/2000/svg"
                        >
                           <path d="M0 0h24v24H0z" fill="none"></path>
                           <path
                              d="M5 13c0-5.088 2.903-9.436 7-11.182C16.097 3.564 19 7.912 19 13c0 .823-.076 1.626-.22 2.403l1.94 1.832a.5.5 0 0 1 .095.603l-2.495 4.575a.5.5 0 0 1-.793.114l-2.234-2.234a1 1 0 0 0-.707-.293H9.414a1 1 0 0 0-.707.293l-2.234 2.234a.5.5 0 0 1-.793-.114l-2.495-4.575a.5.5 0 0 1 .095-.603l1.94-1.832C5.077 14.626 5 13.823 5 13zm1.476 6.696l.817-.817A3 3 0 0 1 9.414 18h5.172a3 3 0 0 1 2.121.879l.817.817.982-1.8-1.1-1.04a2 2 0 0 1-.593-1.82c.124-.664.187-1.345.187-2.036 0-3.87-1.995-7.3-5-8.96C8.995 5.7 7 9.13 7 13c0 .691.063 1.372.187 2.037a2 2 0 0 1-.593 1.82l-1.1 1.039.982 1.8zM12 13a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                              fill="black"
                           ></path>
                        </svg>
                        <span>Enroll For Free</span>
                     </button>
                  </div>

               </Col>
               <Col xs={12} md={5} lg={4}>
                  <div className='d-flex justify-content-center'>
                  <video src='/videos/Medi video.mp4' style={{
                     width: isMobile?"95%":"100%",
                     
                     margin:"0px",
                     padding: "0px",
                     borderRadius: "50px",
                     border: "10px solid rgba(0,0,0,0.3)",
                     marginTop:isMobile?"30px":"0px"
                  }}

                     autoPlay
                     loop
                     muted
                  />

                  </div>
               </Col>
            </Row>

         </div>
      </div>

   )
}

export default VideoBackground