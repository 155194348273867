import React from 'react'
import HeadingNew from '../AboutUs/HeadingNew'
import { useContext } from 'react'
import { AuthContext } from '../../context/AllContext'
import iphone from "../../images/badge_app_store.png"
import android from "../../images/badge_play_store.png"


function MobileSection() {
    const { isMobile } = useContext(AuthContext);

    return (
        <>
            <div style={{
                marginTop: "100px"
            }}>
                <HeadingNew title={"Silva Method, Anytime, Anywhere!"} />
            </div>
            <div className='mt-2'>
                <HeadingNew title={"We are Now at Your Fingertips on "} colored={"iOS & Android!"} small />
            </div>
            <div className='d-flex justify-content-center align-items-center'>
                <h5 className='mt-2 robotofont' style={{
                    fontWeight: "600",
                    margin: "0",
                    fontSize: isMobile ? "0.9rem" : "1rem",
                     lineHeight: isMobile ? "1.3rem" : "1.5rem",
                    textAlign: "center",
                    maxWidth: isMobile ? "90%" : "60%",
                    padding: "0",
                    lineHeight: "25px",


                }}>
                    Learning and growing with the Silva Method just got easier! Whether you're commuting, relaxing at home, exploring new places, or at work, our app on iOS and Android gives you the freedom to dive into your journey whenever and wherever life takes you.
                </h5>
            </div>
            <div class="containermobilenew">
                <div class="box box1new">
                    <img className='imgmobilenew' src="/img/bg/bghero/app-swection-mobile-img-iOS.webp" alt="" />
                </div>
                <div class="box box2new">
                    <img className='imgmobilenew' src="/img/bg/bghero/available-on-web.webp" alt="" />
                </div>
                <div class="box box3new">
                    <img className='imgmobilenew' src="/img/bg/bghero/without-internet.webp" alt="" />
                </div>

               
            </div>
            <div className="d-flex justify-content-center align-items-center gap-3">
                    <a href="https://apps.apple.com/us/app/silva-method/id6478581512"><img src={iphone} alt="#" loading="lazy" /></a>
                    <a href="https://play.google.com/store/apps/details?id=com.silvamethod"><img src={android} alt="#" loading="lazy" /></a>
                </div>
        </>

    )
}

export default MobileSection