import React from 'react'
import { Badge, Card, Col, Row } from 'react-bootstrap'
import HeadingNew from '../../AboutUs/HeadingNew'
import ReactStars from 'react-stars'
import "./mindcontrol.css"
import { useContext } from 'react'
import { AuthContext } from '../../../context/AllContext'

function HeroSectionBook({handleShow}) {

    const {isMobile} = useContext(AuthContext);

    return (
        <div className='silvabookbg'>
            <div className='container'>
            <Row className='justify-content-center align-items-center' style={{
                margin:isMobile?"20px":0
            }}>
                    <Col xs={12} md={6}>
                        {/* <HeadingNew title={"The Silva Mind"} start white /> */}
                        <div style={{
                            position: "relative"
                        }}>
                             <div style={{
                                width: "100px",
                                height: "30px",
                                backgroundColor: "rgba(0,0,0,0.5)",
                                borderRadius: 5,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "white",
                                marginBottom:"15px"
                            }}>
                                Original
                            </div>
                            <h1 style={{fontSize:"40px",color:"#fff", fontWeight:"700",marginBottom:"15px"}}>The Silva Mind Control Method</h1>
                            {/* <HeadingNew title={"Control Method"} start white /> */}
                           
                        </div>

                        <div className='d-flex gap-3 justify-content-start align-items-center my-3'>
                            <button className='herodiv-becomememberbtn3'>
                                By Jose Silva

                            </button>
                            <p className='white-color' style={{
                                padding: 0,
                                margin: 0,
                                lineHeight: "1.2"
                            }}>99 ways to revolutionize <br /> Your Existence</p>

                        </div>
                        <p className='white-color' style={{
                            lineHeight: "1.4"
                        }}>
                            The human mind holds immense surprises and untapped potential. But often, we are un aware of our innate abilities and the impact we can make on society.
                        </p>
                        <p className='white-color' style={{
                            lineHeight: "1.4"
                        }}>
                            This incident may prompt you to contemplate your untapped potential. Could you harness your abilities to earn more, enhance your standard of living, and move beyond your current waitressing job? Perhaps you realize that your talents lie beyond the role that this job provides, and envision yourself as the person sitting at the table, engaging in conversations about stocks and business. This realization opens up new possibilities and encourages you to explore avenues that align with your untapped potential.
                        </p>
                        <p className='white-color' style={{
                            lineHeight: "1.4"
                        }}>
                            Consider a scenario where you work as a waiter in a restaurant. Although you excel at this job and it pays well, you unexpectedly find yourself engaged in a conversation between two customers struggling with investment decisions. Utilizing your natural instincts, you provide them with an insightful overview of long-term benefits in stock investments, simplifying their decision-making process.
                        </p>
                        <p className='white-color' style={{
                            lineHeight: "1.4"
                        }}>
                            <b>
                                Silva Mind Control Method, founded by Joses Silva In 1962, is a self-transformation practice. It is a technique, practicing which, one can train their minds at a subconscious level and help themselves to achieve greater success in their life.
                            </b>
                        </p>


                    </Col>
                    <Col xs={12} md={6}>
                        <div style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center"
                        }}>
                            <Card style={{
                                width: isMobile?"100%":"85%",
                                padding: isMobile?"15px":"25px",
                                borderRadius: "25px"
                            }}>
                                <Card.Body>
                                    <Card.Img src='/img/bg/img-05.webp' style={{
                                        borderRadius: "25px"
                                    }} />
                                    <Card.Title className='mt-3' style={{
                                        color: "black",
                                        fontWeight: "600"
                                    }}>
                                        The Silva Mind Control Method Book
                                    </Card.Title>
                                    <Card.Subtitle className='text-muted'>Jose Silva</Card.Subtitle>
                                    <div className='d-flex gap-4 justify-content-start align-items-center flex-wrap mt-2'>
                                        <span style={{
                                            fontWeight: "700",
                                            color: "black",
                                            fontSize: "1.2rem"
                                        }}>4.7</span>
                                        <ReactStars
                                            count={5}
                                            value={4.7}
                                            edit={false}
                                            color2='#ffa534'
                                            size={24}
                                        />

                                        <div>
                                        <span className='text-muted' style={{
                                            borderRight:"2px solid lightgray",
                                            paddingRight:15,
                                            fontWeight:"500"
                                        }}>
                                            247,075 ratings
                                            
                                        </span>
                                        <span className='text-muted' style={{
                                             paddingLeft:15,
                                            fontWeight:"500"

                                        }}>
                                        14,923 reviews
                                        </span>
                                        </div>
                                    </div>


                                    {/* stack of images */}

                                    <div className='d-flex gap-3 mt-3 flex-wrap'>
                                    <div class="avatars" style={{
                                        position: "relative"
                                    }}>
                                        <span class="avatar">
                                            <img src="img/icon/stockusers/stockuser6.jpg" width="40" height="40" />
                                        </span>
                                        <span class="avatar">
                                            <img src="img/icon/stockusers/stockuser5.jpg" width="40" height="40" />
                                        </span>
                                        <span class="avatar" >
                                            <img src="img/icon/stockusers/stockuser4.jpg" width="40" height="40" />
                                        </span>
                                        <span class="avatar">
                                            <img src="img/icon/stockusers/stockuser3.jpg" width="40" height="40" style={{
                                                zIndex: 2
                                            }} s />
                                        </span>
                                        <span class="avatar">
                                            <img src="img/icon/stockusers/stockuser2.jpg" width="40" height="40" style={{
                                                zIndex: 3
                                            }} />
                                        </span>
                                        <span class="avatar">
                                            <img src="img/icon/stockusers/stockuser1.jpg" width="40" height="40" style={{
                                                zIndex: 4
                                            }} />
                                        </span>
                                        <div className='fadewhite'>

                                        </div>
                                    </div>
                                    <p className='text-muted mt-1' style={{
                                        fontWeight:"700"
                                    }}>
                                        10M+ people read this book.
                                    </p>
                                    </div>

                                    <button className='herodiv-becomememberbtn4 mt-3' onClick={handleShow}>
                                        {/* <a href="/documents/The_Silva_Mind_Control_Method_Book.pdf" className='text-decoration-none text-dark'> */}
                                            Want to read
                                            {/* </a> */}
                                    </button>


                                </Card.Body>
                            </Card>

                        </div>

                    </Col>

                </Row>

            </div>

        </div>
    )
}

export default HeroSectionBook