import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import Masonry from "react-masonry-css";
import ReactStars from "react-stars";
import { Fade } from "react-awesome-reveal";
import TestimonialCard from "../../newbooking/TestimonialCard";
import { useState } from "react";

const breakpointColumnsObj = {
  default: 3,
  1100: 2,
  700: 1,
};

const TestimonialGrid = () => {
  const [reviews, setReviews] = useState([]);

  const getreviews = async () => {
    const myHeaders = new Headers();
    myHeaders.append("ApiKey", "40bb9d38c66e40a86678979286f4e2b5");
    myHeaders.append("Device", "Web");
    myHeaders.append("Language", "english");

    const formData = new FormData();
    formData.append("start_index", "0");
    formData.append("no_of_records", "10");

    const country = localStorage.getItem("countryname");
    const currency = localStorage.getItem("currency");

    if (country) {
      myHeaders.append("country", country.toString());
    }

    if (currency) {
      myHeaders.append("currency", currency.toString());
    }
    const options = {
      method: "POST",
      headers: myHeaders,
      body: formData,
    };
    const res = await fetch(
      `https://silvamethod.com/manage/api/testimonialList`,
      options
    );
    const { data } = await res.json();
    //console.log(data.data.classes);
    // setReviews(data.data);

    const revi = data.map((val) => {
      return {
        type: "text",
        text: val.comment,
        image: val.image,
        name: val.name,
        designation: val.designation,
      };
    });
    const videoTestiomonials = [
      // {
      //   type: "video",
      //   text: "",
      //   image: "",
      //   video:
      //     "/videos/The Number One Method To Get Better Sleep And Improve Your Health.mp4",
      //   name: "",
      //   designation: "",
      // },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "/videos/My Fibromyalgia was healed through meditation and visualization. I was able to sit without pain!!!.mp4",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/e483775b-00be-4d08-98f3-f2cad0e4752b/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/7175f34c-af40-43ac-b565-44663239d18f/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/0ed3e928-7883-4357-8675-a46749627dbe/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/c2f5c60d-4126-422f-960d-b52055699057/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/3e4c2779-d471-4ee7-995c-9560369e0e20/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/113d1ae4-6646-48b8-94bc-b6e420e5308c/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/507fe8cf-f18f-4e38-92c1-bc7fddb91532/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/42a9baec-616b-4e75-9985-c4c441d58749/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/6b90eda0-48a8-448a-8b0b-9d4ab243c1f4/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/dfdedc9f-0ab2-40c5-80e7-1df5ceed009b/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/9ad6bb0e-e8b5-46da-a311-eb6f32d830f6/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/ffb3642c-9e31-4b3d-998a-2fee451f96bc/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/f85118d2-25b4-46f7-915d-3dc09fa2e56e/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/69b9fd6c-408c-49e2-9c83-1d8d38c7ce57/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/052a214f-5c9e-423f-bd18-755a89c5c223/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/98fcb9eb-122f-4e58-9817-981dbb48df05/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/d0bda075-45f7-4ba6-93af-80c5e998a887/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/576afb95-5ee9-4510-afb8-3d4be5ea7dbe/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/0e27e42e-7312-4c99-92a8-368463592ae5/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/13a54da2-19e2-4553-8e70-e8efed71d3da/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/3b7499db-9f57-47a0-9650-dc2e06b895f0/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/e7aa40e4-2149-4cc1-beff-962dcc6e7c48/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/5c3ca80f-2a6c-4199-b548-ea4c3f3d4f38/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/e0f17428-c008-4508-8940-d0d822f1fe16/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/9eae6aac-069a-4dd1-9dda-582c7029d5b3/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/34844380-9481-4ced-830d-576ddf4ce13b/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/b4f07226-4d11-4a79-b98a-923ad54d2178/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/23ae9d60-653e-424a-ac4f-89c7ece9db9f/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/42fc55b4-769e-4300-bf90-d8a769b7cf90/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/c5758fea-538a-43c8-bfd9-d2a55da1c37b/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/37a5dd15-b8cf-4e08-a095-8e13c35ca141/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/be2a5a1c-4e14-4144-b787-9bf333870074/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/b85e4528-0ba2-4b54-bd79-028020f90a64/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/a36f4a31-fa75-4b1f-8c8a-cc7280709bfd/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/dae84244-466c-41b8-8a14-be4b98034513/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/d5573927-cb7e-4339-b8da-b640222242b0/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/84e41eb1-17a1-4f2e-8e30-f17cf9c73add/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/7eb69234-1141-4dc2-ae9d-fa8b75487e24/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/e993e6fa-b421-4e4f-9ed5-d5f279eb443b/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/adb74194-c467-4775-a2c6-2bb62db476c3/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/a640c2a0-3d34-4979-8c78-38075709ac95/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/44dd2d77-9713-446d-8b1f-34be0758d35b/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/b6457194-5262-4a7f-b8f7-24d3e51bcb26/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/6ec68636-305a-4b15-aafa-16760f4ed1eb/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/857ca3c3-5969-4b6c-afa3-5b6f49da4cf7/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/f096fc4e-1346-4869-8579-3cd96652c911/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/bb3a7d49-b205-43a0-baa6-ccf57433a720/playlist.m3u8",
        name: "",
        designation: "",
      },
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/0c1eb7e6-0aa3-4154-9f87-4bc1329f5c30/playlist.m3u8",
        name: "",
        designation: "",
      },
    ];
    setReviews([
      {
        type: "video",
        text: "",
        image: "",
        video:
          "https://vz-47e4ab86-ce6.b-cdn.net/293850cf-822f-4ca2-8c97-948bafaa4940/playlist.m3u8",
        name: "",
        designation: "",
      },
      ...revi,
      {
        type: "text",
        text: "I achieved 13 pole positions and 13 wins in a row. To become a racing driver takes determination, confidence and money. To become a winning racing driver takes a bit more. My remarkable success is down to the personal empowerment techniques of The Silva Method. The Silva Method gives me supreme concentration and confidence which are both greatly needed in the very competitive sport of motor racing. I am now committed to using the Silva techniques for the rest of my career.",
        image: "/img/reviews/racer_rev.png",
        name: "Andy Priaulx MBE",
        designation: "British Racing Driver European Touring Car champion, three times World Touring Car champion",
      },
      ...videoTestiomonials,
      {
        type: "text",
        text: "I am very happy that I made the decision to attend the Silva Method course. The course provides great insight into how one's mind works and how we can use it to help ourselves and others around us. It was great to spend the weekend listening to Stephen; the sessions were very informative, open to questions from the audience, and there was not a boring second! Meditations were well-guided and explained, and we were welcomed to share our feedback and experiences.",
        image: '/img/Izabela Beden.jpg',
        name: "Izabela Beden",
        designation: "",
      },
      {
        type: "text",
        text: "I suffered from Dyslexia throughout my life until I applied the techniques I learned in the Silva Method. I am literally free of this life long handicap of dyslexia, learning to spell virtually overnight at the age of forty and developing a heightened ‘Spiritual Sensitivity’ that allowed me to write Unshriven, my first published book",
        image: '/img/June Kidd.jpg',
        name: " June Kidd",
        designation: "",
      },
      {
        type: "text",
        text: "I am able to dance, walk and drive my car again after being paralyzed in my left leg and arm for 14 years. I regained the full use of my left arm and leg after suffering a stroke. As a nurse, I have seen many stroke patients who never recovered. I am so grateful for learning the Silva Method. My life is transformed.",
        image: '/img/Betty Perry.jpg',
        name: "Betty Perry",
        designation: "",
      },
      {
        type: "text",
        text: "Before silva, my life had little direction - I couldn’t decide what I wanted to do or where I wanted to go when I finished school. I still don’t know what I want to be, but silva taught me that that’s okay! I am exploring a world of opportunities because of silva. I am entirely happy within myself and I have faith now in my body and my mind, that it will take me where I need to be.This next year will be a massive journey for me. I am going to be embarking on a four-month internship at the museum of my dreams, the Hermitage Museum in St. Petersburg - a position normally offered only to graduate students, and yet I am only just out of high school. I will also be volunteering at a school in Vietnam and working with a family in Florence - a year of discovery to say the least! I will no doubt be using the techniques I have learnt at Silva to help me get the best of all the wonderful places I will be visiting, working in and living in. I cannot recommend this course more, and with Irene especially - my guide and my inspiration.",
        image: '/img/SorayaB.jpg',
        name: "Soraya B",
        designation: "",
      },
      {
        type: "text",
        text: "I attended my first Silva Method Seminar in February 2013, and then another in April 2013. I used to suffer from sinuses, which only affected me in the evening and prevented me from getting a good night's sleep. After doing the silva method, I found I could focus and relax the area in my head where I felt the pressure building up. After practicing this a few times I haven’t had it since!!  Another health issue arose when I started to develop eczema on my right arm and around my neck and face.  The itching drove me nuts, and the medicine I was using did not seem to be working.  I found it impossible to stop scratching.  It was while I was busy scratching my arm that I realised how tense the area was. I used a Silva Method technique and noticed that the itching sensation went from unbearable to barely noticeable instantly.  I have also taught my daughter (13 years old) to use various techniques to heal her pimples and these have helped her immensely. ",
        image: '/img/Adrianne-Curry.jpg',
        name: "Adrianne",
        designation: "",
      },
      {
        type: "text",
        text: "I have found the Sleep Technique to be invaluable– it is great to be able to easily go to sleep and have a great night’s sleep!",
        image: '/img/shophia.jpg',
        name: "Sophia.S",
        designation: "",
      },
      {
        type: "text",
        text: "I learned the art of Silva meditation and it helps in positive thinking - Stardust Magazine, August 2003",
        image: '/img/sanjoydutt.jpg',
        name: "Sanjay Dutt",
        designation: "Bollywood Actor",
      },
      {
        type: "text",
        text: "Silva method can help in every area of life",
        image: "/img/testimonials/Naveen Jindal.jpg",
        name: "Naveen Jindal",
        designation: "Industrialist",
      },
      {
        type: "text",
        text: "I learned a lot about meditation from the Silva classes",
        image: "/img/testimonials/Amrish Puri.jpg",
        name: "Amrish Puri",
        designation: "Bollywood Actor",
      },
      {
        type: "text",
        text: "I have attended the Silva courses and it was a positive experience",
        image: "/img/testimonials/Aditya Chopra.jpg",
        name: "Aditya Chopra",
        designation: "Film Maker",
      },
      {
        type: "text",
        text: "For mental relaxation I practice Silva techniques daily",
        image: "/img/testimonials/sanjay swaraj.webp",
        name: "Sanjay Swaraj",
        designation: "Actor",
      },
      {
        type: "text",
        text: "I loved the Silva meditation techniques",
        image:"/img/testimonials/Aruna Irani.jpg",
        name: "Aruna Irani",
        designation: "Actor",
      },
      {
        type: "text",
        text: "Silva method can help bring direction and purpose in life",
        image: "/img/testimonials/kishore namit kapoor.jpg",
        name: "Kishore Namit Kapoor",
        designation: "Actor & Author",
      },
      {
        type: "text",
        text: "Through doing Silva method in 2000, I got drawn to the power of the mind",
        image: "/img/testimonials/rageswari loomba.jpg",
        name: "Raageshwari Loomba",
        designation: "Actor , Singer",
      },
      {
        type: "text",
        text: "Silva method is a great program",
        image: "/img/testimonials/Chitra Singh.jpg",
        name: "Chitra Singh",
        designation: "Singer",
      },
      {
        type: "text",
        text: "I apreciate the Silva meditation experience, meditation itself is the art of living.",
        image: "/img/testimonials/rhea pillai.jpg",
        name: "Rhea Pillai",
        designation: "Actress & Model",
      },
      {
        type: "text",
        text: "With the Silva Method, you can enhance and maintain your health and fitness.",
        image: "/img/testimonials/Yashovardhan Birla.jpg",
        name: "Yashovardhan Birla",
        designation: "Director, Yash Birla Group",
      },
      {
        type: "text",
        text: "Silva method can be beneficial for everyone",
        image: "/img/testimonials/Mallika Sarabhai.jpg",
        name: "Mallika Sarabhai",
        designation: "Dancer & Social Activist",
      },
      {
        type: "text",
        text: "Silva method techniques are simple but their effects are powerful- Dainik Jagaran, May 1997",
        image: "/img/testimonials/Ramanand Sagar.jpg",
        name: "Ramanand Sagar",
        designation: "Padmashri director of Ramayana TV series",
      },
      {
        type: "text",
        text: "Silva method is very helpful for attaining mental relaxation",
        image: "/img/testimonials/Tanuja.jpg",
        name: "Tanuja Mukherjee Samarth",
        designation: "Actor",
      },
      {
        type: "text",
        text: "You can transform your life with the Silva Method",
        image: "/img/testimonials/Ashish Vidyarthi.jpg",
        name: "Ashish Vidyarthi",
        designation: "Actor",
      },
      {
        type: "text",
        text: "I find Silva method helps in reducing mental stress and attaining peace of mind.",
        image: "/img/testimonials/Dr Balakrishna V. Doshi.jpg",
        name: "Dr Balakrishna V. Doshi",
        designation: "Padma Bhushan & Pritzker awardee architect",
      },
      {
        type: "text",
        text: "I wish I had done Silva earlier in life as it is so beneficial.",
        image: "/img/testimonials/helen richardson khan.jpg",
        name: "Helen Richardson Khan",
        designation: "Padma Shri film actress & dance legend",
      },
      {
        type: "text",
        text: "It is over 30 years since I first took the Silva Method course and figured I had got value for money. Over the years since I have had experience of a fair few course facilitators in repeat sessions. I have found Claire Lombard to be an excellent and capable instructor of the Silva Method and I have also taken instruction in Yoga and French language from her with positive results.",
        image: '/img/Aongus MacCana.jpg',
        name: "Aongus MacCana",
        designation: "Retired at Údarás na Gaeltacht",
      },
      {
        type: "text",
        text: "Visualization, mental rehearsal, stress management, and intuition are as much a part of an athlete's training regimen as diet, exercise, and sport specific practice. The Silva System allows instant access to these mental techniques.",
        image: '/img/miller.jpg',
        name: "Lance R. Miller",
        designation: "International Shooting Coach; Director, Fla. State Junior Olympic Camp; 1995, USA Shooting, Coach of the Year",
      },
      {
        type: "text",
        text: "I was travelling on Ryan Air when it was announced that passengers could go for a draw. The winner would win two air flight tickets for Italy; So I decided now and there that I would like to win those tickets and that it was ok for me for it to happen. So I went to my level and programmed for it and guess what... Wasn't I announced to be the lucky winner!",
        image: '/img/ursula.jpg',
        name: "Ursula",
        designation: "The Wholistic Clinic of Medecine, Galway",
      },
      {
        type: "text",
        text: "I found the Silva Method programme a life changing experience; it has helped me immensely with reducing my stress levels and sleeping at night, something I had not been able to do for a long time.The Silva Method helps me think with a much greater degree of clarity and focus, thus helping me to solve any problem I face with tremendous results. I would recommend this programme to anybody.",
        image: '/img/ger.jpg',
        name: "Ger",
        designation: "Company Director, co Clare",
      },
      {
        type: "text",
        text: "There was a discussion between myself and another person and when I tried to explain my point of view they totally dismissed it and didn't agree with me. That night I went to my level and I explained the situation to this person again, using the Mirror of the Mind Technique. The following day she told me that she now understood where I was coming from the previous day and understood what I was trying to say. I also use the three fingers technique to get parking spaces.",
        image: '/img/K. Galway.jpg',
        name: "K. Galway",
        designation: "",
      },
      {
        type: "text",
        text: "Since I have taken the course and that I go to my level everyday, life seems to unfold naturally and effortlessly.",
        image: '/img/pat.jpg',
        name: "Pat, Longford",
        designation: "",
      },
      {
        type: "text",
        text: "I went for a top up in the teeth of my last exam and fellow students thought I was mad as I needed all my spare time for studying. During the exam, the minute something wouldn't come into my head I used my Three Fingers Technique which is one of the Silva techniques which has never let me down.",
        image: '/img/annaalen.jpg',
        name: "Anna Allan",
        designation: "70 year old graduate in M. Lit feminist at NUIT Galway",
      },
      {
        type: "text",
        text: "I used the long relax prior to taking my exams for my degree course. It really helped me relax and I became far more focused. And then on the days of the exams I felt totally clear focused. I also found the techniques invaluable for memorising points and lists in the exam situation. Overall, in every day life, since I have been using the techniques I have found myself to be far more positive.",
        image: '/img/kathy.jpg',
        name: "Kathy, Castlebar",
        designation: "",
      },
      {
        type: "text",
        text: "When I was sick with cancer and on treatment I would use the Long Relax for total and healing relaxation, adding the Pain Control Technique to minimise the pain. The results were terrific. I was told I was a model patient at the hospital. I now use the techniques mostly on the road to have an uneventful journey and to be moving the traffic along: I set duration for my travel and using the techniques always find that I achieve my goal, arriving safely, relaxed and on time. I use the sleep technique whenever I'm restless to have good quality sleep. Also, I sometimes have to travel long distances to attend meetings so, when I know I'm going to have a very short night sleep I use the Long Relax to improve the quality of sleep in order to be alert whilst driving and during the meeting.",
        image: '/img/annmartin.jpg',
        name: "Ann Martin",
        designation: "Nurse, Galway",
      },
      {
        type: "text",
        text: "During the course we were asked to focus on someone we really liked so I did so on my present wife whom I didn't really know that well but whom I felt drawn to. We then got to know each other better... and you now know the end of the story! It has turned out to be a most positive step in my life.",
        image: '/img/sean.jpg',
        name: "Sean,co Galway",
        designation: "",
      },
      {
        type: "text",
        text: "My Mum was trying to contact my brother in London but none of us knew to where to reach him. So I used my Three Fingers Technique and there he was in the middle of London, in front of my car; and just at that time the traffic lights turn red which gave us time for him to come to my door and give me his phone number!",
        image: '/img/Giselle.jpg',
        name: "Giselle",
        designation: "Galway",
      },
      {
        type: "text",
        text: "I used to get uptight quite easily but since I have taken the Silva Course I have found myself to be far more relaxed and at work, my colleagues who would know me to lose my temper, are surprised as I don't anymore! Overall the days seem to flow more easily.",
        image: '/img/sean cork.jpg',
        name: "Sean,co Cork",
        designation: "",
      },
      {
        type: "text",
        text: `Thank you very much for a very insightful weekend. Last night after the course I went to help my brother at his house... he is renovating. I found myself facing the door to his garden shed in the dark with the massive bunch of keys that he has. I needed to find the correct key to the shed. I decided to use one of the tools I learned to find the right key so I used the 3 finger technique and said "I will pick out the correct key first time"...and guess what?... Instantaneously I picked out the right key...Looking forward to using the rest of the tools!`,
        image: '/img/M. Reid.jpg',
        name: "M. Reid",
        designation: "Dublin",
      },
      {
        type: "text",
        text: `Thank you for a lovely experience during the weekend. As a result I had a super duper parent teacher meeting. I felt all interactions between myself and each set of parents were very fruitful. I wasn't worn out at the end of the night as I have felt before. The whole evening was more positive and I could see the parents blossoming with all the positive news and giving me back useful feedback. The school day has also become more productive. I really believe that even after using the phrase "You are really learning/concentrating/listening etc. better and better every day" for two days so far the atmosphere in the classroom has become more focused and attentive. Thank you so much again and I'll write again to let you both know how the memory pegs and three-finger technique works with the children". Follow-on success for Melissa..."I received brilliant news this morning. I have an interview this Saturday for a permanent job in Le Chéile-Educate Together school. I have wanted to work in this type of school for some time. So plenty of visualisation and 3 fingered technique until this Saturday. I can't wait to work there.`,
        image: '/img/Melissa.jpg',
        name: "Melissa",
        designation: "Primary School Teacher, Dublin",
      },
      {
        type: "text",
        text: "Using the Silva Method is not just a beautifully r elaxing way to be, it is much more than that. It helps you to remain positive, focused and healthy whatever happens. You will not become invincible but it will certainly give you the feeling that you are better equipped to deal with life, help yourself and even others in any situation (illnesses, decisions, crisis, etc.) `If there is one thing I always recommend to my friends for personal development it is to book the next Silva course.",
        image: '/img/zsofia.jpg',
        name: "Zsofia Puszta",
        designation: "Multinational IT Company, Dublin",
      },
      {
        type: "text",
        text: "Golf was the reason I came to the Silva course. My game definitely improved. The unexpected surprise is freedom from the cloud of depression that hung over me for the past 15 years.",
        image: '/img/kenshephard.jpg',
        name: "Ken Shepard",
        designation: " Sport Enthusiast",
      },
      {
        type: "text",
        text: "I was in dropout prevention classes from second grade through the fifth grade. During the second semester of fifth grade I came to the Silva course. I scored high on the FCAT and all of my other tests. I am now in regular or advanced classes and doing very well.",
        image: require('../../../images/profile-picture-vector.jpg'),
        name: "S.B. January",
        designation: "Student",
      },
      {
        type: "text",
        text: "There is no way to estimate the value of the Silva Method principles in the conduct of a new business. The power of the deep levels of mind to solve practical problems, bring forth creative new product ideas, and aid in positive employer-employee relationships is fantastic. We have found the Silva Method of Mind Development to be the most grounded, practical, accessible and easy method of all.",
        image: '/img/John and Rita Donohue.jpg',
        name: "John and Rita Donohue",
        designation: " Entrepreneur",
      },
      {
        type: "text",
        text: "As an epigeneticist, I know how our minds can influence our biology. If you want to learn how to have the mind be your friend in creating miracles, this course is the one. We are all so powerful, but forget this, and this course actually reminds you how to be powerful. Katarzyna, is an amazing facilitator, who has the ability to teach this content magnificently. She is compassionate, loving and so approachable. I not only enjoyed the course, but a few days after and I am already feeling the benefits of it on so many levels.",
        image: '/img/Dr Charan Surdhar.jpg',
        name: "Dr Charan Surdhar",
        designation: "",
      },
      {
        type: "text",
        text: "Invest in yourself by attending Kasia’s Silva courses! You will manifest many positive returns, even better and beyond your original intent. As a business professional, I sought from the Silva Method tools for increasing productivity and efficiency; techniques for learning, retaining, and recalling large amounts of technical knowledge; the capacity to stay relaxed, focused and in “flow” in high pressure situations; and the ability to serve my company, coworkers and clients more profoundly by tapping in to their true needs and concerns. Kasia opened the door to all of these aspirations! Her connections to and understanding of the business world make her an excellent coach for those seeking business related goals. But I promise you will get more – you will gain insight into yourself and faculties for enriching all aspects of your life. While my initial intent was business related, the Silva techniques in conjunction with my personal beliefs have rekindled my awareness of the beautiful mysticism of each present moment. Thank you, Kasia, for all you do!.",
        image: '/img/Reba Bush.jpg',
        name: "Reba Bush",
        designation: "",
      },
      {
        type: "text",
        text: `Accidentally or not, I came across a book called the Silva Mind Control Method by Jose De Silva. After I read the book, I signed up for Silva Life & Intuition System seminars by Katarzyna Ostasz (Mindset Coach & Business Trainer Certified Silva Method Instructor) in Dublin.
        As an artist, I often have a creative block, and I was looking forward to learning how to enter the Alpha level and then using my creative mind to help me enhance creativity or let inspiration flow.
        I can say the seminar exceeded all my expectations. I found all elements of Silva’s Life & System Intuition seminar interesting to explore. Katarzyna explanations, guidance, unique and inspiring stories, meditations through the seminar, and her successful personal story with Silva Method blew my mind. They opened a more comprehensive presentation of what I want in life in general, not only in the creative part as I look from the beginning of the seminar. Some parts of the seminar prove that we don’t know our capabilities and don’t know much about our inner strength. Our connection and experience with our Source Energy still, I am sure, to expand and envelope for me. A lot of the program felt very practical to me. I love Lab and Counselors , Glass of Water technique, Mirror of the Mind technique, three-finger technique and many more. The visualisation in HoloViewing Technique gave me the confidence and enthusiasm to shape my life more how I’d like it.
        Katarzyna, step by step, guides us through complex material and patiently answers all questions through seminars, helping us to feel relaxed, happy, and positive, with a positive mindset after each seminar. I learned a lot from her.
        It is not enough words to express my gratitude for all vibes, energy and knowledge we received from Katarzyna. I also would like to thank Katarzyna for the warm and welcoming atmosphere of the seminar. The tasty lunch and coffee breaks were so lovely. I am thankful for all the supporting materials and unlimited access to complimentary email support . Priceless!
        Additionally, to add that I met so positive and open-minded participants. I recommend this course to everyone, and if you believe that there are many things in this life that we are yet to discover, and can keep an open mind about things, you’ll find it fascinating.
        Now I know that the Silva Intuition System will unfold for me more with time and open my awareness and help me to tap into the power of my intuition in all areas of life. Every day, in every way, I’m getting better and better.`,
        image: '/img/Ekatarina Donnelly.jpg',
        name: "Ekatarina Donnelly",
        designation: "",
      },
      {
        type: "text",
        text: `The time spent on the course was some of the best investment in myself I have made in the recent years. It was transformational, relaxing and applicable to pretty much all areas of my life.
        I have learned about the way our brain operates and how to use its potential to “create” our life the way we want it. The meditations and techniques are simple yet incredible and the magic that follows is contagious. Now I make my husband meditate – which is a big thing.
        So far I have used the tools and techniques in work, relationship, to “book” a parking spot in a busy location – so pretty much everywhere.
        This course is for everyone who wants to invest in themselves, take control of their life and change the mindset from life that is happening to me to create the life I want.`,
        image: '/img/Magda Pepper.png',
        name: "Magda Pepper",
        designation: "",
      },
      {
        type: "text",
        text: `Kasia is a wonderful facilitator and inspirational coach. She leads her seminars professionally while remaining mindful to participants needs. I thoroughly enjoyed the Silva Method training with Kasia which encompassed her pleasant manner, expert skills and attention to detail. Kasia is clearly compassionate about her work.
        Whether you are looking for Silva Method training or Life Coaching, Kasia will motivate you to focus on goals and achieve your dreams. I recommend Kasia as a successful business woman who aspires to empower others through delivery of high quality teaching.Contact Kasia now to ensure your life becomes better & better in every way.`,
        image: '/img/Rachel Brady.jpg',
        name: "Rachel Brady",
        designation: "",
      },
      {
        type: "text",
        text: "I have completed this course and loved it so much that I have gone back as a ‘repeater’. Katarzyna is a fantastic facilitator and teaches the Silva Method with great clarity. Once the Silva mind ‘tools’ are implemented correctly, the results are amazing. It is beneficial not only for achieveing your own goals, but also fantastic for helping those around you with their goals or health issues etc, which is a real plus! A very enjoyable few days with great people. I highly recommend this course.",
        image: '/img/Roisin Sullivan.jpg',
        name: "Roisin Sullivan",
        designation: "",
      },
      {
        type: "text",
        text: "The past two weekends have been life changing and eye opening to say the least!  Other than meeting my fiancee, and memories I have with family and friends, I think this past weekend has been the most impactful weekend I’ve ever experienced in my life. You’ve provided myself and my fellow silva classmates with a precious gift, allowing us to access  more of ourselves that we didn’t think was ever possible. For this I can’t express enough my sincerest gratitude for your teachings.  I feel very blessed to have had an instructor with incredible passion, drive, and a genuine love for helping others. Your delivery of the content was impeccable. I never felt rushed, overwhelmed, or stressed during this experience. You provided us with a comfortable, warm, and incredibly structured class with honest examples of how the silva method has changed your life. You are an outstanding teacher and example of the power of human potential! Overall this was an amazing experience and I’m already recommending it to many of my friends! I look forward to keeping in touch with you and my fellow Silva brothers and sisters from the class (haha)!  I wish you all the best Kasia. Sending lots of love and positive energy.",
        image: require('../../../images/profile-picture-vector.jpg'),
        name: "Habib Mutawakil",
        designation: "",
      },
      {
        type: "text",
        text: "I was fortunate to participate in Silva Method course run by Kasia.She is a person who pours all her heart into her work. Her passion for sharing the knowledge and way of sharing it with the clients makes everybody at ease. She presents the knowledge in a practical and interesting way. You never get bored and you always benefit.You always feel inspired, motivated and relaxed in the same time.",
        image: '/img/Magda Kowalewska.jpg',
        name: "Magda Kowalewska",
        designation: "",
      },
      {
        type: "text",
        text: "I did the Silva Life and Silva Intuition System online courses over two weekends. I thoroughly enjoyed the courses, the silva team were extremely enjoyable and informative. It was an excellent, they provided opportunities for discussion and gave good clarification on any queries we had. It was also great that it was online, because living in the West of Ireland, it meant that I didn’t have to travel to Dublin. Silva Method Team provided good opportunities for interaction with the other people taking the course and it was lovely to meet and interact with like minded people. I have been practising the Silva Method, twice a day for over 20 minutes since doing the courses and I’m already seeing the difference it is making in my life. I look forward to seeing where it leads me over the next few weeks and into the future. I would highly recommend doing the Silva Life and Silva Intuition Courses.",
        image: '/img/Sarah.jpg',
        name: "Sarah",
        designation: "",
      },
      {
        type: "text",
        text: "I graduated from the Silva Method 17 years ago in Hungary, and recently I've felt the need to repeat the course. Although I'd had great results and achievements over the years, I wanted to refresh my knowledge and explore more deeply. Then, I found out about the Silva Method's upcoming classes in Dublin, and I didn't hesitate to enroll.This turned out to be one of the best decisions I've made in recent years. The classes were both familiar and fresh at the same time, with the Silva Method's official team delivering them with enthusiasm and positive energy. The weekends we spent in these sessions were filled with a sense of uplift and inspiration.If you're thinking about joining a Silva Method course, I highly recommend it. The team will guide you expertly through the process, ensuring you gain a solid understanding of the method. You'll learn how to tap into a powerful tool you already have but might not have used in this way before—your own mind and intuition. Better & better!",
        image: '/img/Eva Molnar.jpg',
        name: "Eva Molnar",
        designation: "",
      },
      {
        type: "text",
        text: "I attended Silva Method courses in London in 2015, and then again in 2016 with the Silva Method official team. Wanting to refresh my knowledge, I was delighted to find the latest course even more insightful than the first two times.The team creates an environment where you feel encouraged to ask questions and share your experiences. They are skilled at making the concepts easy to understand, often using real-life examples and referencing modern technology to clarify points, which makes the original documentation much more accessible. I gained so much more from the course this time; the Silva Method team really knows how to make the ideas clear, reinforcing key concepts throughout the sessions. I would recommend attending a Silva Method course to everyone. It's a fantastic experience that will change the way you look at the world! The people drawn to these classes are also amazing, adding to the overall enriching experience.",
        image: '/img/Sinead Hanlon.jpg',
        name: "Sinead Hanlon",
        designation: "",
      },
      {
        type: "text",
        text: "I am husna-Silva trainer,Reiki Grand master and a NLP master practitioner but i am using these words for my session of Silva Theta healing with Elenne that, The sessions were UNBELIEVABLE,AMAZING,WONDERFUL,POWERFUL,REMARKABLE.. and full of healing.I never thought that such a different type of energy healing can be introduced. It is best for person suffering from past traumas. Again i would say all above written words for the Silva Method & Jose Silva.",
        image: '/img/Husna HM.jpg',
        name: "Husna HM",
        designation: "FOUNDER AND MANAGING DIRECTOR. at Healing Mind HM",
      },
      {
        type: "text",
        text: "I had the great pleasure to work with Eleanne, Silva Method Instructor - she is a great healer and a beautiful soul - she showed great patience , compassion in helping me navigate the nuances of my issues and kept through it till I was complete. She is a channel for divine guidance and she gave me great inputs. I felt  so good after working with her and the release was truly wonderful. I totally recommend Eleanne & Silva Method.",
        image: '/img/Jyothsna Sri.jpg',
        name: "Jyothsna Sri",
        designation: "",
      },
      {
        type: "text",
        text: `If you can count, you can count on the Silva Sales Power Method to skyrocket your sales. This teaches how to sell your way through the top."Learn to enjoy success, achievement and all the benefits of the good life like time, money and lifestyle freedom while selling yourself by Silva's ideas.`,
        image: '/img/MARK VICTOR HANSEN.jpg',
        name: "MARK VICTOR HANSEN",
        designation: "Motivational speaker, sales trainer, and author of Chicken Soup for the Soul.",
      },
      {
        type: "text",
        text: `I love it, fantastic, Silva Sales Power, the Silva Mind Method for Sales Professionals is a much needed book & Courses to make you become the World's Greatest Anything.`,
        image: '/img/JOE GIRARD.jpg',
        name: "JOE GIRARD",
        designation: "Number one automobile and truck sales person in the world and author of How to Sell Anything to Anybody",
      },
      {
        type: "text",
        text: `Something seems to be happening, I am not sure what, but luck seems to be improving and the daily worries don't seem to be getting to me. Even had the comment that I looked happier than I had in a long time. Headaches are almost gone, too.
        "I seem to have more endurance than I have had since the accident 2 years ago. I do contribute that to the System. The brain power seems to be back to normal. Like I said, I don't know HOW it works, but it does.`,
        image: '/img/CALVIN POOLE MD.jpg',
        name: "CALVIN POOLE MD",
        designation: "Gynecologist, Mississippi",
      },
      {
        type: "text",
        text: `The Silva Method helped me with my concentration, and it helped me to relax before the pressures of a game.`,
        image: '/img/RUSSELL BUCKY DENT.jpg',
        name: `RUSSELL "BUCKY" DENT`,
        designation: "MVP of 1978 Baseball World Series",
      },
      {
        type: "text",
        text: `Buy the Silva Sales Power book and apply the Silva Method techniques as instructed. do what it says. These practices will change your life.`,
        image: '/img/DANIELLE KENNEDY.jpg',
        name: `DANIELLE KENNEDY`,
        designation: "Author of Selling-The Danielle Kennedy Way",
      },
      {
        type: "text",
        text: `I wish I just had more time to take come classes!
        I had to think twice when I subscribed to Silva Method, but it was soooooo worth it.
        I had done the Silva Method live back in 1992, wow it’s great it’s on the platform as well! I wish I just had more time to take come classes!`,
        image: '/img/Charlotte Gantenbein.jpg',
        name: `Charlotte Gantenbein`,
        designation: "",
      },
      {
        type: "text",
        text: `Explanations are world class
        The Silva Method Courses are incredible. They really are because you can understand everything that's said and apply it instantly to get actual results.`,
        image: '/img/Joshua Huller.jpg',
        name: `Joshua Huller`,
        designation: "",
      },
      {
        type: "text",
        text: `Amazing Courses. I was interested on the Silva mind control courses. The Courses are structured well and I loved it! It’s life changing. Signing up was easy and I feel that the subscription fee is fair and worth it.`,
        image: '/img/Robert Jackson.jpg',
        name: `Robert Jackson`,
        designation: "",
      },
      {
        type: "text",
        text: `Silva Method has healed me tremendously. I am very grateful for everyone’s collaboration in making this possible. Thank you Jose Silva & Silva Method Whole Team.`,
        image: '/img/Joel Cruz.jpg',
        name: `Joel Cruz`,
        designation: "",
      },
      {
        type: "text",
        text: `Silva Method is a great place to learn. You can expand your knowledge in a great way. It also provides great mind development & Self Growth programs to change or learn to know yourself better.`,
        image: '/img/Marja Opsteegh.jpg',
        name: `Marja Opsteegh`,
        designation: "",
      },
      {
        type: "text",
        text: `I loved the way Silva Method creates a community to plug into around its courses and keeps you on track with regular emails, class remninders & event updates.`,
        image: '/img/mark.jpg',
        name: `Mark`,
        designation: "",
      },
      {
        type: "text",
        text: `Silva Method is a Life Changing Educational Platform.
        Silva Method has created a truly rich and powerful platform that addresses the educational needs of those who want to enrich their lives and the lives of others.`,
        image: '/img/Sylvie G.jpg',
        name: `Sylvie G`,
        designation: "",
      },
      {
        type: "text",
        text: `Amazing experience. It has been one of the best experiences of transformation in my life. Thank you Jose Silva for creating Silva Method.`,
        image: '/img/Laura A.jpg',
        name: `Laura A`,
        designation: "",
      },
      {
        type: "text",
        text: `Silva Courses are Life changing.
        Amazing! So many incredible programs to do for absolutely anyone interested in personal development in all areas of life. Literally life changing.`,
        image: '/img/Angela.jpg',
        name: `Angela`,
        designation: "",
      },
      {
        type: "text",
        text: `Really loved each lesson. Silva Live Interactive classes instructors are superb. Very professional lay-out. Exercises are helpful.`,
        image: '/img/karin van zuilen.jpg',
        name: `karin van zuilen`,
        designation: "",
      },
      {
        type: "text",
        text: `Great Experience. I have been enjoying the programs offered in Silva Method. The instructors are phenomenal, the techniques effective and well organized. This experience has surpassed my expectations.`,
        image: '/img/Yanira.jpg',
        name: `Yanira`,
        designation: "",
      },
      {
        type: "text",
        text: `Everyone will find a suitable course.... so much to discover
        There are so many different programs and audios, that everyone will find something suitable to develop new aspects of personality`,
        image: '/img/pat2.jpg',
        name: `Pat`,
        designation: "",
      },
      {
        type: "text",
        text: `It is the best platform for self development.`,
        image: '/img/Jette.jpg',
        name: `Jette`,
        designation: "",
      },
      {
        type: "text",
        text: `Amazing value for so many top quality mind control courses`,
        image: '/img/Ailis.jpg',
        name: `Ailis`,
        designation: "",
      },
      {
        type: "text",
        text: `Wonderful Experience. The program delivered exactly what was promised and more. Very coherent, focused messages with super articulate explanations.`,
        image: '/img/Michal.jpg',
        name: `Michal`,
        designation: "",
      },
      {
        type: "text",
        text: `After taking the Silva Course, I have found that I can focus easier and retain so much more information. I am less stressed and feel much more at ease with life. In terms of my studies, I found that by going to level before reading, I could remember almost everything. This proved very helpful in exams - and helped me to get 1st in English and History. Also I used the three-fingers technique during my speech (triggering confidence and success) and I won the school speech competition. I look forward to the next Silva Course where I can continue to improve my newfound skills and learn more life-changing techniques!`,
        image: require("../../../images/profile-picture-vector.jpg"),
        name: `Lukas .C`,
        designation: "",
      },
      {
        type: "text",
        text: `I'm very glad that I did this course. It was just great to learn to control things that you never imagine that you could.

        I'm far more optimistic than I have ever been before. I can now control uncomfortable situations in a better manner and I'm able to control my health as well. Now my dreams are clear and it gives me better direction and vast improvement in my visualizations! I attended one seminar and I achieved so much! Now I'm ready for life and ready to make my life better and better the way that I want, the way I think is better for me ! Better and Better!
        
        Thank you Silva Method for everything!!!!`,
        image: require("../../../images/profile-picture-vector.jpg"),
        name: `Eliton`,
        designation: "",
      },
      {
        type: "text",
        text: `CURED OF MIGRAINES.
        I suffered from serious head aches that sometimes lasted for several days, sometimes for weeks. I felt nauseous, had blurred vision, felt sleepy, run down and unable to concentrate. Not to mention my consumption of pain killers… and the depression that overwhelmed me again and again… The many sick leave days I had to take, made me very anxious that I might lose my job. After attending the Silva Seminars, I now live a migraine-free life. I have not taken any pills since! I’m able to concentrate better than ever. No pain, heaps of energy, and my creativity is back. I wanted to say Thank You again to Jose Silva and Silva Method, I feel so good and every day is better than the previous one.`,
        image: '/img/Daniel.jpg',
        name: `Daniel`,
        designation: "",
      },
      {
        type: "text",
        text: `RELATIONSHIP HEALED. After the Silva Method seminar I decided to see whether a long standing relationship could really be healed. My relationship with my mother has been a negative one: I have been very angry with her for many years as well as with myself for getting angry with her. I went to my level and spoke to my mum and asked her to forgive me for anything I had done to hurt her and then I forgave her for all the hurt she caused me. The next morning my mum called me and we had a lovely chat like old friends. I felt wonderful and this feeling is still with me. I laughed as we talked and I realized how much I really love her and she loves me.`,
        image: '/img/S. Z.png',
        name: `S. Z`,
        designation: "",
      },
      {
        type: "text",
        text: `MANIFESTING MY DREAM HOME
              I have attended Silva Life System and Silva Method online seminar in February. Using one of the techniques I have manifested my dream house in 1 week time, it took another 2 week to negotiate and next week we are going unconditional on the contract. I wanted a 5 bedroom house with a section 3000+sqm in a quiet location, away from the road with mature trees and good neighbours. In one week time my husband came across a 5 bedroom house with a section of 2938sqm, when attending open homes. It is situated down a long private drive way, the section is surrounded with mature trees. I wanted a garage, but I did not put that on paper and the house has a carport (that’s what happens when you do not specify). The house price was out of our budget, we have put an offer in and it was rejected. Using another Silva technique I have asked about what to do in this situation and I got an answer to wait for 2 weeks. We have repeated our offer in two weeks and it was accepted. We started to organise finance for the house as soon as we found the house. I have used one of the Silva techniques to create a positive outcome. At the end our loan was approved (before attending Silva seminar and using the techniques our applications were rejected twice). I consider this particular situation to be a great success and proof that Silva techniques work if you have faith, believe and expect things to happen. I was amazed by how quickly the house manifested. I knew that the house that I wanted was out of our price range but I believed it anyway, expected it to happen and the house manifested and we were in a position to buy it. Thank you very much, Irene, for being such an inspirational teacher.`,
        image: '/img/Natalia Lykova.jpg',
        name: `Natalia Lykova`,
        designation: "",
      },
      {
        type: "text",
        text: `IMPROVED GRADES
      I just wanted to let you know that I did really well in my second semester exams at University I secured an A, and 2 B+'s. This is a great improvement on my previous grades. Currently I am doing summer school and its going really well!! Moreover, I had programmed to reduce my weight and I have managed to do so and I am feeling really good about this achievement.`,
        image: '/img/T. S. (Student) Auckland.jpg',
        name: `T. S. (Student) Auckland`,
        designation: "",
      },
      {
        type: "text",
        text: `I did my first Silva training in 1993, and I have been actively using the Silva Method ever since. It is by far the best personal development program I have ever done. The most important technique I learned in that course was the basic technique of creative visualization. Visualization was explained clearly and it was easy to follow and to do, even on my own.

        In 2006, I began writing my book. In the ten years that followed, I attended several Silva seminars with Irene in New Zealand. Her support and enthusiasm about my book was wonderful. The road to publication has not been easy; there have been a lot of ups and downs. But I continued to visualize my success and keep the positive outcome of publication as my intention.
        
        My book, This Way Up: Seven Tools for Unleashing Your Creative Self and Transforming Your Life will be published in April 2016! I know with certainty that the Silva Method and creative visualization in particular has been a big part of getting my book to publication.
        
        The Silva Method has had a huge impact on my life. It's amazing how much you can do with your mind when you know what to do. I cannot recommend this program highly enough.`,
        image: require('../../../images/profile-picture-vector.jpg'),
        name: `Silva Graduate`,
        designation: "",
      },
      {
        type: "text",
        text: `IDEAL JOB OFFER 🙂.
        Since attending the Silva Seminar in Wellington, I have had success with programming for small things and big plans in my life- including an ideal job offer for next year and also for dealing with bureaucracy in my role as a volunteer for my refugee family. Very happy and grateful for all that! Thanks to Silva Method.`,
        image: '/img/J C.jpg',
        name: `J C`,
        designation: "",
      },
      {
        type: "text",
        text: `IDEAL JOB OFFER 🙂.Since attending the Silva Seminar in Wellington, I have had success with programming for small things and big plans in my life- including an ideal job offer for next year and also for dealing with bureaucracy in my role as a volunteer for my refugee family. Very happy and grateful for all that! Thanks to Silva Method.`,
        image: '/img/Jakab C.jpg',
        name: `Jakab C`,
        designation: "",
      },
      {
        type: "text",
        text: `LEARNING ABOUT ME 🙂.
        Since attending the Silva Method seminar & doing online courses, I have found my true career path. This has also provided me with an immense amount of energy that I never had before, as well as clarity in thought. The Silva Method has helped me to broaden my perspectives on how to view different aspects of life, and in turn to take different courses of action, to get better results. The seminar also taught me things that I didn’t know about myself.
        This is very important, as many people go through life with a belief or experience that is holding them back and they don’t even know about it.`,
        image: '/img/Alex J.jpg',
        name: `Alex J`,
        designation: "",
      },
      {
        type: "text",
        text: `FAMILY HARMONY 🙂.
        My dysfunctional relationship with my dad has virtually vanished. There are some improvements still needed, which I am working on.
        My relationship with my dad has been improved significantly only after attending the silva method seminar.
        Having made improvements in that area, I feel more at peace and relaxed. As a result, I now can focus and think easily. I am now able to focus my energy in areas that are very important to my personal development and growth, as I no longer focus my thoughts on anger and resentment towards my dad. Thank you Jose Silva Mind Control Method, for helping me so much.`,
        image: '/img/Peter N.jpg',
        name: `Peter N`,
        designation: "",
      },
      {
        type: "text",
        text: `ACHIEVING RELAXATION.
        Since I have done the Silva Life System Course, I have gained a better understanding of myself. I now experience a sense of ease when dealing with day-to-day experiences, especially with decision-making. Most important, my relationships with my family, work colleagues and friends have improved significantly.`,
        image: '/img/P. D.jpg',
        name: `P. D`,
        designation: "",
      },
      {
        type: "text",
        text: `AN ATTITUDE OF GRATITUDE
        Attending the Silva Method Seminar & Courses has helped me focus on the positive and see the good things in life that I am so grateful for and what we normally take for granted. I also feel much calmer and more relaxed. My sleeping has also improved and I now have the skills to deal with things that pop up on a daily/weekly basis. I feel so lucky for having had the opportunity to do this course and hope to do another one sometime soon.  I thoroughly recommend it and have made a promise to myself to make sure I get my children there as soon as possible – it would be such a good investment in their future.`,
        image: '/img/AJC.jpg',
        name: `AJC`,
        designation: "",
      },
      {
        type: "text",
        text: `A NEW LEASE OF LIFE
        Right from the start I noticed a complete change in energy. I felt more positive and had more awareness about what I was doing and saying. The silva seminar was amazing and life changing. ­It gave me a whole new toolbox for problem solving, staying focused and achieving greater clarity. I notice that I am calmer and more peaceful. Amazing co-incidences have started happening and my outlook on life has changed dramatically for the better.`,
        image: '/img/Wendy.jpg',
        name: `Wendy`,
        designation: "",
      },
      {
        type: "text",
        text: `A MUST READ FOR PARENTS
        My son had post traumatic disorder issues, yet whilst we did so much work with various therapists, still problems remained, both behavioral and self destructive problems. Sending my son to Irene was really a last resort and the work she did is amazing. My son is happy, his self confidence and self esteem are now extremely high. My parents who see my kids once a year were amazed at the changes in confidence and behaviour and still talk to me about it because he is such a different teenager. Not only has Irene done amazing work with my son but she has equipped him with great tools to help his academics, and he finds learning now so much easier.`,
        image: require('../../../images/profile-picture-vector.jpg'),
        name: `Silva Graduate`,
        designation: "",
      },
      {
        type: "text",
        text: `MANIFESTING SUCCESS  with  SELLING MY HOUSE (During the Economic Crisis)!!
        I attended the Silva Life System Seminar on September 27 and 28, 2008. 
        On October 30, 2008 I told Irene that I'd just put my house up for sale the previous weekend.  She asked if I was doing any 'Silva' techniques for it – and explained how to use 'Mirror of the Mind' for this.  I followed her instructions to the letter over the next 6 days, as instructed, then 'let it go' as she had advised.  Two weeks later we had a signed offer, from a couple who fell in love with the house.  Although the price was a little below what I wanted, we included an exit clause in the agreement.  The following week we did get a higher offer, but eventually that one fell through.  The second offer would have disadvantaged the first people, and I earnestly asked that they be looked after – which they were, albeit to my slight disadvantage.  However, I guess that means there were two successes. 
        I have now definitely sold my house (went unconditional and settled without any problems).  In today's depressed market that is quite an achievement – to have sold privately, in a total of 3 weeks, when others are not selling even with agent assistance.  In fact, my neice and partner have been trying to sell their house for the past two months, without success; even going to auction but with not one offer resulting.
        Is this success just a mystery?  Or has some 'secret power' been at work here? I know what I believe! Thank you Irene (Silva Instructor), and the Silva Method.`,
        image: require('../../../images/profile-picture-vector.jpg'),
        name: `Nola`,
        designation: "",
      },
      {
        type: "text",
        text: `I got an A+ in my favorite and 3rd stage paper at my University - Strategic Brand Management. I received a 100% in the exam (never done before). My online was 100% as well (10% of the course), my report was 38/40 (38% of the assessment). This meant that I received 48% for my assessment and 50% for exam = total of 98% (A+) overall!!:)I've been really calm over this period (exam time), unlike all the other times! After our graduate meeting I started doing the 30 minutes relaxation at night before I went to bed and even 15mins (going into Alpha sessions) basically anywhere that was quiet. I used this to calm myself and visualize what/how I wanted the exam to go and even for my presentations. I saw myself being happy, smiling and totally calm- that's why my recall period of everything I had memorized for the A+ paper came out so clearly! It was amazing...I wrote more pages then the booklet offered and I had time to plan my essays!:)After the graduate meeting it re-enforced for me how important it is not to be stressed out and be able to get oneself out of stressful situations- that's why relaxing and doing the exercises was so important for me, especially using the 3 finger technique- if i could feel my heart rate accelerating which we know leads to STRESS. It's been a great journey and I am happy with the outcome!! Especially since it's all been happening- getting both scholarships that I wanted, getting into Copenhagen and getting an A+ for my favorite paper!!!`,
        image: require('../../../images/profile-picture-vector.jpg'),
        name: `T Spisic`,
        designation: "",
      },
      {
        type: "text",
        text: `I have been using the Silva Method for a number of years now and have been impressed with the abilities i have gained within my own life, It has been truly life changing.  If you are new to meditation and relaxation techniques give this a go and you will be able to learn how to tap into a lot more than that.`,
        image: '/img/Sean Hurst.jpg',
        name: `Sean Hurst`,
        designation: "",
      },
      {
        type: "text",
        text: `PROGRAMMING WORKS.
        After completing the course I have been continuously reaching my monthly Sales Targets. Leads are coming through for Sales, and I have developed an excellent Rapport with my Sales Manager.
        Secondly, my landlady recently was very interested in selling the house I am currently renting. I wanted to continue to stay in the same house so I went to level and used one of the techniques which I had learned.
        After a couple of days I received an email from my landlady saying " Right now my family have decided not to sell the property and you and your family can continue to stay as long as you want! Getting parking places comes effortlessly and finally, I find I have become calmer, full of energy and more focused.`,
        image: '/img/A. Mehta.jpg',
        name: `A. Mehta`,
        designation: "Auckland",
      },
      {
        type: "text",
        text: `I am in my final year of school and attended the Silva Seminar in April this year. I began to see major changes once I began the annual drama for my class. I had been cast in a massive role (I had maybe 50% if the total lines in the hour and a half long play) and was finding it difficult to learn my lines with all the other hectic school things going on. I found it would take me along time to learn a small amount of lines. Then the Silva technique popped into my head which I had not used since the Seminar. So I went to level and programmed myself into ULTRA information absorbtion mode whenever I used the special technique for accelerated learning. I was amazed how the information flowed into my head after having read it once or twice. I learnt more in that hour of Silva focused learning than I had in several days of just struggling through. In less than a week I had learnt the bare bones of the entire text and by the time the performance came along (about a week and half later) I was fully prepared and had nailed 99.9% of the lines. I would attribute all my success to Silva in this case. For me this was my turning point on the path of Silva. Now I go to level everyday and sometimes at night and I go to my lab for a few hours every couple of days. I find it so relaxing and whenever school or home gets too stressful it is so nice to have a quiet place to go to get guidance. Another quick story is: my mum has recently gotten engaged, and although I do like her fiance there are certain things he does that gets on my nerves. I was finding myself very frustrated with him to the point of not wanting to be around him However by going to level and simply asking him why he was being like that I discovered it was all because he was nervous around me being the 'new dad' kind of thing. I am excited to see what I can do next with my Silva techniques to change my life for the better and help anyone who needs help. Thankyou.`,
        image: '/img/Liam McDonald-Lurch.jpg',
        name: `Liam McDonald-Lurch`,
        designation: "",
      },
      {
        type: "text",
        text: `Improving by leaps and bounds...
        I am in my final school year and have used the Silva Method to help me in my exams and studies because now I intuitively guess what is important. I have improved a lot, with my grades jumping by 20% in a few months! Before I attended the Silva Seminar, I had trouble waking up and couldn't control my tiredness so I always left things to the last minute which caused me a lot of stress but through the Silva Method techniques, I now have more energy and can program myself to wake up at whatever time I desire. I consider myself really lucky to have been introduced to the Silva teachings because they have helped me more than I ever expected. Thankyou 🙂`,
        image: '/img/Annabel.jpg',
        name: `Annabel`,
        designation: "",
      },
      {
        type: "text",
        text: `Best Year of My Life
        Hi Irene!2009 was the best year of my life! This is not just because I got high grades for all of my school tests but I also was awarded a cup for accounting and economics, I also came top in my class. This is only due to the Silva Method course I did towards the middle of the year. This course has helped me soo much that I am now confident to say that it is the pure drive behind my success in 2009! I would say that, the Silva Method course is definitely worth attending not only for aldults but more so for school students like me! Thankyou`,
        image: require('../../../images/profile-picture-vector.jpg'),
        name: `P. Taunk`,
        designation: "",
      },
      {
        type: "text",
        text: `I have had a great first week at uni! Wow its been so much fun! I have applied my Silva techniques - using the special memory enhancement technique for taking in information easily and effortlessly, I noticed I hardly took any notes in my fashion history lecture as I was more focused on actually listening to what my tutor was saying. It was absolutely successful. Thank you so much for everything again Irene Sophie & Silva Method.`,
        image: require('../../../images/profile-picture-vector.jpg'),
        name: `Silva Graduate`,
        designation: "",
      },
      {
        type: "text",
        text: `SUCCESS IN STUDIES.
        I have been following the Silva techniques for some time now and do all my studing at level. During my exams I am using the Silva techniques and my grades have gone up considerably. This has really helped me in during my first year of uni. Thank you so much`,
        image: '/img/K. Gandhii.jpg',
        name: `K. Gandhii`,
        designation: "",
      },
      {
        type: "text",
        text: `RELATIONSHIP ISSUE SOLVED
        Thank you  for your support and great presentation of the Silva Method. I read Jose Silva's book many years ago and was fascinated with it's content. Now thanks to you I have been able to complete the course in NZ. There was a challenge with lack of communication from a member within my circle affecting several people. I went to my level and asked for clarity and guidance on how to resolve it. The very next day this person said they wanted to talk, so we did .They apologized &  said they weren't sure why they had been distant with those around them! The air was cleared and the communication improved by leaps and bounds! It's so exciting to have so many tools to utilise for personal development , literally at my finger tips and life is getting better 'n better.`,
        image: require('../../../images/profile-picture-vector.jpg'),
        name: `Kay`,
        designation: "",
      },
      {
        type: "text",
        text: `GOLF HANDICAP LOWERED FROM 24 TO 6!
        I have been playing golf socially for 10 years. When I had my two children, I decided after 3 months they could come to golf with me. They got strapped on the golf trundler in their push chair and off we went.
        Two years ago, I seriously decided to put in more effort as I found golf very interesting and challenging. After missing out on winning the mercedes tournaments 3 years ago, I decided to get really serious with my golf. I won the longest drive two years in a row, and just missed out on the regional finals.
        About two and a half years ago I attended my first SILVA Method Program! I started playing at a handicap of 24 when entering the mercedes' tournaments. After doing the Silva  Method course, I started applying the techniques that would help me take control of my life and my sports. I got to a single figure of 9.9 a month ago. In addition, I repeated the course a number of times. Today my handicap is 7.9!!
        Raising the kids and being a wife and Mum is a tough job. However, I believe it becomes easier when you  have  techniques like stress control /meditation and heaps of others to refer to when you are going through some tough times.
        Daily I go to level to relax and regain some lost energy when feeling down. I listen to audio tapes when I need some motivation. I use half a glass of water to solve problems because it is so simple. I take these actions because I believe they Will work for me and they do. Doing the Silva exercises daily and regularly has done wonders for me in so many many ways. All I have to do and tell myself is I choose to be happy and so it is.`,
        image: require('../../../images/profile-picture-vector.jpg'),
        name: `Karameli Clegg`,
        designation: "",
      },
      {
        type: "text",
        text: `SURVIVING" SCHOOL CAMP
        Since taking the Silva course, I have more aware of all the unwanted and negative feelings, habits, and have slowly began to work through them. I stressed less, became a calmer and more peaceful person. I have better control of my life now, it's really great!! I was really worried about 'surviving' my school camp last year, as they often involved many physical activities which I wasn't good at. With the Silva Method, I programmed for myself to have fun. It was amazing -my camp group was quite an odd combination of people, none of us top athletes, but we worked together like clockwork and somehow won almost every challenge! It was total surprise from all of us!`,
        image: '/img/Jenny Liu.jpg',
        name: `Jenny Liu`,
        designation: "",
      },
      {
        type: "text",
        text: `FINANCIAL FREEDOM.
        Prior to attending the Silva Course I was going through a financial crunch. I was contracted to do some work for a company (which i had done many times before on a regular basis)however, I had not been paid for a couple of months and I was becoming very concerned as I had incurred a lot of debts that needed to be paid asap.
        As soon as I completed the course, I programmed myself using one of the mental tools and visualised myself receiving a cheque for the full amount from the person concerned at a specific time on a specific day. (In the past the person concerned often set times, dates and places to meet with no intention of showing up). So on the day (programmed by me) I went to his place of business as agreed by both of us. (I went early to make sure I would not miss him) and exactly at the time specified he arrived(it was the first time in two years!!). Moreover, the cheque I received had the the correct amount owing to me. It was at this point that I really started believing in myself, in my capabilities and the Silva Method techniques. I truly am grateful.`,
        image: '/img/B. Bowman.jpg',
        name: `B. Bowman`,
        designation: "Auckland",
      },
      {
        type: "text",
        text: `SUCCESS....THANKS TO SILVA.
        I was working as a consultant required to write a strategy and action plan for an agency. Upon completing the document, my employer reviewed the work and decided that I would affect their funding eligibility and asked me to re-write the document in their favour. I declined to do this and it resulted in my employer refusing to pay me for work completed.
        I used the Silva Method by going to level and visualising a discussion taking place with my employer, seeing it result in a positive way and result in my being paid in full. Two days later I was paid in full for my consultancy work - Success.......... thanks to Silva!`,
        image: '/img/Itania.jpg',
        name: `Itania`,
        designation: "Auckland",
      },
      {
        type: "text",
        text: `Silva Method is a great course for manifestation. If you really want to tap into your spiritual self, this is the course for you!`,
        image: '/img/Cocoahoney.jpg',
        name: `Cocoahoney`,
        designation: "Auckland",
      },
      {
        type: "text",
        text: `I'm A Fan of Silva Method`,
        image: require('../../../images/profile-picture-vector.jpg'),
        name: `Gary Hopkins`,
        designation: "",
      },
      {
        type: "text",
        text: `Very good resource.
        Silva Method Helps to you learn how to manifest anything in life. Got real results after completing the mind control courses.`,
        image: '/img/Jacqueline O..jpg',
        name: `Jacqueline O.`,
        designation: "",
      },
      {
        type: "text",
        text: `I'm A Fan of Silva Method.
        I became aware of Jose Silva's 3 to 1 method of meditation a few years ago when I visited the website and signed up for the free online course that they offer. I have been recommending this method ever since. It's one of the best techniques that I have found to alter my state of consciousness, and to do it quickly.
        After finally breaking down a short time ago and buying the whole course, I can see where most of the spiritual and psychic development training that is out on the market today comes from.
        It's straight forward and to the point instruction, with personal experiences told by the narrators that make up the majority of this CD set. They also include a short and long version of the method on a separate CD to use in your meditations.
        If you have a hard time with centering yourself (paying attention) during meditation (or just don't know how to get started) then this method will most likely help you. For those of you that meditate consistently, this method reminds me of a guided Vipassana style of meditation (just not as long as a normal session) and should be a good addition to your practice.`,
        image: '/img/Gary Hopkins.jpg',
        name: `Gary Hopkins`,
        designation: "",
      },
      {
        type: "text",
        text: `Great meditation and healing program.
        This is the simplest way to find your spirit and its power. The many spiritual exercises available in this Silva Method program helped me work better, sleep better, communicate better with the people I love, project and help people, navigate difficulties, feel stronger, etc. It is also a healing program with which I can heal physical illnesses of my own and others.`,
        image: '/img/Alexander Ionescu.jpg',
        name: `Alexander Ionescu`,
        designation: "",
      },
      {
        type: "text",
        text: `Silva Method Digital Course Materials are all very nice and effective.`,
        image: require('../../../images/profile-picture-vector.jpg'),
        name: `Discover`,
        designation: "",
      },
      {
        type: "text",
        text: `I've been a student of the Silva programs for several years and the newer techniques did not disappoint.`,
        image: '/img/Mary R. Cowley.jpg',
        name: `Mary R. Cowley`,
        designation: "",
      },
      {
        type: "text",
        text: `Excellent courses & programs, very pleasant experience! So much for the price! Thank you very much Silva Method.`,
        image: '/img/Angela2.jpg',
        name: `Angela`,
        designation: "",
      },
    ]);
  };

  useEffect(() => 
    {
    getreviews();
    const disableSelectAndDoubleClick = (e) => {
      e.preventDefault();
    };

    const elements = document.querySelectorAll('.no-select');
    elements.forEach((el) => {
      el.addEventListener('selectstart', disableSelectAndDoubleClick);
      el.addEventListener('mousedown', disableSelectAndDoubleClick);
      el.addEventListener('dblclick', disableSelectAndDoubleClick);
    });

    return () => {
      elements.forEach((el) => {
        el.removeEventListener('selectstart', disableSelectAndDoubleClick);
        el.removeEventListener('mousedown', disableSelectAndDoubleClick);
        el.removeEventListener('dblclick', disableSelectAndDoubleClick);
      });
    };
  }, []);

  // Sample data for testimonials
  // const testimonials = [
  //   {
  //     type: "text",
  //     text: "„Dragan is fantastic at what he is doing. Together with Dragan we are engaged in unleashing the human potential throughout the world.“",
  //     image: "https://draganv.com/wp-content/uploads/2016/11/jan-muhlfeit2.png",
  //     name: "Jan Muelfeit",
  //     designation:
  //       "Global Strategist/Coach/Mentor/Ret. Chairman Europe at Microsoft Corporation",
  //   },
  //   {
  //     type: "text",
  //     text: "I have known Dragan Vujovic since 2002 or nearly 15 years. Dragan taught me stress reduction techniques that I can suggest without a doubt, saved or at least extended my life a significant number of years.Cardiovascular disease is the number one cause of death in the United States. And stress is a major contributor to this disease.  Perhaps of equal importance, is Dragan’s ability to provide positivity in some of the more dire circumstances and truly is what makes him so special. And, his ability to extend the most appropriate tools to maintain that positivity separates him from many of the world’s leading mental trainers.To be able to retain and re-apply Dragan’s techniques on demand, and in the now moment has helped me realize many goals. I would wholeheartedly recommend to anyone struggling with self-limiting habits, high stress levels and low self-esteem, the investment of a small amount of personal time and get to know Dragan and his wonderful mental training techniques.",
  //     image: "https://draganv.com/wp-content/uploads/2016/11/Terry4-orez.jpg",
  //     name: "Terrence Ellifritz",
  //     designation:
  //       "CEO Ellifritz & Associates LLC, Strategic Marketing Services",
  //   },
  //   // Add more testimonials as needed
  //   {
  //     type: "text",
  //     text: "I started a 2 week private course with Dragan as an anxious, negative, and lonely guy with very low self-esteem. The problem I was focused on was that I struggled with relationships and interacting with women I was attracted to. He taught me his mental techniques/tools that totally changed my outlook and perception of my life and led me to take responsibility and gain control over it. He went above and beyond and took me around the beautiful city of Prague, as I had traveled there by myself, and also Skyped me when I got back to Chicago to check in and see how things were going. Just two months after the course, I met the love of my life, and we recently got engaged! Taking this course was truly the best decision I have ever made and I will be forever grateful to Dragan!",
  //     image: "https://draganv.com/wp-content/uploads/2016/11/Mark-Ninyo.jpg",
  //     name: "Mark Ninyo",
  //     designation: "Financial Analyst, Chicago, USA",
  //   },
  //   {
  //     type: "text",
  //     text: "I would have never imagined how strongly my life could be influenced, stimulated and enlightened. Meeting Dragan that day on the beach during my holiday in Egypt almost 3 years ago has changed everything; the way how I see and how I treat the world, myself and the others. Being equipped with loads of theories is one thing, but having them integrated in our daily lives with the help of Dragan as a personal trainer and friend gives a totally different life perspective.",
  //     image:
  //       "https://draganv.com/wp-content/uploads/2016/11/final-4947web-1.jpg",
  //     name: "Adam Stastny",
  //     designation: "Keyence International, Manager",
  //   },
  //   {
  //     type: "text",
  //     text: "For almost fifteen years when I lived in Prague I was mentored regularly and successfully by Dragan Vujovic, a dedicated professional in human potential development. It is a pleasure to endorse and recommend any and all of Dragan’s education programs.",
  //     image:
  //       "https://draganv.com/wp-content/uploads/2016/11/Peter-Magurean-III.jpg",
  //     name: "Peter Magurean III",
  //     designation: "Enterpreneur and Philantropist, Miami Beach, Florida, USA",
  //   },
  //   // {
  //   //   type: "video",
  //   //   video: "/videos/testi.mp4",
  //   //   name: "Nigel Atkins",
  //   //   designation: "Developer, University professor, Paris, France",
  //   // },
  //   {
  //     type: "text",
  //     text: "The training sessions were interactive and very informative. Dragan was easy to  understand and did a great job of conveying the information. He was also easily  accessible via email.",
  //     name: "Tanya Sims",
  //     image: "/img/reviews/tanya.png",
  //     designation: "Literacy Specialist , Klein ISD, Spring, TX, USA ",
  //   },
  //   {
  //     type: "text",
  //     text: "I highly recommend Dragan and his program to all Klein ISD employees. Dragan is very easy to talk to and has a great program to help anybody improve their life and begin to realize a life without stress.",
  //     name: "Chad Greene",
  //     image: "/img/reviews/chad.png",
  //     designation:
  //       "Manager – Application Support Services Klein ISD, Information Technology –  Teaching & Learning Team",
  //   },
  //   {
  //     type: "text",
  //     text: "Dragan's communication throughout our program was extremely reliable and helpful. I knew that if a time was set to meet by Skype that he would be there 5 minutes early every time.  His approach to stress and positive thinking was delivered to us in a way that made all of us realize that our lives can be enjoyed with much less stress.  He taught us how to manage in a way that was easy to understand, beneficial, and enjoyable.  He has a nice mixture of relating to his own personal experiences to the many things he has learned from others in life. ",
  //     name: "Tony Scherrman",
  //     image: "/img/reviews/tony.png",
  //     designation: "Brill Counselor",
  //   },
  //   {
  //     type: "text",
  //     text: "Mr. Dragan V You gave me a new perspective and some concrete steps to take to change my thinking and response to these issues. I appreciate the time and energy you invested in me and the other group members. You brought a fresh perspective to issues we considered problems and gave us creative ways to deal with these issues. Thank you. ",
  //     name: "Tammy Koonce",
  //     image: "/img/reviews/tammy.png",
  //     designation: "Spring Texas",
  //   },
  //   {
  //     type: "text",
  //     text: "Dragan’s delivery method of instructional videos and practice session videos is very effective. He uses real-life examples as he teaches his tools and then gives the learner an opportunity for guided practice. Dragan clearly explains how different life will “look” when using his tools, making it easy for the learner to see the result of applying the tools.",
  //     name: "Annaliese Altendorf ",
  //     image: "/img/reviews/annalisa.png",
  //     designation: "P.E. teacher, Kuehnle Elementary, Klein I.S.D.",
  //   },
  //   {
  //     type: "text",
  //     text: "Working with Dragan is enjoyable and entertaining. He has a wealth of knowledge about the mind and more that I wish we had more time to explore. I feel that his explanations  and connections are relevant and help the participants to understand not only how to use these tools, but also the importance of using them. He sincerely wants to improve the quality and quantity of life of his participants. ",
  //     name: "Colleen Horst",
  //     image: "/img/reviews/coleen.png",
  //     designation: "Athens SD, Ohio, USA",
  //   },

  //   {
  //     type: "text",
  //     text: "Dragan Vujovic ia an amazing human being and I recommend him to everyone as a life coach. He is non-judgemental, caring, helpful, and he is willing to take time to find your stressors and individualize instruction to fit your personal life. ",
  //     name: "Jane Morris-Maynard ",
  //     image: "/img/reviews/jane.png",
  //     designation: "English Language Arts Athens SD, Ohio, USA",
  //   },
  //   {
  //     type: "text",
  //     text: "Using the techniques provided by Dragan, I am now  able to transform the worry in a positive direction.  I also enjoy waking every morning and  sharing my gratitude for the day.  This very simple gesture puts my day in perspective and allows me to embrace the day in its fullest. I enjoyed Dragan's approach to the sessions.",
  //     name: "Susan Matters",
  //     image: "/img/reviews/susan.png",
  //     designation: "Athens SD, Ohio, USA",
  //   },
  //   {
  //     type: "text",
  //     text: " “After sharing a substantial amount of professional discourse with Dragan over the last quarter, both telephonically and via video-conferencing, I can state without hesitation that he is 100% committed to mutual cooperation, respect and communication across all aspects of his mentoring and consultation. That being said, as a mental coach, Dragan’s services are superior to any others I have leveraged or engaged with in either my personal or professional life. For those out there looking to better their lives through improved stress management, I highly recommend connecting with Dragan as he will truly make a difference in the quality of your life.”",
  //     name: "Melissa Bingham",
  //     image: "/img/reviews/melissa.png",
  //     designation: "Grade 3, Gladstone Street School Rhode Island ",
  //   },
  //   // {

  //   // }
  //   // Add more testimonials as needed
  // ];

  // Masonry breakpoint columns
  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    700: 1,
  };

  useEffect(() => {
    console.log(reviews, "reviews");
  }, [reviews]);

  return (
    <div className="no-select">
      <div className="container">
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {reviews.map((item, index) => (
            <Fade duration={700}>
              <TestimonialCard key={index} data={item} />
            </Fade>
          ))}
        </Masonry>
      </div>
    </div>
  );
};

export default TestimonialGrid;
