import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import "./common.css"
import { useMemo } from 'react'
import { useState } from 'react'
import HeadingNew from '../../AboutUs/HeadingNew'
import CourseGridSection from './CourseGridSection'
import CourseAccordionSectionCombo from './CourseAccordionSectionCombo'
import { useContext } from 'react'
import { AuthContext } from '../../../context/AllContext'
import { requestData } from '../../../utils/baseUrl'

function PopularSection() {
    const { isMobile, userData } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [listData, setListData] = useState([]);


    // const tabs = useMemo(() => {
    //     return [
    //         {
    //             name:"All Courses",
    //         },
    //         {
    //         name:"Silva Method Life System",
    //         id:"silva-life-system-digital-audio"
    //     },
    //     {
    //         id:"silva-method-manifesting-program",
    //         name:"Silva Method Manifesting System"
    //     },
    //     {
    //         name:"Silva Method Intuition System",
    //         id:"silva-intuition-system-digital-audio-program"
    //     },
    //     {
    //         name:"Silva Mind Body Healing System",
    //         id:"silva-mind-body-healing-program"
    //     },
    //     {
    //         name:"Silva Alpha Sound",
    //         id:"silva-alpha-sound"
    //     },
    //     {
    //         name:"Silva Theta Sound",
    //         id:"silva-theta-sound"
    //     },
    //     {
    //         name:"Silva Love Program",
    //         id:"love-program"
    //     },
    //     {
    //         name:"Weekly Journal to Finding a Soul Mate",
    //         id:"weekly-journal-to-finding-a-soul-mate"
    //     }
    // ]
    // }, [])

    const [tabs, setTabs] = useState([])


    // function arraysAreEqual(arr1, arr2) {
    //     if (arr1.length !== arr2.length) {
    //         return false;
    //     }
    //     for (let i = 0; i < arr1.length; i++) {
    //         if (arr1[i] !== arr2[i]) {
    //             return false;
    //         }
    //     }
    //     return true;
    // }

    function arraysAreEqual(arr1, arr2) {
        // Check if every element in arr2 is included in arr1
        return arr2.every(element => arr1.includes(element));
    }

    const getAllData = async () => {
        setLoading(true)
        const res = await requestData('latestCourseList', "POST", {
            "start_index": "0",
            "no_of_records": "20"
        })
        setLoading(false)




        // console.log(is_current_combo, "is_current_combo")




        const enrolled = userData ? userData.enrolled_course.map((val) => val.course_id) : [];



      

        const filtereddata = res.data.map((val, i) => {
            if (val.is_combo_course === "Yes") {
                const mappedincluded = val.included_course.map((val) => val.course_id);

                const result = arraysAreEqual(enrolled, mappedincluded)

                console.log(result,"result",val)

                return {
                    ...val,
                    course_purchased: result,
                    included_course: val.included_course,
                    is_combo_course:val.is_combo_course
                }


            } else {

                return {
                    ...val,
                    course_purchased: enrolled.includes(val.course_id),
                    included_course: val.included_course,
                    is_combo_course:val.is_combo_course
                }

            }
        })

        const filtered2 = filtereddata.filter((courseval) => courseval.is_combo_course === "No")

        // setTabs

        const mappedcourses = filtereddata.map((val) => {
            return {
                name: val.course_title,
                id: val.course_id,
                included_course: val.included_course,
                is_combo_course:val.is_combo_course,
                course_purchased:val.course_purchased
            }
        }).reverse()
        setTabs(mappedcourses)
        setListData(filtered2);
    }


    const [index, setIndex] = useState(0);


    useEffect(() => {
        getAllData()
    }, [])



    return (
        <div className='container my-5'>
            <HeadingNew title={"Complete Digital Audio Courses"} />
            <Row className={isMobile ? 'justify-content-center align-items-center mb-5 text-nowrap' : 'justify-content-center align-items-start my-5'}>
                <Col xs={12} md={2}>
                    <div className={isMobile ? 'd-flex flex-row overflow-scroll gap-4 container mb-4' : 'd-flex flex-column gap-3'}>
                        {
                            tabs.map((val, i) => (
                                <div style={{
                                    fontSize: "16px",
                                    color: "black",
                                    fontWeight: "500",
                                    paddingLeft: "10px",
                                    cursor: "pointer",
                                }}
                                    onClick={() => {
                                        setIndex(i)
                                    }}
                                    className={index === i ? 'active-tabbtn py-3' : 'py-3'}
                                >
                                    {val.name}
                                </div>
                            ))
                        }
                    </div>


                </Col>
                <Col xs={12} md={10}>
                    {
                        tabs && tabs[index] && tabs[index]?.id && tabs[index]?.is_combo_course === "Yes" ? (
                            <CourseGridSection tabs={tabs} tabid={tabs[index].id} tab={tabs[index]} loading={loading} setLoading={setLoading} listData={listData} setListData={setListData} getAllData={getAllData} />
                        ) : tabs && tabs[index] && tabs[index]?.id ? (
                            <CourseAccordionSectionCombo id={tabs[index].id} listData={listData} tab={tabs[index]}  />
                        ):null
                    }

                </Col>

            </Row>

        </div>
    )
}

export default PopularSection