import React, { useContext } from "react";
import { BsFacebook, BsTwitter, BsLinkedin } from "react-icons/bs";
import { AiOutlineInstagram } from "react-icons/ai";
import { TfiPinterest, TfiYoutube } from "react-icons/tfi";
import { Link, NavLink, useNavigate } from "react-router-dom";
import FooterImage from "../../../../img/footer-right-bottom-image.png";
import { AuthContext } from "../../../../context/AllContext";
import { FaWhatsapp } from "react-icons/fa";
import { Col, Image, Row } from "react-bootstrap";
import { MdEmail } from "react-icons/md";
import { RiWhatsappFill } from "react-icons/ri";
import { IoIosPin } from "react-icons/io";
import "./video-background.css";

function CustomFooter() {
  const { isTablet, isMobile } = useContext(AuthContext);

    const navigate = useNavigate();

  return (
    // <div className="custom-footer-container p-2">
    //   <div className="row">
    //     <div className="col-md-12">
    //       <footer className="custom-footer mx-3">
    //         <div className="container">
    //           <div className="row">
    //             <div className="col-md-3 m-b-30">
    //               <div className="custom-footer-title m-t-5 m-b-20 p-b-8">
    //                 Courses
    //               </div>
    //               <div className="custom-footer-links">
    //                 <Link
    //                   to="/courses/silva-life-system-seminar"
    //                   style={{
    //                     textDecoration: "none",
    //                     border: "none",
    //                     padding: "0",
    //                     margin: "0",
    //                   }}
    //                 >
    //                   <a>Silva Life System</a>
    //                 </Link>

    //                 <Link
    //                   to="/courses/silva-intution-system-seminar"
    //                   style={{
    //                     textDecoration: "none",
    //                     border: "none",
    //                     padding: "0",
    //                     margin: "0",
    //                   }}
    //                 >
    //                   <a>Silva Intuition System</a>
    //                 </Link>

    //                 <Link
    //                   to="/courses/silva-mastery-system"
    //                   style={{
    //                     textDecoration: "none",
    //                     border: "none",
    //                     padding: "0",
    //                     margin: "0",
    //                   }}
    //                 >
    //                   <a>Silva Mastery System</a>
    //                 </Link>

    //                 <Link
    //                   to="/courses/basic-lecture-series"
    //                   style={{
    //                     textDecoration: "none",
    //                     border: "none",
    //                     padding: "0",
    //                     margin: "0",
    //                   }}
    //                 >
    //                   <a>Basic Lecture Series(BLS)</a>
    //                 </Link>
    //               </div>
    //             </div>
    //             <div className="col-md-3 m-b-30">
    //               <div className="custom-footer-title m-t-5 m-b-20 p-b-8">
    //                 Quick Links
    //               </div>
    //               <div className="custom-footer-links">
    //                 <Link to="/youtube-videos">
    //                   Silva Method Youtube Videos
    //                 </Link>

    //                 {/* <Link to="/store/books" style={{ textDecoration: "none", border: "none", padding: "0", margin: "0" }}>
    //                                         <a
    //                                         >
    //                                             Books
    //                                         </a>
    //                                     </Link> */}
    //                 <a href="https://silvamethod.com/stores/shop/">Books</a>
    //                 <Link
    //                   to="/silva-method-faq"
    //                   style={{
    //                     textDecoration: "none",
    //                     border: "none",
    //                     padding: "0",
    //                     margin: "0",
    //                   }}
    //                 >
    //                   <a>FAQs</a>
    //                 </Link>

    //                 <Link to="/become-silva-affiliate">
    //                   Become an Affiliate
    //                 </Link>

    //                 <a href="https://www.silvacasesonline.com/">Silva Cases</a>

    //                 <Link to="/silva-method-courses">Join Now</Link>
    //               </div>
    //             </div>
    //             <div className="col-md-3 m-b-30">
    //               <div
    //                 className="custom-footer-title m-t-5 m-b-20 p-b-8"
    //                 style={{ wordBreak: "break-all" }}
    //               >
    //                 Silva Updates
    //               </div>
    //               <div className="custom-footer-links">
    //                 <Link to="/what-is-silva-method">
    //                   What is Silva Method?
    //                 </Link>
    //                 <Link to="/">Jose Silva Method Courses</Link>
    //                 {/* <Link to="/">Silva Mind Control Method</Link> */}
    //                 <Link to="/jose-silva-method">
    //                   A biography on Jose Silva’s Penmanship
    //                 </Link>
    //                 <Link to="/youtube-videos">Youtube Videos</Link>
    //               </div>
    //             </div>
    //             <div className="col-md-3 m-b-30">
    //               <div className="custom-footer-title m-t-5 m-b-20 p-b-8">
    //                 About us
    //               </div>
    //               <div style={{ wordBreak: "break-all" }}>
    //                 <p className="text-light px-3">
    //                   We are a global organization dedicated to changing lives
    //                   by empowering minds.
    //                 </p>
    //                 <p className="text-light px-3">
    //                   Reach us: digital@silvamethod.com
    //                 </p>
                
    //                 <button
    //                   className="footer-whatapp-Btn"
    //                   onClick={() => {
    //                     const whatsappGroupURL =
    //                       "https://chat.whatsapp.com/JIc1WVgSMxA4Kg2Fxx5VqZ";
    //                     window.open(whatsappGroupURL);
    //                   }}
    //                   style={{fontSize:'11px', textTransform:'capitalize',paddingRight:10,paddingLeft:10}}
    //                 >
    //                   <span>
    //                     <FaWhatsapp size={20} color="white" />
    //                   </span>{" "}
    //                   Join the Silva International Official WhatsApp
    //                 </button>
    //                 <a
    //                   href="https://www.bbb.org/us/tx/laredo/profile/educational-consultant/silva-international-inc-0825-19971/#sealclick"
    //                   id="bbblink"
    //                   class="ruhzbum"
    //                   target="_blank"
    //                   rel="nofollow"
    //                 >
    //                   <img
    //                     src="https://seal-austin.bbb.org/logo/ruhzbum/bbb-19971.png"
    //                     style={{ border: "0" }}
    //                     alt="Silva International Inc BBB Business Review"
    //                   />
    //                 </a>
    //                 {/* <img src={FooterImage} /> */}
    //                 <div style={{ display: "flex" }}>
    //                   <NavLink to="/contact">
    //                     <button className="footer-contact-btn">
    //                       Contact Us
    //                     </button>
    //                   </NavLink>
    //                   <NavLink to="/login">
    //                     <button className="footer-join-btn">Join Us</button>
    //                   </NavLink>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </footer>
    //       <div
    //         className={
    //           isMobile || isTablet
    //             ? "custom-footer-btn2"
    //             : "custom-footer-bottom"
    //         }
    //       >
    //         <div className="d-flex align-items-center justify-content-center">
    //           <p className="text-light">
    //             Copyright © 2024, All Rights Reserved
    //           </p>
    //         </div>
    //         <div className="custom-footer-social-links d-flex align-items-center justify-content-center">
    //           <li>
    //             <a
    //               href="https://www.facebook.com/SilvaInternationalInc"
    //               target="_blank"
    //             >
    //               <BsFacebook />
    //             </a>
    //             <a href="https://twitter.com/SilvaHomeOffice" target="_blank">
    //               <BsTwitter />
    //             </a>
    //             <a
    //               href="https://www.instagram.com/silvamethodofficial/"
    //               target="_blank"
    //             >
    //               <AiOutlineInstagram />
    //             </a>
    //             <a
    //               href="https://www.pinterest.com/the_silvamethod/"
    //               target="_blank"
    //             >
    //               <TfiPinterest />
    //             </a>
    //             <a
    //               href="https://www.youtube.com/channel/UCaCDqnK1-i0rb9tFPznvI4w"
    //               target="_blank"
    //             >
    //               <TfiYoutube size={40} />
    //             </a>
    //             <a
    //               href="https://www.linkedin.com/company/thesilvamethods/"
    //               target="_blank"
    //             >
    //               <BsLinkedin size={40} />
    //             </a>
    //           </li>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className='bgfooternew' style={{

      paddingTop: "80px",
      paddingBottom: "50px"
  }}>
      <footer>
          <div className='container'>
              <Row className='justify-content-center align-items-start gy-5'>
                  <Col xs={12} md={6} lg={3} style={{
                      borderRight: "1px solid #ccc",
                  }}>
                      <div className='d-flex justify-content-center align-items-start flex-column gap-3 gap-md-4' style={{

                          paddingRight: "40px",
                          paddingLeft: isMobile ? "30px" : "0px"
                      }}>
                          <Image src='/img/footer/footer-03.webp' />
                          <p className='texthovergray' style={{
                              lineHeight: "1.2rem",

                          }}>
                              We are a global organization dedicated to changing lives by empowering minds.
                          </p>
                          <div className='d-flex justify-content-center align-items-start flex-column'>
                              <h5 className='fw-semibold text-light'>Contact Info</h5>
                              <p className='texthovergray'>
                                  <span style={{
                                      marginRight: "10px",
                                  }}><MdEmail color='white' size={20} /></span>
                                  <a href="mailto:digital@silvamethod.com" style={{
                                      color: "white"
                                  }}>digital@silvamethod.com</a>
                              </p>
                          </div>

                          <div className='d-flex justify-content-center align-items-start flex-column gap-2'>
                              <h5 className='fw-semibold text-light'>Stay Connected</h5>

                              <div className='d-flex gap-2'>
                                  <Image src='/img/footer/footer-05.webp' style={{
                                      cursor: "pointer"
                                  }} width={40} height={40}
                                      onClick={() => {
                                          window.open("https://www.youtube.com/channel/UCaCDqnK1-i0rb9tFPznvI4w")
                                      }}
                                  />
                                  <Image src='/img/footer/footer-06.webp' style={{
                                      cursor: "pointer"
                                  }} width={40} height={40}
                                      onClick={() => {
                                          window.open("https://www.instagram.com/silvamethodofficial/")
                                      }}
                                  />
                                  <Image src='/img/footer/footer-07.webp' style={{
                                      cursor: "pointer"
                                  }} width={40} height={40}
                                  onClick={() => {
                                      window.open("https://www.facebook.com/SilvaInternationalInc")
                                  }}
                                  />
                                  <Image src='/img/footer/footer-08.webp' width={40} height={40} style={{
                                      cursor: "pointer"
                                  }}
                                  onClick={() => {
                                      window.open("https://x.com/SilvaHomeOffice?mx=2")
                                  }}
                                  />
                                  <Image src='/img/iconbookpage/icon-16.svg' width={40} height={40} style={{
                                      cursor: "pointer"
                                  }}
                                  onClick={() => {
                                      window.open("https://www.tiktok.com/@silvamethodinternational")
                                  }}
                                  />

                              </div>
                          </div>
                      </div>

                  </Col>

                  <Col xs={12} md={6} lg={3}>
                      <div className='d-flex justify-content-center align-items-start flex-column gap-3 gap-md-5' style={{
                          paddingLeft: isMobile ? "30px" : "40px"
                      }}>
                          <div className='d-flex justify-content-center align-items-start flex-column gap-3'>
                              <h5 className='fw-semibold text-light'>Courses</h5>
                              <ul style={{
                                  listStyleType: "none",
                                  lineHeight: "25px"
                              }}>
                                  <li style={{
                                      cursor:"pointer"
                                  }}
                                  onClick={()=>{
                                      navigate("/courses/silva-life-system-seminar")
                                  }}
                                  className='texthovergray'
                                  >Silva Life System</li>
                                  <li style={{
                                      cursor:"pointer"
                                  }}
                                  onClick={()=>{
                                      navigate("/courses/silva-intution-system-seminar")
                                  }}
                                  className='texthovergray'
                                  >Silva Intuition System</li>
                                  <li style={{
                                      cursor:"pointer"
                                  }}
                                  onClick={()=>{
                                      navigate("/courses/silva-mastery-system")
                                  }}
                                  className='texthovergray'
                                  >Silva Mastery System</li>
                                  <li style={{
                                      cursor:"pointer"
                                  }}
                                  onClick={()=>{
                                      navigate("/courses/basic-lecture-series")
                                  }}
                                  className='texthovergray'
                                  >Basic Lecture Series(BLS)</li>
                                   <li style={{
                                      cursor:"pointer"
                                  }}
                                  onClick={()=>{
                                      navigate("/silva-method-courses")
                                  }}
                                  className='texthovergray'
                                  >Silva Method Original Courses</li>

                              </ul>
                          </div>
                          <div className='d-flex justify-content-center align-items-start flex-column gap-3'>
                              <h5 className='fw-semibold text-light'>Silva Updates</h5>
                              <ul style={{
                                  listStyleType: "none",
                                  lineHeight: "25px"
                              }}>
                                  <li style={{
                                      cursor:"pointer"
                                  }}
                                  onClick={()=>{
                                      navigate("/what-is-silva-method")
                                  }}
                                  className='texthovergray'
                                  >What is Silva Method</li>
                                  <li style={{
                                      cursor:"pointer"
                                  }}
                                  onClick={()=>{
                                      navigate("/success-stories")
                                  }}
                                  className='texthovergray'
                                  >Silva Method Success Stories</li>
                                  <li style={{
                                      cursor:"pointer"
                                  }}
                                  onClick={()=>{
                                      navigate("/jose-silva-method")
                                  }}
                                  className='texthovergray'
                                  >A biography on Jose Silva's penmanship</li>
                                  <li style={{
                                      cursor:"pointer"
                                  }}
                                  onClick={()=>{
                                      navigate("/youtube-videos")
                                  }}
                                  className='texthovergray'
                                  >Youtube Videos</li>

                              </ul>
                          </div>
                      </div>
                  </Col>
                  <Col xs={12} md={6} lg={3}>
                      <div className='d-flex justify-content-center align-items-start flex-column gap-3 gap-md-5' style={{
                          paddingLeft: isTablet?"0px":"30px"
                      }}>
                          <div className='d-flex justify-content-center align-items-start flex-column gap-3'>
                              <h5 className='fw-semibold text-light'>Quick Links</h5>
                              <ul style={{
                                  listStyleType: "none",
                                  lineHeight: "25px"
                              }}>
                                  {/* <li style={{
                                      cursor:"pointer"
                                  }}
                                  onClick={()=>{
                                      navigate("/youtube-videos")
                                  }}
                                  className='texthovergray'
                                  >Silva Method Youtube Videos</li> */}
                                  <li style={{
                                      cursor:"pointer"
                                  }}
                                  onClick={()=>{
                                     window.open("https://silvamethod.com/stores/shop/")
                                  }}
                                  className='texthovergray'
                                  >Books</li>
                                  <li style={{
                                      cursor:"pointer"
                                  }}
                                  onClick={()=>{
                                      navigate("/silva-method-faq")
                                  }}
                                  className='texthovergray'
                                  >FAQs</li>
                                  <li style={{
                                      cursor:"pointer"
                                  }}
                                  onClick={()=>{
                                      navigate("/become-silva-affiliate")
                                  }}
                                  className='texthovergray'
                                  >Become an Affiliate</li>
                                  <li style={{
                                      cursor:"pointer"
                                  }}
                                  onClick={()=>{
                                      window.open("https://silvacasesonline.com")
                                  }}
                                  className='texthovergray'
                                  >Silva Cases</li>
                                  <li style={{
                                      cursor:"pointer"
                                  }}
                                  onClick={()=>{
                                      navigate("/login")
                                  }}
                                  className='texthovergray'
                                  >Join Now</li>
                              </ul>
                          </div>

                          <div className='d-flex justify-content-center align-items-start flex-column gap-3 flex-wrap'>
                              <h5 className='fw-semibold text-light'>Silva International <br /> Whatsapp group</h5>
                              <button className='footer-whatapp-Btn' style={{
                                  margin: "0px",
                              }}
                              onClick={()=>{
                                  window.open("https://chat.whatsapp.com/JIc1WVgSMxA4Kg2Fxx5VqZ")
                              }}
                              >
                                <span><RiWhatsappFill color='white' size={20} /></span>  Join Group

                              </button>
                          </div>

                      </div>
                  </Col>
                  <Col xs={12} md={6} lg={3}>

                      <div className='d-flex justify-content-center align-items-start flex-column gap-3 gap-md-4' style={{
                          paddingLeft: "30px"
                      }}>

                          <div className='d-flex justify-content-center align-items-start flex-column gap-2'>
                              <h6 className='fw-semibold text-light'>Rating A+</h6>
                              <a
                                  href="https://www.bbb.org/us/tx/laredo/profile/educational-consultant/silva-international-inc-0825-19971/#sealclick"
                                  id="bbblink"
                                  class="ruhzbum"
                                  target="_blank"
                                  rel="nofollow"
                              >
                                  <img
                                      src="https://seal-austin.bbb.org/logo/ruhzbum/bbb-19971.png"
                                      style={{ border: "0" }}
                                      alt="Silva International Inc BBB Business Review"
                                  />
                              </a>
                          </div>

                          <div className='d-flex justify-content-center align-items-start flex-column gap-2'>
                              <h6 className='fw-semibold text-light'>Reach Us</h6>
                              <div className='d-flex gap-2'>
                                  <button className='btn' style={{
                                      background: "linear-gradient(90deg, rgba(53,32,230,1) 0%, rgba(217,1,252,1) 49%)",
                                      color: "white",
                                      borderRadius: "10px",
                                      border:"none",
                                  }}
                                  onClick={()=>{
                                      navigate("/contact") 
                                  }}
                                  >Contact us</button>
                                  <button className='btn btn-light' style={{
                                      borderRadius: "10px",
                                  }}
                                  onClick={()=>{
                                      navigate("/login")
                                  }}
                                 
                                  >Join us</button>


                              </div>
                          </div>
                          <div className='d-flex justify-content-center align-items-start flex-column gap-2'>
                              <h6 className='fw-semibold text-light'><span style={{
                                  marginRight: "5px"
                              }}><IoIosPin color='red' /></span> Find us on google maps</h6>
                              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7076.144526545807!2d-99.47702!3d27.529214000000003!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x866121401541c593%3A0x5b7e28a5e5bcc98f!2s3426%20N%20Buena%20Vista%20Ave%2C%20Laredo%2C%20TX%2078043%2C%20USA!5e0!3m2!1sen!2sin!4v1715943007951!5m2!1sen!2sin" style={{
                                  width: isMobile?"100%":"80%",
                                  height: isMobile?"200px":"150px",
                                  borderRadius: "10px",
                                  border: "0px"
                              }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                          </div>

                      </div>
                  </Col>
              </Row>
          </div>

      </footer>
  </div>
  );
}

export default CustomFooter;
