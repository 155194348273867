import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import useAuth from '../../../hooks/useAuth';
import { useForm } from "react-hook-form";
import { Alert, Spinner } from "react-bootstrap";
import { baseUrl } from "../../../utils/axioscall";
import { toast } from "react-hot-toast";
import { AuthContext } from "../../../context/AllContext";
import OtpModal from "../../../components/OtpModal/OtpModal";
import SendOTP from "./SendOTP";
import VerifyOTP from "./VerifyOTP";
import { requestData, requestData2 } from "../../../utils/baseUrl";
import { reactLocalStorage } from "reactjs-localstorage";
import "./login2.css";

import { AiOutlineMail, AiFillLock, AiFillEye, AiFillEyeInvisible } from "react-icons/ai"
import SocialLoginCommon from "./SocialLoginCommon";

function LoginForm({ activeTab }) {
    const coupon = reactLocalStorage.get("coupon")
    const cplan = reactLocalStorage.get("couponplan")
    const { search } = useLocation();
    //console.log(search);
    const planId = search?.split("=")[1];
    //console.log(planId);

    const [otpsent, setOtpSent] = useState(() => {
        if (localStorage.getItem("otpsent")) {
            return true;
        } else {
            return false;
        }
    });

    const [emailvalue,setEmailvalue] = useState("");
    const [show, setShow] = useState(false);
    const [showForgot, setShowForgot] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleShowForget = () => setShowForgot(true)
    const handleCloseForget = () => setShowForgot(false)

    const [loading, setLoading] = useState(false);
    const [loadingForgot,setLoadingForgot] = useState(false);

    const navigate = useNavigate();
    // const { loginUser, passwordResetWithEmail, googleSignIn } = useAuth();
    const [showPass, setShowPass] = useState(false);
    const {
        setIsUserLoggedIn,
        setUserData,
        setUserToken,
    } = useContext(AuthContext);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();



    const onSubmit = async (data) => {
        // console.log(data)

        // console.log(data);
        // console.log(errors)
        // const res = await axiosinstance.post("login", {
        //    email: data.email,
        //    password: data.password
        // })

        loginfetch(data.email, data.password);

        // loginUser(email, password,reset)
    };


    const loginfetch = async (email, password) => {
        var myHeaders = new Headers();
        myHeaders.append("ApiKey", "40bb9d38c66e40a86678979286f4e2b5");
            myHeaders.append("Device", "Web");
        myHeaders.append("Language", "english");
        myHeaders.append("DeviceId", "{{asdfwedfgdasfds}}");
        // myHeaders.append("Token", "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJzaWx2YW1ldGhvZC5jb20iLCJhdWQiOiJUaGVfQXVkIiwiaWF0IjoxNjgxNDQ0MzI3LCJuYmYiOjE2ODE0NDQzMzcsImV4cCI6NTM1MjE0NDQzMjcsImRhdGEiOiIxIn0.D-p1cFH0rSYBWN1tGnDG1UUvRejaEdwkcoxgcWAlwmo");

        var formdata = new FormData();
        formdata.append("email", email);
        formdata.append("password", password);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };

        setLoading(true);
        const res = await fetch(`${baseUrl}login`, requestOptions);
        setLoading(false);
        const data = await res.json();
        if (data.error === false) {
            setUserToken(data.data.profile.student_id);
            reactLocalStorage.set("token", data.data.profile.student_id);
            setIsUserLoggedIn(true);
            toast.success(data.messages);
            // console.log(data,"Dataa")
            setUserData({
                ...data.data.profile,
                total_course:data.total_course
            });
            const body = {

            }

            if (coupon && cplan == planId) {
                body.coupon_code = coupon
            }

            if (planId && (data.data.profile.enrollment_status !== "Enrolled")) {
                const res = await requestData2(
                    `subscribeNowCourse?plan_id=${planId}`,
                    "POST",
                    body
                );
                // reactLocalStorage.remove("coupon")
                // reactLocalStorage.remove("couponplan")

                if (res?.error === false) {
                    // reactLocalStorage.remove("coupon")
                    // reactLocalStorage.remove("couponplan")

                    window.location.assign(res.data);
                    return;
                } else {
                    return;
                }
            } else if (
                data.data.profile.enrollment_status === "Enrolled"
            ) {
                // reactLocalStorage.remove("coupon")
                // reactLocalStorage.remove("couponplan")


                // console.log("paidrun");
                navigate("/today");
            } else if (
                (data.data.profile.enrollment_status !== "Enrolled")
            ) {
                navigate("/");
            } else if (
                (data.data.profile.enrollment_status !== "Enrolled")
            ) {
                navigate("/");
            }
        } else {
            toast.error(data.messages);
        }
        // console.log(data);
    };


    const fetchSocialLogin = async (email, firstname, lastname, image) => {
        // console.log("api calling")
        var myHeaders = new Headers();
        myHeaders.append("ApiKey", "40bb9d38c66e40a86678979286f4e2b5");
            myHeaders.append("Device", "Web");
        myHeaders.append("Language", "english");
        myHeaders.append("DeviceId", "{{asdfwedfgdasfds}}");
        // myHeaders.append("Token", "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJzaWx2YW1ldGhvZC5jb20iLCJhdWQiOiJUaGVfQXVkIiwiaWF0IjoxNjgxNDQ0MzI3LCJuYmYiOjE2ODE0NDQzMzcsImV4cCI6NTM1MjE0NDQzMjcsImRhdGEiOiIxIn0.D-p1cFH0rSYBWN1tGnDG1UUvRejaEdwkcoxgcWAlwmo");

        var formdata = new FormData();
        formdata.append("email", email);
        formdata.append("first_name", firstname);
        formdata.append("last_name", lastname);
        formdata.append("profile_image", image);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };

        const res = await fetch(baseUrl + "loginWithSocial", requestOptions);
        const data = await res.json();
        // console.log(data, "socialdata");
        if (data && data.error === false) {
            setUserToken(data.data.profile.student_id);
            // sessionStorage.setItem("token",data.data.token)
            localStorage.setItem("token", data.data.profile.student_id);
            localStorage.setItem("google_login", true);
            setIsUserLoggedIn(true);
            toast.success(data.messages);
            setUserData({
                ...data.data.profile,
                total_course:data.total_course
            });
            const body = {

            }

            if (coupon && cplan == planId) {
                body.coupon_code = coupon
            }
            // Clear the token from local storage and redirect to login page
            if (planId && (data.data.profile.enrollment_status !== "Enrolled")) {
                const res = await requestData2(
                    `subscribeNowCourse?plan_id=${planId}`,
                    "POST",
                    body
                );
                // reactLocalStorage.remove("coupon")
                // reactLocalStorage.remove("couponplan")

                if (res?.error === false) {
                    // reactLocalStorage.remove("coupon")
                    // reactLocalStorage.remove("couponplan")

                    window.location.assign(res.data);
                    return;
                } else {
                    return;
                }
            } else if (
               (data.data.profile.enrollment_status === "Enrolled") 
            ) {
                // reactLocalStorage.remove("coupon")
                // reactLocalStorage.remove("couponplan")

                // console.log("paidrun");
                navigate("/today");
            } else if (
                (data.data.profile.enrollment_status !== "Enrolled")
            ) {
                // reactLocalStorage.remove("coupon")
                // reactLocalStorage.remove("couponplan")

                navigate("/");
            } else if (
                (data.data.profile.enrollment_status !== "Enrolled")
            ) {
                
                navigate("/");
            }

            // const res = await requestData("courseListWithChild", "POST", { start_index: 0 });
            // console.log(data.data.profile.strip_payment_status, "linkres");
        } else {
            toast.error(data.messages);
        }
        // console.log(data);
    };


    const handleForgotPassword = async()=>{

        const matchedEmail =  /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(emailvalue);

        if(!matchedEmail){
            toast.error("Please enter a valid email");
            return
        }
        setLoadingForgot(true)
        const res = await requestData("forgotPassword","POST",{
            email:emailvalue
        })

        setLoadingForgot(false)

        if(res && res.error===false){
            toast.success(res.messages,{
                position:"top-center"
            })
            setEmailvalue("")
            handleCloseForget()
        }else{
            toast.error(res.messages)
        }
    }

    return (
        <>
            <div className='mt-3' style={{ width: "100%" }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <SocialLoginCommon fetchSocialLogin={fetchSocialLogin} handleShow={handleShow} activeTab={activeTab} />
                    <span>or</span>
                    <div className="inputField">
                        <div className="inputArea">
                            <span>
                                <AiOutlineMail color='#9d9d9d' />
                            </span>
                            <input
                                {...register("email", {
                                    required: true,
                                    pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                                })}
                                type="email" id="1-email" inputmode="email" name="email" className="auth0-lock-input"
                                placeholder="Your Email" autocomplete="off" autocapitalize="off" aria-label="Email"
                                aria-invalid="false" />
                        </div>
                        {errors.email && (
                            <Alert variant={"danger"}>
                                Invalid Email ! Please enter a valid email address
                            </Alert>
                        )}

                        <div className="inputArea">
                            <span>
                                <AiFillLock color='#9d9d9d' />

                            </span>
                            <input
                                {...register("password", {
                                    required: true,
                                    minLength: 3,
                                })}
                                type={showPass ? "text" : "password"}
                                name="password" class="auth0-lock-input" autocomplete="off"
                                autocapitalize="off" aria-label="Password" aria-invalid="false"
                                placeholder="Your Password" />
                            <span className="showPass">
                                {!showPass && (
                                    <AiFillEye
                                        color='#9d9d9d'
                                        onClick={() => {
                                            setShowPass(!showPass);
                                        }}
                                    />
                                )}
                                {showPass && (
                                    <AiFillEyeInvisible
                                        color='#9d9d9d'
                                        onClick={() => {
                                            setShowPass(!showPass);
                                        }}
                                    />
                                )}
                            </span>

                        </div>
                        {errors.password && (
                            <Alert variant={"danger"}>
                                Invalid Password ! Please enter a valid password
                            </Alert>
                        )}
                    </div>
                    <p><a className="rememPass" onClick={handleShowForget}>Don't remember your password?</a></p>
                    <button type="submit" class="btnnew">
                        <span className="btnSpan">
                            {
                                loading ? (
                                    <Spinner animation="border" variant="light" />
                                ) : "Login to Silva Method"
                            }</span>
                    </button>
                </form>


            </div>
            <OtpModal show={show} setShow={setShow} handleClose={handleClose}>
                {!otpsent ? (
                    <SendOTP setOtpSent={setOtpSent} />
                ) : (
                    <VerifyOTP
                        setOtpSent={setOtpSent}
                        handleClose={handleClose}
                        setIsUserLoggedIn={setIsUserLoggedIn}
                        planId={planId}
                    />
                )}
            </OtpModal>

            <OtpModal show={showForgot} setShow={setShowForgot} handleClose={handleCloseForget} title="Forgot Password">
                <div className='login-area'>
                    <div className="basic-login2">
                        <form onSubmit={(e)=>{
                            e.preventDefault();
                            handleForgotPassword()
                        }}>
                            <label htmlFor="forgotemail" className='mb-2'>Enter Your Email</label>
                            <input required id="forgotemail" name="forgotemail" type="email"
                                placeholder="Enter Email"
                                value={emailvalue}
                                onChange={(e)=>setEmailvalue(e.target.value)}
                                />

                            <button className='btn btn-success'>
                                {
                                    loadingForgot ? (
                                        <Spinner animation="border" variant="light" />
                                    ) : "Submit"
                                }
                            </button>
                            <button className='btn btn-danger mx-2' onClick={handleCloseForget}>
                               Close
                            </button>
                        </form>
                    </div>
                </div>

            </OtpModal>
        </>
    )
}

export default LoginForm