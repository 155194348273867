import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../context/AllContext";
import {
  Button,
  Card,
  Form,
  Image,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import { FaStripe, FaLock } from "react-icons/fa";
import {
  REACT_BASE_URL,
  domainUrl,
  reqData,
  requestData,
} from "../../../utils/baseUrl";
import toast from "react-hot-toast";
import moment from "moment";
import { useLocation } from "react-router-dom";

function ComboPaymentCard({ datanew, cardClicked = false }) {

  const [currentEvent, setCurrentEvent] = useState(null);
  const [defaultPayment, setDefaultPayment] = useState("Stripe");
  const [studentId, setStudentId] = useState("");
  const [paypalOrderID, setPaypalOrderID] = useState("");

  const { userData, isDesktopOrLaptop, isBigScreen, isMobile } = useContext(AuthContext);

  const formRef = useRef(null);

  const [loading, setLoading] = useState(false);

  const [priceplan2, setPricePlan2] = useState("");

  const [hasEmail, setHasEmail] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [paramCoupon, setParamCoupon] = useState("");
  const [validCoupon, setValidCoupon] = useState("");
  const [includeLiveClass, setIncludeLiveClass] = useState(false);
  
  
  const location = useLocation();
  const token = location?.state?.token;
  
  const btnRef = useRef(null);
  const searchParams = new URLSearchParams(location.search);
  const tokenParam = searchParams.get('token');
  // const couponNames=["SUNNYMIND","sunnymind","LASTCHANCE","SILVAMIND55"];
  
  const [coupon, setCoupon] = useState("" || token || tokenParam);

  useEffect(() => {
    if(token){
    setCoupon(token);
    }
  }, [token]);


  useEffect(()=>{
    if (tokenParam && coupon==="") {
       setCoupon(tokenParam)
    }
  },[location.search,tokenParam,searchParams]);

  useEffect(() => {
    // console.log(paramCoupon,'paramcoupon')
    if (coupon && (token || tokenParam) && datanew) {
      btnRef?.current?.click();
    }
  }, [coupon, datanew]);


  // useEffect(()=>{
  //   if(paramCoupon){
  //     setCoupon(paramCoupon);
  //   };
  //   if (coupon && coupon === "sunnymind" && datanew) {
  //     btnRef?.current?.click();
  //   }
  // },[datanew,paramCoupon,coupon])

  const handleApplyCoupon = async () => {
    if (!coupon) {
      toast.error("Please enter a coupon !");
      return;
    }
    const res = await requestData("applyCouponCode", "POST", {
      coupon_code: coupon,
    });

    if (res && res.error === false) {
      const validCoupon = moment(new Date(Date.now())).isBetween(
        res.data.start_date,
        res.data.end_date
      );

      let priceplan;

      // console.log(datanew,'data')

      const originalPrice = Number(datanew?.price);


      // console.log(originalPrice,'originalPrice')

      // Discount percentage
      const discountPercentage = Number(datanew?.discount_percentage) || 0;

      // console.log( typeof discountPercentage,'dscp')

      // Calculating the discount amount
      const discountAmount =
        (discountPercentage * parseInt(originalPrice)) / 100;

      // Calculating the discounted price
      const discountedPrice = Number(
        Math.floor(originalPrice - discountAmount).toFixed(2)
      );

      // Result
      // console.log(discountedPrice);
      priceplan = discountedPrice;

      // }

      // setPricePlan2(priceplan)
      if (validCoupon) {
        // reactLocalStorage.set("coupon", coupon2)
        // reactLocalStorage.set("couponplan", 3)
        // reactLocalStorage.setObject("value",val)
        const newdis =
          priceplan - priceplan * (Number(res?.data?.discount_percentage) / 100);


        // const found = plans.find(p=>p.cycle===cycle)
        if (newdis == priceplan) {
          toast.error("Coupon already applied", {
            position: "top-center",
          });
          return;
        }

        let finalPrice;

        if (includeLiveClass) {
          finalPrice =
            Math.ceil(newdis.toFixed(2)) + Number(currentEvent.combo_price);
        } else {
          finalPrice = Math.floor(newdis.toFixed(2));
        }
        // console.log(Math.floor(newdis.toFixed(2)),'newdis')

        console.log(finalPrice, 'finalPrice')
        setPricePlan2(finalPrice);

        setValidCoupon(coupon);

        // setPricePlan1(Number(plans[0].price).toFixed(2))

        toast.success("coupon applied", {
          position: "top-center",
        });
      } else {
        toast.error("Coupon is not valid !!", {
          position: "top-center",
        });
      }
    } else {
      toast.error(res.messages, {
        position: "top-center",
      });
    }
  };

  const handleSubscription = async () => {
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(userEmail);
    if (!isValidEmail) {
      toast.error("Please enter a valid email");
      return;
    }

    if (defaultPayment === "Stripe") {
      handleSubscriptionStripe();
    } else {
      handleSubscriptionPaypal();
    }
  };

  const handleSubscriptionPaypal = async () => {
    const data = {
      course_id: datanew.course_id,
      email: userEmail,
      payment_gatway: defaultPayment,
    };

    if (includeLiveClass) {
      data.event_id = "silva-life-intuition-training";
    }

    if (validCoupon) {
      data.coupon_code = validCoupon;
    }
    setLoading(true);
    const res = await requestData("enrollNow", "POST", data);

    // localStorage.setItem("token",res.student_id)

    if (res && res.error === false && res.status === 200) {
      setPaypalOrderID(res.order_id);
      setStudentId(res.student_id);
    } else {
      toast.error(res.messages);
    }

    // const gentok = await generateToken();

    // const courseName = datanew.course_title;
    // const order = await createOrder(
    //     gentok,
    //     priceplan2,
    //     res.order_id,
    //     courseName,
    //     res.student_id
    // );

    setLoading(false);

    // if (!!order?.links) {
    //     const url = order.links.find(link => link.rel == 'approve');
    //     window.location.href = url.href
    // }
  };

  const handleSubscriptionStripe = async () => {
    const data = {
      course_id: datanew.course_id,
      email: userEmail,
      payment_gatway: defaultPayment,
    };

    if (includeLiveClass) {
      data.event_id = "silva-life-intuition-training";
    }

    if (validCoupon) {
      data.coupon_code = validCoupon;
    }
    setLoading(true);
    const res = await requestData("enrollNow", "POST", data);
    // localStorage.setItem("token",res.student_id)
    setLoading(false);

    if (res && res.title === "StripeExceptionInvalidRequestException") {
      toast.error("You are already subscribed to stripe");
      return;
    }

    if (res && res.error === true) {
      toast.error(res.messages);
      return;
    }

    if (res && res.error === false) {
      window.location.assign(res.data);
    }
  };

  useEffect(() => {
    if (userData && userData.email) {
      setHasEmail(true);
      setUserEmail(userData.email);
    } else {
      setHasEmail(false);
    }
  }, [userData]);

  useEffect(() => {
    if (paypalOrderID && studentId) {
      if (formRef.current) {
        formRef.current.submit();
      }
    }
  }, [paypalOrderID, studentId]);

  useEffect(() => {
    if (datanew) {
      setPricePlan2(
        Math.floor(
          datanew?.price -
          ((datanew?.discount_percentage || 0) * parseInt(datanew?.price)) /
          100
        ).toFixed(2)
      );
    }
  }, [datanew]);

  useEffect(() => {
    if (!includeLiveClass && !location.state) {
      setCoupon("");
      setValidCoupon("");
    }

    if (datanew && datanew.course_id) {
      const course_id = datanew.course_id;

      if (course_id === "combo-plan") {
        if (includeLiveClass) {
          const pp = Math.floor(
            Number(priceplan2) + Number(currentEvent?.combo_price)
          )?.toFixed(2);

          setPricePlan2(pp);
        } else {
          setPricePlan2(
            Math.floor(
              datanew?.price -
              ((datanew?.discount_percentage || 0) *
                parseInt(datanew?.price)) /
              100
            ).toFixed(2)
          );
        }
      } else {
        if (includeLiveClass) {
          const pp = Math.floor(
            Number(priceplan2) + Number(currentEvent?.combo_price)
          )?.toFixed(2);

          // setPricePlan2(Math.floor(Number(priceplan2) + 700)?.toFixed(2));
          setPricePlan2(pp);
        } else {
          setPricePlan2(
            Math.floor(
              datanew?.price -
              ((datanew?.discount_percentage || 0) *
                parseInt(datanew?.price)) /
              100
            ).toFixed(2)
          );
        }
      }
    }
  }, [includeLiveClass, datanew]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const res = await reqData("internationalEventList", "POST", {});

        let found;

        if (res && res.error === false) {
          found = res.data.find(
            (val, i) => val.event_id === "silva-life-intuition-training"
          );
        }

        if (found) {
          setCurrentEvent(found);
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchEvents();
  }, []);


  // console.log(datanew,'datanew')

  return (
    <div className="container" style={{
      paddingRight: isMobile ? "0" : "auto"
    }}>
      <Card className="combo-price-card" style={{
        borderRadius: "15px", boxShadow: "4px 5px 5px 0px rgba(0,0,0,0.5)", height: "100%", width: isMobile ? "94%" : "90%",
        // boxShadow: "rgba(240, 46, 170, 0.4) 5px 5px, rgba(240, 46, 170, 0.3) 10px 10px, rgba(240, 46, 170, 0.2) 15px 15px, rgba(240, 46, 170, 0.1) 20px 20px, rgba(240, 46, 170, 0.05) 25px 25px"
        boxShadow: cardClicked ?
          'rgba(240, 46, 170, 0.4) 0px 0px 0px 5px, rgba(240, 46, 170, 0.3) 0px 0px 0px 10px, rgba(240, 46, 170, 0.2) 0px 0px 0px 15px, rgba(240, 46, 170, 0.1) 0px 0px 0px 20px, rgba(240, 46, 170, 0.05) 0px 0px 0px 25px'
          : 'none'
        ,
        // animation: 'blink 2s infinite alternate'


      }}>
        <Card.Body>
          <Card.Img
            src={datanew?.web_image}
            style={{ borderRadius: "15px" }}
          />
          {/* <div className="coursepricediv pt-3">
            <h3 className="ratinghead4">{priceplan2 ? `$${priceplan2}` : `$${priceplan2}`}</h3>

   
            <h4 className="ratinghead3">${priceplan2 + (priceplan2 * 0.4)}</h4>


            <p style={{ fontWeight: "500" }}>You will get 41 % off</p>
          </div> */}
          <div className="coursepricediv mt-2">
            {datanew?.discount_percentage &&
              datanew?.discount_percentage != 0 && (
                <h3 className="ratinghead4">
                  {priceplan2 ? `$${priceplan2}` : `$${priceplan2}`}
                </h3>
              )}
            {/* ${Math.floor((datanew?.price) - (((datanew?.discount_percentage) * parseInt(datanew?.price)) / 100))} */}
            {(!datanew?.discount_percentage ||
              datanew?.discount_percentage == 0) && (
                <h3 className="ratinghead4">${priceplan2}</h3>
              )}
            {datanew?.discount_percentage &&
              datanew?.discount_percentage != 0 && (
                <h4 className="ratinghead3">
                  $
                  {includeLiveClass && currentEvent
                    ? Number(datanew?.price) + Number(currentEvent.price)
                    : datanew?.price}
                </h4>
              )}
            {datanew?.discount_percentage &&
              datanew?.discount_percentage != 0 && (
                <p style={{ fontWeight: "500" }}>
                  You will get{" "}
                  {includeLiveClass && currentEvent
                    ? Math.floor(
                      ((Number(datanew.price) +
                        Number(currentEvent.price) -
                        priceplan2) /
                        (Number(datanew.price) +
                          Number(currentEvent.price))) *
                      100
                    )
                    : Math.floor(
                      ((datanew.price - priceplan2) / datanew.price) * 100
                    )}
                  % off
                </p>
              )}
          </div>
          <form onSubmit={(e) => e.preventDefault()}>
            <label htmlFor="couponinput" className="couponinputlabel">
              Do you have a coupon code ?
            </label>
            <InputGroup className="mb-2">
              <Form.Control
                placeholder="Type Code Here"
                aria-label="Username"
                aria-describedby="basic-addon1"
                id="couponinput"
                className="couponinputfield"
                value={coupon}
                onChange={(e) => setCoupon(e.target.value)}
              />
              <InputGroup.Text
                id="basic-addon1"
                className="applycouponbtn"
                onClick={handleApplyCoupon}
                ref={btnRef}
              >
                Apply
              </InputGroup.Text>
            </InputGroup>
            <div>
              <label htmlFor="emailinput" className="couponinputlabel">
                Your Email Id <span style={{ color: "red" }}>*</span>
              </label>
              <Form.Control
                placeholder="Type Here"
                aria-label="Username"
                aria-describedby="basic-addon1"
                id="emailinput"
                className="couponinputfield"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
              />
            </div>
          </form>
          <Form className="mt-2">
            <label htmlFor="emailinput" className="couponinputlabel mb-2">
              Your Preferred Payment Mode
            </label>

            <div className="formpaymentradio">
              <div className="">
                <Form.Check
                  type={"checkbox"}
                  id={`check-api-checkbox`}
                  style={{ marginBottom: "0px",display:"flex",alignItems:"baseline"}}
                >
                  <Form.Check.Input
                    type={"checkbox"}
                    isValid
                    checked={defaultPayment === "Stripe"}
                    onChange={() => setDefaultPayment("Stripe")}
                  />
                  <Form.Check.Label className="stripelabel">
                    Pay Via{" "}
                    <span style={{ marginLeft: "3px" }}>
                      <FaStripe color="#655fff" size={40} />
                    </span>
                  </Form.Check.Label>
                </Form.Check>
              </div>

              <div>
                <Form.Check type={"checkbox"} id={`paypal`}>
                  <Form.Check.Input
                    type={"checkbox"}
                    isValid
                    checked={defaultPayment === "Paypal"}
                    onChange={() => setDefaultPayment("Paypal")}
                  />
                  <Form.Check.Label className="paypalabel">
                    Pay Via{" "}
                    <span>
                      <Image
                        fluid
                        src={require("../../../images/paypal-logo-preview.png")}
                        style={{ width: "auto", height: "18px" }}
                      />
                    </span>
                  </Form.Check.Label>
                </Form.Check>
              </div>
            </div>
          </Form>
          <div className="secureserver mb-2">
            <span>
              <FaLock size={15} />
            </span>

            <p style={{ fontSize: "0.8rem" }}>
              For your security all orders are processed on a secure server
            </p>
          </div>
          {defaultPayment === "Paypal" && (
            <form
              id="form"
              action={process.env.REACT_APP_PAYPAL_URL}
              method="post"
              ref={formRef}
            >
              <input
                type="hidden"
                name="business"
                value={process.env.REACT_APP_BUSINESS_EMAIL}
              />
              <input type="hidden" name="cmd" value="_xclick" />
              <input
                type="hidden"
                name="item_name"
                value={"Combo with live interactive"}
              />
              <input
                type="hidden"
                name="item_number"
                value={"combo-with-live"}
              />
              <input type="hidden" name="amount" value={priceplan2} />
              <input type="hidden" name="currency_code" value="USD" />
              <input type="hidden" name="custom" value={paypalOrderID} />
              <input
                type="hidden"
                name="cancel_return"
                value={`${REACT_BASE_URL}subscription-failed?q=${btoa(
                  paypalOrderID
                )}&s=${btoa(studentId)}`}
              //  value={`http://localhost:3000/subscription-failed?q=${btoa(
              //   paypalOrderID
              // )}&s=${btoa(studentId)}`}
              />

              <input
                type="hidden"
                name="return"
                value={`${REACT_BASE_URL}subscription-success?q=${btoa(
                  paypalOrderID
                )}&s=${btoa(studentId)}&pg=${btoa("paypal")}`}
              // value={`http://localhost:3000/subscription-success?q=${btoa(
              //   paypalOrderID
              // )}&s=${btoa(studentId)}&pg=${btoa("paypal")}`}
              />

              <input
                type="hidden"
                name="notify_url"
                value={`${domainUrl}/paypal-notify`}
              />
              <input type="hidden" name="auto_return" value="all" />
              <input
                type="hidden"
                className="herodiv-becomememberbtn3"
                value={"Enroll Now"}
              />
            </form>
          )}
          <div className="d-grid gap-2 my-2 enrollbtn emptyhr">
            <Button
              variant="warning"
              size="sm"
              onClick={handleSubscription}
              className="enroll_btn"
              style={{
                height: "40px",
                fontWeight: "600",
                fontSize: "1rem",
              }}
              disabled={loading}
            >
              {loading ? (
                <Spinner animation="border" variant="dark" />
              ) : (
                "Enroll Now"
              )}
            </Button>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default ComboPaymentCard;
