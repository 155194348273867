import React, { useCallback, useContext, useEffect, useState } from "react";
import { Card, Form, Image, Spinner } from "react-bootstrap";
import StoriesCard from "./StoriesCard";
import { Pagination } from "react-pagination-bar";
import { AuthContext } from "../../context/AllContext";
import "./Stories.css";
import { requestData } from "../../utils/baseUrl";
import CommentsComp from "./CommentsComp";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
// import MemberShipPricingPlan from "../silvamembership/MemberShipPricingPlan";
import MemberShippricingPlan2 from "../silvamembership/MemberShippricingPlan2";
// import MemberShipPricingPlan from "../silvamembership/MemberShipPricingPlan";

function Stories({ newdata,allOff=false,id }) {
  // const {id} = useParams()
  const location = useLocation()
  const navigate = useNavigate();
  const [comment,setComment] = useState("");
  const [loadingCommentPost,setLoadingCommentPost] = useState(false)
  const { userData,isMobile } = useContext(AuthContext);

  const [comments, setComments] = useState([]);


  //   const posts = [
  //     { id: 1, title: "Post 1" },
  //     { id: 2, title: "Post 2" },
  //     { id: 3, title: "Post 3" },
  //     { id: 4, title: "Post 4" },
  //     { id: 5, title: "Post 5" },
  //     { id: 6, title: "Post 6" },
  //     { id: 7, title: "Post 7" },
  //     { id: 8, title: "Post 8" },
  //     { id: 9, title: "Post 9" },
  //     { id: 10, title: "Post 10" },
  //     { id: 11, title: "Post 11" },
  //     { id: 12, title: "Post 12" },
  //   ];



  const fetchCourseComments = useCallback(async()=>{
   
    const res = await requestData("courseCommentList", "POST", {
      course_id: newdata.course_id
    })


    if (res && res.error === false && res.data) {
      setComments(res.data.reverse())
    }else{
      setComments(res.data)
    }
  },[id,newdata,location])


  const handlePostComment = async()=>{
    if(!comment || !comment.trim()){
      toast.error("Please type a comment to submit !!")
      return
    }
    setLoadingCommentPost(true)
    const res = await requestData("CourseComment","POST",{
      course_id:newdata.course_id,
      comment:comment,
      name:(userData.first_name||"")+" "+(userData.last_name || ""),
      email:userData.email,
      student_id:userData.student_id
    })
    setLoadingCommentPost(false)


    if(res && res.error===false){
      toast.success(res.messages)
      setComment("")
      fetchCourseComments()
    }else{
      toast.error(res.messages)
    }
    // console.log(res)
    // console.log(userData,comment)
  }


  const handleCommentChange = useCallback((e)=>{
    setComment(e.target.value)
  },[comment])

  useEffect(() => {
    if (newdata) {
        fetchCourseComments()
    }
  }, [newdata,id,location])




  


  return (
    <>
     
      <div className="mt-4 commentsection">
        {
          userData && (
            // <div className="wrapper">
            <Card style={{width:"100%",borderRadius:"10px"}}>
              <Card.Body className="wrapper">
              <Image thumbnail className="wrapperimg" src={userData.profile_image} />
              <input type="text" placeholder="type a comment" onChange={handleCommentChange} value={comment} />
              <button onClick={handlePostComment}>
                {
                  loadingCommentPost ? (
                    <Spinner animation="border" variant="light" />
                  ):"Post"
                }
              </button>
              </Card.Body>
              </Card>
            // </div>
          )
        }

        {
          !userData && (
            // <div className="wrapper2">
            <Card style={{width:"100%",borderRadius:"10px"}}>
            <Card.Body className="d-flex justify-content-center align-items-center flex-column">
              <h4 className="mb-3">Log in or sign up to leave a comment</h4>
              <div>
              <button className="wrapperbtn" onClick={()=>navigate("/login")}>Login</button>
    
              <button className="wrapperbtn" style={{marginTop:isMobile?"10px":"0"}} onClick={()=>navigate("/login",{state:"link-2"})}>Signup</button>
              </div>
              </Card.Body>
              </Card>
            // {/* </div> */}
          )
        }

        <div>
          {
            comments && comments.length > 0 && (
              <CommentsComp comments={comments} fetchCourseComments={fetchCourseComments} />
            )
          }

          {
            ((!comments) || (comments && comments.length==0)) && (
              <div className="mt-4">
                <h5>No Comments Yet , Be the first to comment</h5>
              </div>
            )
          }
        </div>
      </div>
     {
      allOff===false && (
        <div className="mt-5">
        <MemberShippricingPlan2 white={true} />
        </div>
      )
     }
    </>
  );
}

export default Stories;
