import React, { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
// Import Swiper React components

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import "./styles.css";

// import required modules
import { AuthContext } from "../../context/AllContext";
import NewReviewsCard from "./NewReviewsCard";
import HomeHeading from "../../pages/Home/Home/HomeHeading";
import { Link } from "react-router-dom";

export default function Testimonials() {
  const { isDesktopOrLaptop, isTabletOrMobile } = useContext(AuthContext);

  const [reviews, setReviews] = useState([]);

  const settings = {
    className: "center",
    infinite: true,
    cssEase: "linear",
    centerMode: true,
    centerPadding: isDesktopOrLaptop ? "20%" : "10%",
    slidesToShow: isDesktopOrLaptop ? 1 : 1,
    speed: 500,
    prevArrow: <GrFormPrevious />,
    nextArrow: <GrFormNext />,
    dots: true,
    autoplay: true,
  };

  const getreviews = async () => {
    const myHeaders = new Headers();
    myHeaders.append("ApiKey", "40bb9d38c66e40a86678979286f4e2b5");
    myHeaders.append("Device", "Web");
    myHeaders.append("Language", "english");

    const formData = new FormData();
    formData.append("start_index", "0");
    formData.append("no_of_records", "10");
    const country = localStorage.getItem("countryname");
    const currency = localStorage.getItem("currency");

    if (country) {
      myHeaders.append("country", country.toString());
    }

    if (currency) {
      myHeaders.append("currency", currency.toString());
    }
    const options = {
      method: "POST",
      headers: myHeaders,
      body: formData,
    };
    const res = await fetch(
      `https://silvamethod.com/manage/api/testimonialList`,
      options
    );
    const data = await res.json();
    //console.log(data.data.classes);
    setReviews(data.data);
  };

  useEffect(() => {
    getreviews();
  }, []);

  // console.log(reviews);

  return (
    <div className={isTabletOrMobile ? "mt-0 mb-0" : "mt-20 mb-40"}>
      <div className="row justify-content-center">
        {/* <h2 className="text-center" style={{ fontWeight: "700", fontSize: isDesktopOrLaptop ? "60px" : "30px", marginBottom: "10px" }}>What Others Say about Us...</h2> */}
        {/* <HeadingNew title={"What Others Say about"} colored={"Silva Method"}/> */}
        {/* <h2
          style={{
            fontWeight: "800",
            fontSize: isBigScreen ? "60px" : isDesktopOrLaptop ? "50px" : isTablet ? "40px" : "30px",
            textAlign: "center"
          }}
        >
            What Others Say about<span style={{ color: "#801cb6", fontWeight: "800", fontFamily: "Poppins" }}> Silva Method</span>
        </h2> */}
        <HomeHeading
          title="What Others Say about"
          colored="Silva Method"
          title2=""
        />
        <h5 className="mt-3 mb-3 text-center">
          Over 12 million people in 110 countries have experienced what Silva
          Method Meditation can do.
        </h5>
        <div className="col-12 text-center bestslider">
          <Slider {...settings}>
            {reviews.map((val, i) => (
              <NewReviewsCard key={i} value={val} />
            ))}
          </Slider>

          {/* <Slider {...settings}>
        
        {reviews && reviews.map((review)=>
            <div key={review.id} className="d-flex flex-column flex-md-row justify-content-center align-items-center text-start border border-1 p-3 text-center">
              <img src={review.image}/>
              <div>
              <div
                dangerouslySetInnerHTML={{__html: review.comment}}
                  />
              <h5>{`${review.name}, ${review.designation}`}</h5>
              </div>
              
            </div>
        )}
        
        </Slider> */}
        </div>
      </div>
      <div className="text-center mt-3">
        <Link to={"/testimonials"}>
          <button className="styledbtn23">View all</button>
        </Link>
      </div>
    </div>
  );
}
