import React, { useContext, useEffect, useMemo, useState } from "react";
import { Col, Container, ListGroup, Nav, Row, Tab } from "react-bootstrap";
import "./schedule.css"
import HeadingComp from "../../pages/SilvaManifestationProgram/HeadingComp";
import HeadingNew from "../../pages/AboutUs/HeadingNew";
import { AuthContext } from "../../context/AllContext";
import { useParams } from "react-router-dom";
import ScheduleTable from "./ScheduleTable";
function Schedule() {
  const [activeTab, setActiveTab] = useState(0);


  const [Chapter, setChapter] = useState(0)

  const { isMobile, isTablet } = useContext(AuthContext);
  const { id } = useParams()
  const coursedataChapters = useMemo(() => {
    return {
      "Silva-Mind-Body-Healing": [
        {
          title: "Chapter 1 – The Power of Holistic Healing",
          days: [
            { 1: "Holo Viewing and Strong Attracter Exercise" },
            { 2: "Managing Stress and Its Strategies" },
            { 3: "Relaxation Exercise" },
            { 4: "Removing Blockages, and Repairing Belief Systems" },
            { 5: "Changing Limiting Beliefs Exercise" },
            { 6: "Changing Limiting Beliefs Exercise" },
            { 7: "The Power of Forgiveness" }
          ]
        },
        {
          title: "Chapter 2 – The Healing of Truth and Perception",
          days: [
            { 8: "Secrets on Truth and Perception" },
            { 9: "Law of Healing Exercise" },
            { 10: "The Effect of Your Emotional Body" },
            { 11: "Awareness Exercise" },
            { 12: "Lessons on Your Mental Body and Guided Imagery" },
            { 13: "The Power of The Three Scenes Technique" },
            { 14: "Three Scenes Exercise" }
          ]
        },
        {
          title: "Chapter 3 – The Positivity of your Mental Body",
          days: [
            { 14: "The Secrets on Meditation and Mental Body" },
            { 15: "Mental Body Exercise" },
            { 16: "The Secrets to a Healthy and Happy Life" },
            { 17: "Dietary Revelations with Energizing Water" },
            { 18: "Energizing Water Exercise" },
            { 19: "Source Energy and You" },
            { 20: "You and Your Healthy Life" }
          ]
        },
      ],
      "Silva-Method-Manifesting-System": [
        {
          title: "Chapter 1 – An Ideal Manifestation of a Good Life",
          days: [
            { 1: "Your State of Mind" },
            { 2: "State of Mind and Open Eyes Meditation" },
            { 3: "The Process of Actualization Exercise" },
            { 4: "Life Purpose and Explanation" },
            { 5: "Life Purpose Exercise" },
            { 6: "Control Your Attention" },
            { 7: "Monkey Mind Exercise" }
          ]
        },
        {
          title: "Chapter 2 – Engaging Your Idea Life",
          days: [
            { 8: "Your Entire Being" },
            { 9: "Engaging Fully Exercise" },
            { 10: "The Law of Allowance" },
            { 11: "Distance Exercise" },
            { 12: "Law of Corresponding and The Outcome of Sabotaging" },
            { 13: "The Help of Refining Your Thoughts" },
            { 14: "Target Image of Your Thoughts Exercise" }
          ]
        },
        {
          title: "Chapter 3 – A Greatness Life",
          days: [
            { 15: "Important Choice Points" },
            { 16: "Choice Points Exercise" },
            { 17: "Believe-Achieve-Desire" },
            { 18: "The Cause and Feedback of Solutions" },
            { 19: "The Power of Synchronicities" },
            { 20: "Integrating Greatness Exercise" },
            { 21: "Your Body and Mind" }
          ]
        },
        {
          title: "Chapter 4 – A Grateful Life",
          days: [
            { 22: "Gratitude and Appreciation" },
            { 23: "Gratitude Exercise" },
            { 24: "Paying it Forward" },
            { 25: "Controlling Your Monkey Magnet" },
            { 26: "Money Magnet Exercise" },
            { 27: "Centering Exercise" },
            { 28: "Three Finger Technique with Mirror of the Mind" }
          ]

        }
      ],
      "Silva-Life-System": [
        {
          title: "Chapter 1 – Core Values and Steps to The Silva Life System",
          days: [
            { 1: "Think Positive with the Power of Desire and Belief" },
            { 2: "Manage Stress Effectively - Handling the Situation" },
            { 3: "Transform Stress Now Exercise" },
            { 4: "Crafting Your Dreams and Outcome Exercise" },
            { 5: "Go Beyond and Changing Your Limiting Beliefs Exercise" },
            { 6: "Your Brain and Mind" },
            { 7: "The Long Relax Exercise" }
          ]
        },
        {
          title: "Chapter 2 - Mind Body Management Beginner, Intermediate, Advance",
          days: [
            { 8: "Steps on Sleep Control and the Exercise" },
            { 9: "Step used For the Alarm Clock Technique and the Exercise" },
            { 10: "How to use Dream Control and the Exercise" },
            { 11: "How to use Dream Control and the Exercise" },
            { 12: "5 steps to Energizing Yourself and Exercise" },
            { 13: "4 Steps to the Three-Fingers" },
            { 14: "Three-Fingers Exercise" }
          ]
        },
        {
          title: "Chapter 3 – Healing & Problem Solving Beginner, Intermediate, Advance",
          days: [
            { 15: "4 Steps to The Glass of Water and Exercise" },
            { 16: "5 Steps Headache Relief and Exercise" },
            { 17: "7 Steps to The Mirror of the Mind and Exercise Part 1" },
            { 18: "8 Steps to The Mirror of the Mind and Exercise Part 2" },
            { 19: "Advanced Dream Control with The Three Scenes and Exercise" },
            { 20: "Steps to The Power of Forgiveness and Healing the Past" },
            { 21: "Healing the Past Exercise" }
          ]
        },
        {
          title: "Chapter 4 – Activating Intuition Beginner, Intermediate, Advanced",
          days: [
            { 22: "7 Steps to Mental Attunement and Exercise" },
            { 23: "Fantastic Voyage & Tips Learning Points of Reference and Exercise " },
            { 24: "6 Steps to the Creation of Mental Laboratory and Exercise" },
            { 25: "The Creation of Your Imaginary Counselors" },
            { 26: "Getting to Know Your Counselors Exercise" },
            { 27: "Meditate with your Counselors" },
            { 28: "Meditate with Your Counselors" }
          ]
        },
        {
          title: "Chapter 5 – Manifesting Intermediate, Advanced",
          days: [
            { 29: "3 Steps to Creating New Beliefs" },
            { 30: "Creating New Beliefs Exercise" },
            { 31: "6 Steps to The Mirror of the Mind Advanced" },
            { 32: "Mirror of the Mind Advanced Exercise" },
            { 33: "Practice What You Have Learned" },
            { 34: "Practice 3 times a Day" },
            { 35: "Keep Practicing Daily What You Have Learned" }
          ]
        }
      ],
      "Silva-Intuition-System": [
        {
          title: "Chapter 1 - The Importance of The Silva Intuition System and You",
          days: [
            { 1: "The Role Your Intuition Plays" },
            { 2: "The Important Statement and Affirmation that Develops Your ESP" },
            { 3: "Getting to know your Subjective Mind, Intuition, and Education" },
            { 4: "The Long Relax Exercise" },
            { 5: "The Doorway to Your Source of Energy and Delta and Exercise" },
            { 6: "Your Internal and External Experiences" },
            { 7: "Droplet of Source Energy Exercise" }
          ]
        },
        {
          title: "Chapter 2 – A Purposeful Life",
          days: [
            { 8: "The Importance of a Driven Life, Finding Yourself and Life Purpose" },
            { 9: "Connecting Your Life and Exercise" },
            { 10: "Connecting to Life Purpose and Exercise" },
            { 11: "Principles in Mind while programming" },
            { 12: "Mental Screen, Clarity, Focus, and Control" },
            { 13: "The Importance of Desire, Belief, and Expectancy" },
            { 14: "Holo-Viewing and Exercise" }
          ]
        },
        {
          title: "Chapter 3 – Plant, Animal, and Human Exercise",
          days: [
            { 15: "Holo-Viewing a Night-Time Exercise" },
            { 16: "Points of Reference and Subjective Dimension" },
            { 17: "Projection – Inanimate Objects Exercise" },
            { 18: "Projection – Plant and Animal Exercise" },
            { 19: "Ken Coscia on Distant Healing " },
            { 20: "Scanning Human Body Exercise" },
            { 21: "Case Working Guidelines" }
          ]
        },
        {
          title: "Chapter 4 – Healing Human, Plant, and Animal",
          days: [
            { 22: "Case Working Exercise" },
            { 23: "Advanced Case Working Human, Plant, and Animal" },
            { 24: "The Seeds of Purpose Exercise - Bonus" },
            { 25: "Holo-Viewing Daytime Exercise - Bonus" },
          ]
        }
      ],
      "Silva-Love-System":[
        {
          title:"Chapter 1 – The Feelings of Love",
          days:[
            {1:"The Benefits of love"},
            {2:"The Secrets to Loving"},
            {3:"What is Love"},
            {4:"Dealing with Thoughts and Feelings"},
            {5:"The Ego and You"},
            {6:"Loving Relationships"},
            {7:"Dealing with Feelings"}
          ]
        },
        {
          title:"Chapter 2 – Appreciate Yourself",
          days:[
            {8:"Doing away with Blocks"},
            {9:"No one is Perfect"},
            {10:"Judging and Condemning"},
            {11:"Feeling Guilt"},
            {12:"Stop Repressing Feelings"},
            {13:"Believe in Your Beliefs"},
            {14:"Stop Comparing Love"}
          ]
        },
        {
          title:"Chapter 3 – Appreciate Yourself Part Two",
          days:[
            {15:"Competition and Growth"},
            {16:"Lack of Self-Love"},
            {17:"Dishonesty Can Damage Love"},
            {18:"Forgiving Yourself"},
            {19:"Keep in Mind"},
            {20:"Your Higher Self"},
            {21:"Understanding the Outer Signs"}
          ]
        },
        {
          title:"Chapter 4 – The Power of Giving and Receiving",
          days:[
            {22:"Your Power of Your Beliefs"},
            {23:"Independence and You"},
            {24:"Obligation and Your Fears"},
            {25:"Experiences of the Unknown"},
            {26:"Suffering and You"},
            {27:"Fears and What Comes with It"},
            {28:"Receiving Gratitude and Love"}
          ]
        },
        {
          title:"Chapter 5 - The Power of Giving, Receiving and the 5-Steps to Love",
          days:[
            {29:"Worth, Deserving, and Self-Forgiving"},
            {30:"Receiving, Vision, and Beliefs"},
            {31:"Firm Foundations"},
            {32:"You start with the Power of Acceptance"},
            {33:"Forgiveness is a Must"},
            {34:"Appreciate Yourself So Gratitude Flows Freely"},
            {35:"Doorway to Love"}
          ]
        },
        {
          title:"Chapter 6 – What is Love and the benefits that Comes with It",
          days:[
            {36:"What is Love"},
            {37:"Your EGO"},
            {38:"Three Components of Love"},
            {39:"Affection is a Must to Change"},
            {40:"Kindness and Respect"},
            {41:"I Am My Best Friend and Cheerleader"},
            {42:"Love, Forgive, and Love What I See"}
          ]
        },
        {
          title:"Chapter 7 – 7 Days to Exercising Your Affection",
          days:[
            {43:"Greet Everyone"},
            {44:"Compliments"},
            {45:"Share Affection to Everyone"},
            {46:"It’s a Listening Day"},
            {47:"Tell Someone You Love Them and Why"},
            {48:"Be Spontaneous"},
            {49:"Time to Laugh with Those You Love"}
          ]
        },
        {
          title:"Chapter 8 – Journey to Love and Mirror of Love Meditations",
          days:[
            {50:"Listen to the Meditation"},
            {51:"Learn the Meditation"},
            {52:"Meditate for Your Goal"},
            {53:"Be aware of Your Surroundings for Clues"},
            {54:"The Mirror of Love Meditation"},
            {55:"Listen to the Meditation"},
            {56:"Learn the Meditation"}
          ]
        },
        {
          title:"Chapter 9 – Mirror of Love and Attracting Your Soul Mate Meditations",
          days:[
            {57:"Meditate for Your Goal"},
            {58:"Be aware of Your Surroundings for Clues"},
            {59:"Attracting Your Soul Mate Meditation"},
            {60:"Listen to the Meditation"},
            {61:"Learn the Meditation"},
            {62:"Meditate for Your Goal"},
            {63:"Be aware of Your Surroundings for Clues"}
          ]
        }
      ]
    }
  }, [])
  const [data, setData] = useState()

  useEffect(() => {
    if (id) {
      if (id === "silva-mind-body-healing-program") {
        setData(coursedataChapters["Silva-Mind-Body-Healing"])
      } else if (id === "silva-method-manifesting-program") {
        setData(coursedataChapters["Silva-Method-Manifesting-System"])
      } else if (id === "silva-intuition-system-digital-audio-program") {
        setData(coursedataChapters["Silva-Intuition-System"])
      } else if (id === "silva-life-system-digital-audio") {
        setData(coursedataChapters["Silva-Life-System"])
      }else if(id==="love-program" || id==="silva-love-program"){
        setData(coursedataChapters["Silva-Love-System"])
      }
    }


  }, [id])




  if (!data) {
    return null
  }
  return (
    <div className={(!isMobile && !isTablet) ? "container mt-3" : "mt-3"}>
      {/* <HeadingNew title={"Day by Day Schedule"} small /> */}
      {/* <h2 className="text-center" style={{fontWeight:"600",color:"#000"}}>Day by Day Schedule</h2> */}
      <HeadingNew title={"Course Curriculum"} small />

      {
        (isMobile || isTablet) && (
          <div className="mt-4 bg-light scrollable-container" style={{ overflowX: 'scroll' }}>

            <div className="d-flex gap-5" style={{ width: "600px", padding: "15px" }} >
              {
                data.map((val, i) => (
                  <div onClick={() => setChapter(i)}>
                    <h5 className="text-start" style={{ fontWeight: "600", color: Chapter === i ? "#9b37f2" : "#000",width:"100px" }}>
                      Chapter {i + 1}
                    </h5>
                    {
                      Chapter === i && (
                        <div style={{ width: "100%", height: "2px", backgroundColor: "#9b37f2" }}>

                        </div>
                      )
                    }
                  </div>
                ))

              }
            </div>


          </div>
        )
      }


      {
        (isMobile || isTablet) && (
          <div className="container mt-4">
            <HeadingNew title={data[Chapter].title} small start />


            <ListGroup className="mt-3" style={{borderRadius:"8px"}}>
              {
                data[Chapter].days.map((val,i)=>(
                  <ListGroup.Item className="bottomlist py-3">
                    <div className="d-flex justify-content-center align-items-start flex-column">
                      <h6 style={{color:"#7a12d4",fontWeight:"700",letterSpacing:"0px",fontSize:"1rem"}}>
                        Part {Object.keys(val)[0]}
                      </h6>
                      <h6 style={{color:"#000"}}>
                        {
                          Object.values(val)[0]
                        }
                      </h6>
                    </div>

                  </ListGroup.Item>
                ))
              }
             
              
            </ListGroup>
          </div>
        )
      }

      {
        (!isMobile && !isTablet) && (
          <div>
            {
              data.map((val, i) => (
                <div className="mt-5">
                  {/* <HeadingNew title={val.title} small start /> */}
                  <h4 className="text-start" style={{ fontWeight: "600", color: "#000" }}>
                    {val.title}
                  </h4>
                  <ScheduleTable data={val} />
                </div>
              ))

            }
          </div>
        )
      }

    </div>
  );
}

export default Schedule;
