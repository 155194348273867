import React, { useEffect, useState } from 'react'
import { requestData } from '../../utils/baseUrl';
import SuggestClassCard from './SuggestClassCard';
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';

function SuggestedClasses({ id, handleShow }) {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1500px)' });
    const isMedium = useMediaQuery({ minWidth: 1280, maxWidth: 1499 })

    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1279 })

    const isLargeMob = useMediaQuery({ minWidth: 600, maxWidth: 767 })
    const isMobile = useMediaQuery({ minWidth: 320, maxWidth: 599 })
    const [suggestedClasses, setSuggestedClasses] = useState([]);
    const settings = {
        className: "center",
        centerMode: false,
        infinite: true,
        // centerPadding: isDesktopOrLaptop ? "100px" : isTablet ? "35px" : isLargeMob ? "45px" : "45px",
        slidesToShow: isDesktopOrLaptop ? 4 : isMedium ? 3 : isTablet ? 3 : isLargeMob ? 2 : 1,
        speed: 500,
        rows: 1,
        slidesPerRow: 1,
        prevArrow: <GrFormPrevious />,
        nextArrow: <GrFormNext />,
        dots: false,
        // autoplay: true,
        slidesToScroll:1
    };
    async function fetchClasses() {
        const res = await requestData("latestClassListbyStartDate", "POST", { start_index: 0, no_of_records: 11 });
        if (res && res.error === false) {
            const newdata = res.data.filter((val) => val.course_id !== id)
            setSuggestedClasses(newdata)
        }
    }

    useEffect(() => {
        fetchClasses()
    }, []);

    // console.log(suggestedClasses);

    return (
        <div className='row justify-content-center align-items-center eventslider'>
            <h3 className='mt-4' style={{ fontWeight: "800", fontSize: isDesktopOrLaptop ? "25px" : "14px", }}>
               Other events you may like
            </h3>
            <Slider {...settings}>
                {
                    suggestedClasses.map((val, i) => (
                        <div className="col-sm-10 col-md-6 col-lg-4" key={i}>
                            <SuggestClassCard val={val} handleShow={handleShow} />
                        </div>
                    ))
                }
            </Slider>
        </div>
    )
}

export default SuggestedClasses