import React, { useContext, useEffect, useState } from 'react'
import SilvaMembershipNavbar from '../silvamembership/SilvaMembershipNavbar'
import CourseHeroSection from './CourseHeroSection'
import { Card, Col, Image, Row } from 'react-bootstrap'
import PaymentCard from './PaymentCard'
import CourseFeaturesSection from './CourseFeaturesSection'
import CourseAccodions from './CourseAccodions'
import { AuthContext } from '../../context/AllContext'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Stories from '../SilvaManifestationProgram/Stories'
import { useRef } from 'react'
import LoadingSpinner from '../LoadingSpinner'
import { reqData, requestData4 } from '../../utils/baseUrl'
import AllCourseAccordions from './AllCourseAccordions'
import { useCallback } from 'react'
import LastSectionMedi2 from '../AdvertisePages/meditationJourney/LastSectionMedi2'
import Schedule from '../../components/Shared/Schedule'
import SilvaLifeFAQ from '../SilvaManifestationProgram/SilvaLifeFAQ'
import HeadingNew from '../AboutUs/HeadingNew'
import ReviewSlider2 from '../../components/DifferenceSection/ReviewSlider2'
import ContactSection from './ContactSection'
import ReviewSlider3 from '../../components/DifferenceSection/ReviewSlider3'
import ReviewsContainer from '../../components/DifferenceSection/ReviewsContainer'
import RefundBanner from './RefundBanner'
import UnstressToolBoxVideo from './UnstressToolBoxVideo'


function CoursePage() {
    const navigate = useNavigate();

    const location = useLocation()
    const [lastCourse, setLastCourse] = useState()
    const [allCourseData, setAllCouurseData] = useState()

    const [courseLevel, setCourseLevel] = useState()

    const [isCurrentCoursePurchased, setIsCurrentCoursePurchased] = useState(false);
    const [loading, setLoading] = useState(false);

    const { isMobile, isTablet, userData } = useContext(AuthContext)
    const st_id = localStorage.getItem("token")
    const courseDescRef = useRef(null)

    const { id } = useParams();
    // console.log(id,'id');
    const [error, setError] = useState(false);
    const [course, setCourse] = useState([]);
    const [courseStatus,setCourseStatus] = useState({loading:false,completed:0,notCompleted:0});
    const [allCourseIds,setAllCourseIds] = useState([]);


    const getCourses = useCallback(async (udata) => {
        const myHeaders = new Headers();
        myHeaders.append("ApiKey", "40bb9d38c66e40a86678979286f4e2b5");
        myHeaders.append("Device", "Web");
        myHeaders.append("Language", "english");

        if (st_id) {
            myHeaders.append("studentid", st_id)
        }


        const formData = new FormData();
        formData.append("course_id", id === "silva-love-program" ? "love-program" : id);

        const options = {
            method: "POST",
            headers: myHeaders,
            body: formData,
        };

        setLoading(true)
        const res = await fetch(
            `${process.env.REACT_APP_API_URL}courseDetail`,
            options
        );
        setLoading(false)

        const data = await res.json();



        if ((!data || data.error === true)) {
            setError(true);
            return
        }


        setCourse(data.data);


        const currCourseId = data.data[0].course_id


        const userenrolledcourses = udata ? udata.enrolled_course.map((val) => val.course_id) : []

        if (udata && udata.enrollment_status === "Enrolled" && (userenrolledcourses.includes(currCourseId)
            || userenrolledcourses.length === Number(userData.total_course))) {
            setIsCurrentCoursePurchased(true)
        } else {
            setIsCurrentCoursePurchased(false)

        }

        if (data.data[0].course_description) {
            courseDescRef.current.innerHTML = data.data[0].course_description
        }
    }, [id]);

    const getCourseStatus= async()=>{
      setCourseStatus({...courseStatus,loading:true});
      const res = await requestData4("courseListWithChild", "POST", {}, userData?.student_id);
      // console.log(res,'courseListWithChild');
      const currentCourse= res?.data?.find((course)=>course?.course_id===id);
      let allLessonsInCurrentCourse = [];
      currentCourse.chapters.forEach((chapter, i) => {
        const lessionsInSingleChapter = chapter.lession;
        allLessonsInCurrentCourse = [...allLessonsInCurrentCourse, ...lessionsInSingleChapter]
        });
                let numofcompleted = 0;
                let hasStarted = false;
                let isCourseCompleted = true
                allLessonsInCurrentCourse.forEach((ls, i) => {
                  // console.log(ls,"lslsls",course.course_title)
                  if (
                    !ls.lesson_activity_status ||
                    ls.lesson_activity_status === "Started"
                  ) {
                    isCourseCompleted = false;
                  }
                  if (ls.lesson_activity_status) {
                    hasStarted = true;
                  }
                  if (
                    ls.lesson_activity_status === "Completed" &&
                    ls.lesson_activity_duration
                  ) {
                    // console.log("started")
                    hasStarted = true;
                    ++numofcompleted;
                  }
                });
                setCourseStatus({...courseStatus,completed:numofcompleted,loading:false});
    }

    // console.log(courseStatus,'course.')


    useEffect(() => {

        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
        if (userData) {
            getCourses(userData);
            getCourseStatus();
        } else {
            getCourses();
        }
    }, [userData, id]);



    useEffect(() => {
        if (location.pathname.includes("combo-plan")) {
            getAllCourses()
        }
    }, [id])


    useEffect(() => {
        getCourseLevel(id)
    }, [id])


    function getCourseLevel(course_id) {
        if (course_id === "silva-life-system-digital-audio") {
            setCourseLevel("Beginner")
        } else if (course_id === "silva-mind-body-healing-program" || course_id === "silva-method-manifesting-program") {
            setCourseLevel("Advanced")
        } else if (course_id === "silva-intuition-system-digital-audio-program") {
            setCourseLevel("Intermediate")
        } else if (course_id === "combo-plan") {
            setCourseLevel("Beginner to Advanced")
        } else {
            setCourseLevel("Beginner")
        }
    }

    const getAllCourses = async () => {
        setLoading(true)
        const res = await reqData("latestCourseList", "POST", {
            start_index: 0,
            no_of_records: 20
        })



        if (res && res.error === false) {
            const allc = res.data.filter((val) => val.course_id !== "combo-plan" && val.course_id !== "silva-method-complete-course-with-live-interactive-program" && val.course_id!=="unstress-toolbox").map((val) => val.course_id);
            setAllCourseIds(allc)
            const promisesArr = [];

            allc.forEach((cid) => {
                promisesArr.push(reqData("courseDetail", "POST", {
                    course_id: cid
                }))
            })


            const result = await Promise.all(promisesArr)
            setLoading(false)

            const finalResult = result.map((val) => {
                if (val.error === false) {
                    return val.data
                }
            }).sort((a, b) => a[0].precedence - b[0].precedence)



            setAllCouurseData(finalResult)


        }
    }


    const lastViewedCourse = async () => {
        const res = await reqData("getLastVisitedLesson", "POST", {});
        if (res && res.error === false) {
            setLastCourse(res.data)
        }
    }


    useEffect(() => {
        if (isCurrentCoursePurchased) {
            lastViewedCourse()
        }
    }, [isCurrentCoursePurchased]);

    useEffect(()=>{
      const getAllC= async()=>{
          try {
              const res= await reqData("latestCourseList","POST",{
                  start_index: 0,
                  no_of_records: 20
              });
              if(res && res.error === false){
                  const filterArr= res?.data?.filter((val,i)=> val.course_id !== id);

                  const sortedCourses = filterArr.sort((a, b) => {
                      const isComboA = a.course_id.toLowerCase().includes('combo');
                      const isComboB = b.course_id.toLowerCase().includes('combo');
                      if (isComboA && !isComboB) return -1;
                      if (!isComboA && isComboB) return 1;
                      return Number(a.precedence) - Number(b.precedence);
                    });
                    const ids= sortedCourses?.map((val,i)=>{return {courseid:val.course_id,title:val?.course_title}})
                  setAllCourseIds(ids);
              }
          } catch (error) {
              console.log(error,'error')
          }
      }
      getAllC()
  },[id])



    const handleNavigate = () => {
        navigate("/silva-life-system-with-dragan")
    }



    if (error) {
        <div className='d-flex justify-content-center align-items-center'>
            <h2>An Error Occured ,Please try again later</h2>
        </div>
    }



    if (loading) {
        return <LoadingSpinner />
    };


    // console.log(course,'course')

    return (
      <div className="singleherocourse">
        <SilvaMembershipNavbar course={course} getCourses={getCourses} courseIds={allCourseIds}/>
       <div className='mt-50'>
       {
          course[0]?.course_id==="unstress-toolbox" &&
          <UnstressToolBoxVideo/>
        }
       </div>
        {course && course.length > 0 && (
          <CourseHeroSection
            courseLevel={courseLevel}
            lastCourse={lastCourse}
            data={course?.length > 0 && course[0]}
            isCurrentCoursePurchased={isCurrentCoursePurchased}
            courseStatus={courseStatus}
          />
        )}

        {course && course.length > 0 && (
          <div className="container">
            {isCurrentCoursePurchased === false ? (
              <Row>
                <Col xs={12} md={12} lg={7}>
                  <div
                    style={{
                      marginLeft: isMobile ? "10px" : "0px",
                      marginRight: isMobile ? "10px" : "0px",
                    }}
                  >
                    <CourseFeaturesSection
                      datanew={course?.length > 0 && course[0]}
                      isCurrentCoursePurchased={isCurrentCoursePurchased}
                    />
                    {course[0] && course[0].course_id !== "combo-plan" && (
                      <CourseAccodions
                        lastCourse={lastCourse}
                        data={course?.length > 0 && course[0]}
                        isCurrentCoursePurchased={isCurrentCoursePurchased}
                      />
                    )}

                    {course[0] &&
                      course[0].course_id === "combo-plan" &&
                      allCourseData &&
                      allCourseData.map((val, i) => (
                        <AllCourseAccordions
                          data={val?.length > 0 && val[0]}
                          isCurrentCoursePurchased={isCurrentCoursePurchased}
                          lastcorused={i}
                        />
                      ))}
                  </div>
                </Col>

                {!isCurrentCoursePurchased && !isMobile && !isTablet && (
                  <Col md={5} lg={5}>
                    <PaymentCard
                      datanew={course[0]}
                      isCurrentCoursePurchased={isCurrentCoursePurchased}
                    />
                  </Col>
                )}
              </Row>
            ) : (
              <Row className="justify-content-center align-items-center">
                <Col xs={12} md={12} lg={12}>
                  <div
                    style={{
                      marginLeft: isMobile ? "10px" : "0px",
                      marginRight: isMobile ? "10px" : "0px",
                    }}
                  >
                    {course[0] && course[0].course_id !== "combo-plan" && (
                      <CourseAccodions
                        lastCourse={lastCourse}
                        data={course?.length > 0 && course[0]}
                        isCurrentCoursePurchased={isCurrentCoursePurchased}
                      />
                    )}
                    {course[0] &&
                      course[0].course_id === "combo-plan" &&
                      allCourseData &&
                      allCourseData.map((val) => (
                        <AllCourseAccordions
                          data={val?.length > 0 && val[0]}
                          isCurrentCoursePurchased={isCurrentCoursePurchased}
                        />
                      ))}
                  </div>
                </Col>
              </Row>
            )}
          </div>
        )}

        <div>
          <Schedule />
        </div>
      

        {course && course.length > 0 && (
          <div className="container mb-5">
            {course && course?.length > 0 && course[0].course_description && (
              <Card
                style={{ width: "100%", borderRadius: "15px", padding: "20px" }}
                className="coursedescriptioncard"
              >
                <Card.Body style={{ maxWidth: "100%" }}>
                  <h5 className="text-dark">Description :</h5>
                  <div
                    className="course_desc"
                    ref={courseDescRef}
                    style={{ color: "black", overflow: "none" }}
                  ></div>
                </Card.Body>
              </Card>
            )}
            {/* {
                            course && course[0] && course[0].course_id !== "combo-plan" && (
                                <div className='container d-flex justify-content-center align-items-center my-4'>
                                    {
                                        isMobile ? (
                                            <Image src='/img/dragan/draganad2.webp' style={{
                                                cursor: "pointer",
                                            }}
                                                onClick={handleNavigate}
                                            />
                                        ) : (
                                            <Image src='/img/dragan/draganad.webp' style={{
                                                cursor: "pointer",
                                            }}
                                                onClick={handleNavigate}
                                            />
                                        )
                                    }
                                </div>
                            )
                        } */}

            {/* <div className="container mt-4">
              <RefundBanner />
            </div> */}

            {course && course?.length > 0 && (
              <Stories newdata={course[0]} allOff id={id} />
            )}
          </div>
        )}

        <LastSectionMedi2 />

        {!isCurrentCoursePurchased && (
          <div className="container my-4">
            <HeadingNew title={"Frequently Asked Questions"} small start />
            <SilvaLifeFAQ />
          </div>
        )}

        {!isCurrentCoursePurchased && <ReviewsContainer />}

        <ContactSection />

        <footer
          className="py-3"
          style={{ backgroundColor: "#f4f5f9", width: "100%", height: "100%" }}
        >
          <p className="text-center" style={{ fontSize: "16px" }}>
            Copyright &copy; silvamethod.com
          </p>
        </footer>
      </div>
    );
}

export default CoursePage