import React, { forwardRef, useContext } from 'react';
import { Card, Col, Image, Row } from 'react-bootstrap';
import { AuthContext } from '../../../context/AllContext';
import { Fade } from 'react-awesome-reveal';
import ComboPaymentCard from './ComboPaymentCard';
import "./combo.css";
import { handleScrollCombo, handleScrollCombo2 } from '../../../utils/handleScrollEmotions';

const ComboHero2 = forwardRef(({ data, isCurrentCoursePurchased, alterbackground = false, onClick1, onClick2 }, ref) => {
  const { isDesktopOrLaptop, isMobile } = useContext(AuthContext);
  const handleScroll = () => {
    const doc = document.querySelector(".curriculumsection");
    console.log(doc,"docss")
    if (doc) {
        doc.scrollIntoView({
            behavior: "smooth",
            block: "start", // Align the top of the element with the top of the viewport
            inline: "nearest", // Scroll until the element is in view
        });
    }
  }
  
  const handleScroll2 = () => {
    const doc = document.querySelector(".classsection")
    if (doc) {
        doc.scrollIntoView({
            behavior: "smooth",
            block: "start", // Align the top of the element with the top of the viewport
            inline: "nearest", // Scroll until the element is in view
        });
    }
  }
  
  
  return (
    <div ref={ref} className={`scrollsection scrollsection2 ${alterbackground ? 'bgnewcombobenefits' : 'combo_main_hero_bg2'}`} style={{ paddingTop: alterbackground ? '0px' : isDesktopOrLaptop ? '100px' : '60px', paddingBottom: alterbackground ? '0px' : '60px' }}>
      <div className="container">
        <div className="mb-40">
          <Fade duration={700}>
            <h2 style={{
              color: 'white',
              textAlign: isMobile ? 'start' : 'center',
              fontWeight: '700',
              lineHeight: isMobile ? '1.5rem' : '2.5rem',
              fontSize: isMobile ? '1.3rem' : '2rem'
            }} className="mx-3">
              <span style={{ color: '#FFD23F', display: 'block' }}>
                The Silva Method
              </span>
              Complete Course (Recorded)
              <br />
              with Live Interactive Program
            </h2>
          </Fade>
        </div>
        <Row>
          {!isDesktopOrLaptop && <Col sm={12} md={12} lg={1}></Col>}
          <Col sm={12} md={12} lg={isDesktopOrLaptop ? 6 : 5} className="d-flex justify-content-center align-items-center">
            <div className="container">
              <Fade duration={700}>
                <Card className={`w-100 mb-3 py-${isDesktopOrLaptop ? '2' : ''}`} style={{
                  borderRadius: '15px',
                  boxShadow: '4px 5px 5px 0px rgba(0,0,0,0.5)'
                }}>
                  <Row>
                    <Col sm={12} md={6} lg={6} className="d-flex justify-content-center align-items-center">
                      <div className="p-3 ps-4">
                        <Image src="/img/live_interaction_image.png" style={{ borderRadius: '15px' }} />
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={6} className="d-flex justify-content-center align-items-center">
                      <div className="container">
                        <h5 className="text-black fw-bolder h5">{"Live Interactive Program"}</h5>
                        <p style={{ lineHeight: '1.2rem' }}>
                          Enjoy the live experience of learning in a transformative setting with cohorts
                        </p>
                        <button className={`herodiv-becomememberbtn mb-3`} onClick={handleScrollCombo2}>
                          Learn More
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Fade>
              {!isCurrentCoursePurchased && 
                <Fade duration={700}>
                  <Card className="w-100" style={{ borderRadius: '15px', boxShadow: '4px 5px 5px 0px rgba(0,0,0,0.5)' }}>
                    <Row>
                      <Col sm={12} md={6} lg={6} className="d-flex justify-content-center align-items-center">
                        <div className="p-3 ps-4">
                          <Image src="/img/combo_course_image.png" style={{ borderRadius: '15px' }} />
                        </div>
                      </Col>
                      <Col sm={12} md={6} lg={6} className="d-flex justify-content-center align-items-center">
                        <div className="container">
                          <h5 className="text-black fw-bolder h5">{"Silva Method Complete Program"}</h5>
                          <p style={{ lineHeight: '1.2rem' }}>
                            Get access to complete Silva Method Digital course for a comprehensive learning
                          </p>
                          <button className={`herodiv-becomememberbtn mb-3`} onClick={handleScrollCombo}>
                            Learn More
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Fade>
              }
            </div>
          </Col>
          <Col sm={12} md={12} lg={isDesktopOrLaptop ? 6 : 5}>
            {!isCurrentCoursePurchased && 
              <Fade duration={700}>
                <ComboPaymentCard datanew={data[0]} />
              </Fade>
            }
            {isCurrentCoursePurchased && 
              <div className={isDesktopOrLaptop ? '' : 'container'}>
                <Fade duration={700}>
                  <Card className={`w-100 py-${isDesktopOrLaptop ? '2' : ''}`} style={{ borderRadius: '15px', boxShadow: '4px 5px 5px 0px rgba(0,0,0,0.5)' }}>
                    <Row>
                      <Col sm={12} md={6} lg={6} className="d-flex justify-content-center align-items-center">
                        <div className="p-3 ps-4">
                          <Image src="/img/silva_method_image.png" style={{ borderRadius: '15px' }} />
                        </div>
                      </Col>
                      <Col sm={12} md={6} lg={6} className="d-flex justify-content-center align-items-center">
                        <div className="container">
                          <h5 className="text-black fw-bolder h5">{"Silva Method Digital Course"}</h5>
                          <p style={{ lineHeight: '1.2rem' }}>
                            Access the complete digital course for in-depth learning and self-improvement
                          </p>
                          <button className={`herodiv-becomememberbtn mb-3'} text-white fw-bold`} onClick={onClick2}>
                            Learn More
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Fade>
              </div>
            }
          </Col>
        </Row>
      </div>
    </div>
  );
});

export default ComboHero2;
