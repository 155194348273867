import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { FaCheckCircle, FaCheckSquare } from 'react-icons/fa';

import { FaStripe, FaLock } from "react-icons/fa";
import {
  REACT_BASE_URL,
  domainUrl,
  reqData,
  requestData,
} from "../../utils/baseUrl";
import moment from "moment";
import { toast } from "react-hot-toast";
import { useContext } from "react";
import { AuthContext } from "../../context/AllContext";
import { useLocation } from "react-router-dom";
import LiveInteractionModal from "./LiveInteractionModal";

function PaymentCard({ datanew, getCourses }) {

  const location = useLocation()

  const [currentEvent, setCurrentEvent] = useState(null);
  const [defaultPayment, setDefaultPayment] = useState("Stripe");
  const [studentId, setStudentId] = useState("");
  const [paypalOrderID, setPaypalOrderID] = useState("");

  const { userData, isMobile } = useContext(AuthContext);
  const searchParams = new URLSearchParams(location.search);
  const tokenParam = searchParams.get('coupon');
  const formRef = useRef(null);
  const token = null;
  const [loading, setLoading] = useState(false);

  const [priceplan2, setPricePlan2] = useState("");

  const [hasEmail, setHasEmail] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [coupon, setCoupon] = useState(token || tokenParam);

  const [validCoupon, setValidCoupon] = useState("");
  const [includeLiveClass, setIncludeLiveClass] = useState(false);
  const [couponApplied, setCouponApplied] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const btnRef = useRef(null);

  useEffect(() => {
    setCoupon(token);
  }, [token]);




  console.log(datanew,'tokenp')





  useEffect(() => {
    // console.log(paramCoupon,'paramcoupon')
    if (coupon && (token || tokenParam) && datanew) {
      btnRef?.current?.click();
    }
  }, [coupon, datanew]);


  const handleApplyCoupon = async () => {
    if (!coupon) {
      toast.error("Please enter a coupon !");
      return;
    };

    if (true) {
      const res = await requestData("applyCouponCode", "POST", {
        coupon_code: coupon,
      });
      if (res && res.error === false) {
        const validCoupon = moment(new Date(Date.now())).isBetween(
          res.data.start_date,
          res.data.end_date
        );

        let priceplan;

        const originalPrice = includeLiveClass
          ? datanew.price_with_live_class
          : datanew?.price;

        // Discount percentage
        const discountPercentage = includeLiveClass
          ? datanew?.discount_percentage
          : datanew.discount_percentage || 0;

        // Calculating the discount amount
        const discountAmount =
          (discountPercentage * parseInt(originalPrice)) / 100;

        // Calculating the discounted price
        const discountedPrice = Number(
          Math.floor(originalPrice - discountAmount).toFixed(2)
        );

        // Result
        // console.log(discountedPrice);
        priceplan = discountedPrice;

        // }

        // setPricePlan2(priceplan)
        if (validCoupon) {
          // reactLocalStorage.set("coupon", coupon2)
          // reactLocalStorage.set("couponplan", 3)
          // reactLocalStorage.setObject("value",val)
          const newdis =
            priceplan - priceplan * (Number(res?.data.discount_percentage) / 100);

          // const found = plans.find(p=>p.cycle===cycle)
          // setCouponApplied(true)

          let finalPrice;

          if (includeLiveClass) {
            // setCouponApplied(false);
            const originalPrice = priceplan;
            const discountPercentage = Number(res?.data.discount_percentage);

            // Calculate the discount amount
            const discountAmount = (originalPrice * discountPercentage) / 100;

            // Calculate the final price after discount
            finalPrice = Math.floor(originalPrice - discountAmount);
          } else {
            finalPrice = Math.floor(newdis.toFixed(2));
          }
          // console.log(Math.floor(newdis.toFixed(2)),'newdis')

          // console.log(finalPrice,"finalPrice2")
          setPricePlan2(finalPrice);

          console.log(finalPrice, "finalprice", originalPrice)

          setValidCoupon(coupon);

          // setPricePlan1(Number(plans[0].price).toFixed(2))

          toast.success("coupon applied", {
            position: "top-center",
          });
        } else {
          toast.error("Coupon is not valid !!", {
            position: "top-center",
          });
        }
      } else {
        toast.error(res.messages, {
          position: "top-center",
        });
      }
    } else {
      toast.error('coupon already applied')
    }



  };

  const handleSubscription = async () => {
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(userEmail);
    if (!isValidEmail) {
      toast.error("Please enter a valid email");
      return;
    }

    if (defaultPayment === "Stripe") {
      handleSubscriptionStripe();
    } else {
      handleSubscriptionPaypal();
    }
  };

  const handleSubscriptionPaypal = async () => {
    const data = {
      course_id: datanew.course_id,
      email: userEmail,
      payment_gatway: defaultPayment,
    };

    // if (includeLiveClass) {
    //   data.course_id =
    //     "silva-method-complete-course-with-live-interactive-program";
    // }

    if (includeLiveClass) {
      data.live_class_included = "Yes"
    } else {
      data.live_class_included = "No"

    }
    if (validCoupon) {
      data.coupon_code = validCoupon;
    }
    setLoading(true);
    const res = await requestData("enrollNow", "POST", data);

    // localStorage.setItem("token",res.student_id)

    if (res && res.error === false && res.status === 200) {
      setPaypalOrderID(res.order_id);
      setStudentId(res.student_id);
    } else {
      toast.error(res.messages);
    }

    // const gentok = await generateToken();

    // const courseName = datanew.course_title;
    // const order = await createOrder(
    //     gentok,
    //     priceplan2,
    //     res.order_id,
    //     courseName,
    //     res.student_id
    // );

    setLoading(false);

    // if (!!order?.links) {
    //     const url = order.links.find(link => link.rel == 'approve');
    //     window.location.href = url.href
    // }
  };

  const handleSubscriptionStripe = async () => {
    const data = {
      course_id: datanew.course_id,
      email: userEmail,
      payment_gatway: defaultPayment,
    };

    // if (includeLiveClass) {
    //   data.course_id =
    //     "silva-method-complete-course-with-live-interactive-program";
    // }
    if (includeLiveClass) {
      data.live_class_included = "Yes"
    } else {
      data.live_class_included = "No"

    }
    if (validCoupon) {
      data.coupon_code = validCoupon;
    }
    setLoading(true);
    const res = await requestData("enrollNow", "POST", data);
    // localStorage.setItem("token",res.student_id)
    setLoading(false);

    if (res && res.title === "StripeExceptionInvalidRequestException") {
      toast.error("You are already subscribed to stripe");
      return;
    }

    if (res && res.error === true) {
      toast.error(res.messages);
      return;
    }

    if (res && res.error === false) {
      window.location.assign(res.data);
    }
  };

  useEffect(() => {
    if (userData && userData.email) {
      setHasEmail(true);
      setUserEmail(userData.email);
    } else {
      setHasEmail(false);
    }
  }, [userData]);

  useEffect(() => {
    if (paypalOrderID && studentId) {
      if (formRef.current) {
        formRef.current.submit();
      }
    }
  }, [paypalOrderID, studentId]);

  useEffect(() => {
    if (datanew) {
      setPricePlan2(
        Math.floor(
          datanew?.price -
          ((datanew?.discount_percentage || 0) * parseInt(datanew?.price)) /
          100
        ).toFixed(2)
      );
    }
  }, [datanew]);

  const handlePlusCoupon = async (c) => {
    const res = await requestData("applyCouponCode", "POST", {
      coupon_code: coupon,
    });
    return res;
  };

  useEffect(() => {
    if (datanew && datanew.course_id) {
      const is_combo = datanew.is_combo_course;

      if (is_combo === "Yes") {
        if (includeLiveClass) {


          const originalPrice = Number(datanew.price_with_live_class);
          const discountPercentage = Number(
            datanew?.discount_percentage
          );

          const discountAmount = (originalPrice * discountPercentage) / 100;

          let finalPrice = Math.ceil(originalPrice - discountAmount);

          setPricePlan2(finalPrice);
          console.log(finalPrice, "finalPrice")

        } else {
          setPricePlan2(
            Math.ceil(
              datanew?.price -
              ((datanew?.discount_percentage || 0) *
                parseInt(datanew?.price)) /
              100
            ).toFixed(2)
          );
        }
      }
    }
  }, [includeLiveClass, datanew]);

  useEffect(() => {
    const fetchComboLive = async () => {
      const res = await reqData("courseDetail", "POST", {
        course_id: "silva-method-complete-course-with-live-interactive-program",
      });

      if (res.error === false) {
        setCurrentEvent(res.data);
      }
    };

    fetchComboLive();
  }, []);





  // useEffect(()=>{
  //   if(includeLiveClass && coupon){

  //   }
  // },[includeLiveClass])



  return (
    <>
      {datanew && (
        <Card
          className={
            datanew.is_combo_course === "No"
              ? datanew.course_id === "silva-method-manifesting-program"
                ? "coursecard coursecardmanifest"
                : "coursecard"
              : "coursecard combocard"
          }
          style={{ position: "relative" }}
        >
          <Card.Body className="coursecardbodymain">
            <div className="coursecardbody">
              <Card.Img
                src={datanew?.web_image}
                className="cardpriceimg"
                // src='https://silvamethod.com/manage/assets/uploads/Untitled%20design%20(3).webp'
                style={{
                  borderRadius: "15px",
                  objectFit: "cover",
                }}
              />

              <h5 className="courseprice">Course Price</h5>

              <div className="coursepricediv">
                {datanew.discount_percentage &&
                  datanew.discount_percentage != 0 && (
                    <h3 className="ratinghead4">
                      {priceplan2 ? `$${priceplan2}` : `$${priceplan2}`}
                    </h3>
                  )}
                {/* ${Math.floor((datanew?.price) - (((datanew?.discount_percentage) * parseInt(datanew?.price)) / 100))} */}
                {(!datanew.discount_percentage ||
                  datanew.discount_percentage == 0) && (
                    <h3 className="ratinghead4">${priceplan2}</h3>
                  )}
                {datanew.discount_percentage &&
                  datanew.discount_percentage != 0 && (
                    <h4 className="ratinghead3">
                      $
                      {includeLiveClass && datanew
                        ? Number(datanew?.price_with_live_class)
                        : datanew?.price}
                    </h4>
                  )}
                {datanew.discount_percentage &&
                  datanew.discount_percentage != 0 && (
                    <p style={{ fontWeight: "500" }}>
                      You will get{" "}

                      {
                        includeLiveClass ? Math.ceil((((Number(datanew?.price_with_live_class) - priceplan2) / Number(datanew?.price_with_live_class)) * 100)) :
                          Math.ceil((((Number(datanew?.price) - priceplan2) / Number(datanew?.price)) * 100))
                      }
                      % off
                    </p>
                  )}

                {
                  !isMobile && (
                    <div className="lifetime-access">

                      <span className="textlife">
                        <span>
                          <FaCheckCircle className="check-icon" />
                        </span>
                        Lifetime Access</span>
                    </div>

                  )
                }
              </div>
              {
                isMobile && (
                  <div className="lifetime-access">

                    <span className="textlife">
                      <span>
                        <FaCheckCircle className="check-icon" />
                      </span>
                      Lifetime Access</span>
                  </div>

                )
              }

            </div>



            <form onSubmit={(e) => e.preventDefault()}>
              {datanew.is_combo_course === "Yes" && (
                <>
                  <label htmlFor="couponinput" className="couponinputlabel">
                    Do you have a coupon code ?
                  </label>
                  <InputGroup className="mb-2">
                    <Form.Control
                      placeholder="Type Code Here"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      id="couponinput"
                      className="couponinputfield"
                      value={coupon}
                      onChange={(e) => setCoupon(e.target.value)}
                    />
                    <InputGroup.Text
                      id="basic-addon1"
                      className="applycouponbtn"
                      onClick={handleApplyCoupon}
                      ref={btnRef}
                    >
                      Apply
                    </InputGroup.Text>
                  </InputGroup>
                </>
              )}


              {hasEmail === false && (
                <div>
                  <label htmlFor="emailinput" className="couponinputlabel">
                    Your Email Id <span style={{ color: "red" }}>*</span>
                  </label>
                  <Form.Control
                    placeholder="Type Here"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    id="emailinput"
                    className="couponinputfield"
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                  />
                </div>
              )}
            </form>

            <Form className="mt-2">
              <label htmlFor="emailinput" className="couponinputlabel mb-2">
                Your Preferred Payment Mode
              </label>

              <div className="formpaymentradio">
                <div className="">
                  <Form.Check
                    type={"checkbox"}
                    id={`check-api-checkbox`}
                    style={{ marginBottom: "0px",display:"flex" , alignItems:"baseline"}}
                  >
                    <Form.Check.Input
                      type={"checkbox"}
                      isValid
                      checked={defaultPayment === "Stripe"}
                      onChange={() => setDefaultPayment("Stripe")}
                    />
                    <Form.Check.Label className="stripelabel">
                      Pay Via{" "}
                      <span style={{ marginLeft: "3px" }}>
                        <FaStripe color="#655fff" size={40} />
                      </span>
                    </Form.Check.Label>
                  </Form.Check>
                </div>

                <div>
                  <Form.Check type={"checkbox"} id={`paypal`}>
                    <Form.Check.Input
                      type={"checkbox"}
                      isValid
                      checked={defaultPayment === "Paypal"}
                      onChange={() => setDefaultPayment("Paypal")}
                    />
                    <Form.Check.Label className="paypalabel">
                      Pay Via{" "}
                      <span>
                        <Image
                          fluid
                          src={require("../../images/paypal-logo-preview.png")}
                          style={{ width: "auto", height: "18px" }}
                        />
                      </span>
                    </Form.Check.Label>
                  </Form.Check>
                </div>
              </div>
            </Form>

            <div className="secureserver">
              <span>
                <FaLock size={15} />
              </span>

              <p style={{ fontSize: "0.6rem" }}>
                For your security all orders are processed on a secure server
              </p>
            </div>

            {defaultPayment === "Paypal" && (
              <form
                id="form"
                action={process.env.REACT_APP_PAYPAL_URL}
                method="post"
                ref={formRef}
              >
                <input
                  type="hidden"
                  name="business"
                  value={process.env.REACT_APP_BUSINESS_EMAIL}
                />
                <input type="hidden" name="cmd" value="_xclick" />
                <input
                  type="hidden"
                  name="item_name"
                  value={datanew.course_title}
                />
                <input
                  type="hidden"
                  name="item_number"
                  value={datanew.course_id}
                />
                <input type="hidden" name="amount" value={priceplan2} />
                <input type="hidden" name="currency_code" value="USD" />
                <input type="hidden" name="custom" value={paypalOrderID} />
                <input
                  type="hidden"
                  name="cancel_return"
                  value={`${REACT_BASE_URL}subscription-failed?q=${btoa(
                    paypalOrderID
                  )}&s=${btoa(studentId)}`}
                //    value={`${'http://localhost:3000/'}subscription-failed?q=${btoa(
                //   paypalOrderID
                // )}&s=${btoa(studentId)}`}
                />

                <input
                  type="hidden"
                  name="return"
                  value={`${REACT_BASE_URL}subscription-success?q=${btoa(
                    paypalOrderID
                  )}&s=${btoa(studentId)}&pg=${btoa("paypal")}`}
                //   value={`${'http://localhost:3000/'}subscription-success?q=${btoa(
                //   paypalOrderID
                // )}&s=${btoa(studentId)}&pg=${btoa("paypal")}`}
                />

                <input
                  type="hidden"
                  name="notify_url"
                  value={`${domainUrl}/paypal-notify`}
                />
                <input type="hidden" name="auto_return" value="all" />
                <input
                  type="hidden"
                  className="herodiv-becomememberbtn3"
                  value={"Enroll Now"}
                />
              </form>
            )}

            <div className="d-grid gap-2 mt-1 enrollbtn emptyhr">
              <Button
                variant="warning"
                size="sm"
                onClick={handleSubscription}
                style={{ height: "40px", fontWeight: "600", fontSize: "1rem" }}
                disabled={loading}
              >
                {loading ? (
                  <Spinner animation="border" variant="dark" />
                ) : (
                  "Enroll Now"
                )}
              </Button>
            </div>
            {/* {datanew?.is_combo_course === "Yes" && (
              <div className="highlighted-background mt-3" style={{ position: "relative" }}>
                <Form className="mt-3">
                  <Form.Check
                    type={"checkbox"}
                    id={`check-api-checkbox2`}
                    style={{ marginBottom: "0px" }}
                  >
                    <Form.Check.Input
                      type={"checkbox"}
                      isValid
                      checked={includeLiveClass}
                      onChange={() => {
                        setIncludeLiveClass(!includeLiveClass)

                      }}
                    />
                    <Form.Check.Label className="stripelabel">
                      <span className="gradient-text">
                        I want to join live interection classes
                      </span>
                      <button

                        style={{
                          color: "#fff",
                          marginLeft: "5px",
                          fontSize: "0.9rem",
                          textDecoration: "underline",
                          fontWeight: "500",
                          backgroundColor: "transparent",
                          border: "none"
                        }}
                        onClick={(e) => { e.preventDefault(); setShowModal(true) }}
                      >
                        Read More
                      </button>
                    </Form.Check.Label>
                  </Form.Check>
                </Form>
              </div>
            )} */}
          </Card.Body>
          <LiveInteractionModal show={showModal} handleClose={handleClose} />
        </Card>
      )}
    </>
  );
}

export default PaymentCard;
