import React from "react";
import HeadingNew from "../../AboutUs/HeadingNew";
import { Accordion } from "react-bootstrap";

function ComboPlusLiveFaqs({ data }) {
  return (
    <div className="container combofaq">
      <div className="my-4">
      <HeadingNew title={"Frequently asked questions"} />
      </div>
      <div className="m-3">
        <Accordion>
          {data.map((faq, i) => (
            <Accordion.Item eventKey={i} style={{backgroundColor:'#fff !important',color:'#000 !important',borderBottom:'1px solid black !important'}}>
              <Accordion.Header>{faq?.heading}</Accordion.Header>
              <Accordion.Body>
                {faq?.body}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </div>
  );
}

export default ComboPlusLiveFaqs;
