import React, { useState } from "react";
import { Image, Modal, Spinner } from "react-bootstrap";
import { AiFillCloseCircle } from "react-icons/ai";
import { useContext } from "react";
import { toast } from "react-hot-toast";
import ParaComp from "../SilvaManifestationProgram/ParaComp";
import { AuthContext } from "../../context/AllContext";
import { requestData } from "../../utils/baseUrl";
import HeadingModal from "../AboutUs/HeadingModal";

function LiveInteractionModal({ show, handleClose }) {
  const { isMobile } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    email: "",
    phone: "",
    name: "",
    source: "",
  });

  function isNumeric(value) {
    return /^\d+$/.test(value);
  }

  // Check if the pressed key is a number
  function isNumericInput(event) {
    const key = event.key;
    return /^[0-9]+$/.test(key);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, phone, name, source } = values;
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(values.email);

    if (!isValidEmail) {
      toast.error("Please enter a valid email", {
        position: "top-center",
      });
      return;
    }
    const data = {};

    if (email) {
      data.email = email;
    }
    if (phone) {
      data.phone = phone;
    }
    if (name) {
      data.name = name;
    }
    if (source) {
      data.source = source;
    }

    setLoading(true);
    const res = await requestData("welcomeForm", "POST", data);
    setLoading(false);
    if (res && res.error === false) {
      toast.success(
        "Thank you! Your Relaxation Exercise is on the way. Kindly check your mail and get relaxed with Silva method",
        {
          position: "top-center",
          duration: 4000,
        }
      );
      setValues({
        email: "",
        phone: "",
        source: "",
        name: "",
      });
      handleClose();
    } else {
      toast.error(res.messages, {
        position: "top-center",
        duration: 4000,
      });
    }
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      centered
      backdrop="static"
      style={{ borderRadius: "30px", height: "100%" }}
      className="emailnewsmodal"
    >
      <Modal.Body className="d-flex justify-content-center align-items-center gap-3">
        {!isMobile && (
          <div style={{ width: "100%", height: "100%" }}>
            <Image
              style={{ height: "100%" }}
              className="newsletterimg"
              fluid
              src={"/img/combo/askintstructor.webp"}
            />
          </div>
        )}

        <div
          className="d-flex justify-content-between align-items-center flex-column "
          style={{ padding: "10px 25px 10px 15px" }}
        >
          <span style={{ alignSelf: "flex-end" }} onClick={handleClose}>
            <AiFillCloseCircle color="#7a12d4" size={30} />
          </span>
          <div className="d-flex justify-content-center align-items-start flex-column">
            {/* <Modal.Title className='modaltitlenew mb-2'>
               Silva Method
            </Modal.Title> */}
            <div className="mb-2">
              <HeadingModal
                small
                start
                title={"Special Add-Ons: "}
                colored={"3 Live Sessions with Experts"}
              />
            </div>
            <div className="my-4">
              <ParaComp
                data={[
                  "If you're seeking expert guidance on your Silva Method journey, you can enroll in our live interactive sessions. You'll receive three sessions each month with a certified Silva Method instructor, providing you with valuable knowledge and the chance to clear any doubts you may have.",
                ]}
                white={false}
                margin={false}
                fw={500}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default LiveInteractionModal;
