import React, { useContext,useState } from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

import toast from "react-hot-toast";
import { AuthContext } from "../../../context/AllContext";
import { reqData } from "../../../utils/baseUrl";
import HeadingNew22 from "../../AboutUs/HeadingNew22";
import HeadingNew from "../../AboutUs/HeadingNew";

function AppointmentHero() {
  const [loading, setLoading] = useState(false);
  const { isMobile,isTablet,isDesktopOrLaptop } = useContext(AuthContext);

  const [values, setValues] = useState({
    email: "",
    name: "",
    whatsapp_number: "",
    date: "",
    query: "",
    country_name: "",
  });

  const handleSubmit = async () => {
    const {
      email,
      name,
      whatsapp_number,
      date,
      query: message,
      country_name,
    } = values;

    const pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    const isValidEmail = pattern.test(email);
    if (!isValidEmail) {
      toast.error("Please enter a valid email");
      return;
    }

    if (!name) {
      toast.error("Please enter a valid name");
      return;
    }
    if (!whatsapp_number) {
      toast.error("Please enter a valid whatsapp number");
      return;
    }

    if (!date) {
      toast.error("Please enter a valid date");
      return;
    }
    if (!message) {
      toast.error("Please enter a valid message");
      return;
    }

    const obj = {
      name,
      email,
      phone: whatsapp_number,
      date,
      message,
      country_name,
    };
    setLoading(true);

    const res = await reqData("/bookAppointment", "POST", obj);

    setLoading(false);
    if (res && res.error === false) {
      toast.success(res.messages);
      setValues({
        email: "",
        name: "",
        whatsapp_number: "",
        date: "",
        query: "",
      });
    } else {
      toast.error(res.messages);
    }
  };

  return (
    <div className={`new_appintmenthero`}>
      {/* <div id='overlay'>

            </div> */}
      <div className="container">
        <Row className="justify-content-center align-items-center">
          <Col xs={12} md={12} lg={6} xl={7}>
            <div
              className={
                (isMobile || isTablet) 
                  ? "d-flex justify-content-center align-items-center flex-column"
                  : "d-flex justify-content-start align-items-start flex-column"
              }
            >
              <HeadingNew22 title={"Still Confused?"} white />
              <HeadingNew22
                white
                title={"Let an expert from our support team assist you for"}
                small
                colored={"FREE!"}
                start={isDesktopOrLaptop?true:false}
              />

              <p
                className="text-white mt-3"
                style={{
                  textAlign: (isMobile || isTablet) ? "center" : "start",
                  width: isMobile ? "90%" : "80%",
                  margin:(isMobile || isTablet) ?"0 auto 30px":"",
                }}
              >
                If you are still confused about what the Silva Method Courses
                are all about and how they can bring positive and rapid
                transformation to your life, no need to worry. You can now talk
                to an expert for Free. Simply fill in the form with your details
                and wait for your call with the instructor.
              </p>
            </div>
          </Col>

          <Col xs={12} md={12} lg={6} xl={5}>
            <div className="d-flex justify-content-end align-items-center flex-column newappointmentform">
              <Card
                style={{
                  width: isMobile ? "90%" : "80%",
                  borderRadius: "12px",
                }}
              >
                <Card.Body>
                  <Card.Title
                    className="text-dark text-center"
                    style={{
                      fontWeight: "600",
                      fontSize: "1.5rem",
                      textTransform: "uppercase",
                      margin:0
                    }}
                  >
                    Submit the form
                  </Card.Title>
                  <p
                  style={{
                    fontWeight: "600",
                    fontSize: "1.1rem",
                    textAlign:"center"
                  }}
                  >to connect with a team member</p>
                  <div
                    style={{
                      padding: "0 30px 30px 30px",
                    }}
                  >
                    <Row className="gy-3 justify-content-center align-items-center">
                      <Col xs={12}>
                        <label className="text-dark mb-1">
                          Enter Full name
                        </label>
                        <input
                          placeholder="enter full name"
                          type="text"
                          id="inputfield"
                          value={values.name}
                          onChange={(e) => {
                            setValues({
                              ...values,
                              name: e.target.value,
                            });
                          }}
                        />
                      </Col>
                      <Col xs={12}>
                        <label className="text-dark mb-1">
                          Enter whatsapp number with country code
                        </label>
                        <PhoneInput
                          inputStyle={{
                            width: "100%",
                          }}
                          placeholder="Enter country code and whatsapp number"
                          defaultCountry="usa"
                          value={values.whatsapp_number}
                          onChange={(phone, country) =>
                            setValues({
                              ...values,
                              country_name: country?.country?.name,
                              whatsapp_number: phone,
                            })
                          }
                        />
                      </Col>
                      <Col xs={12}>
                        <label className="text-dark mb-1">
                          Enter Your Email
                        </label>
                        <input
                          placeholder="enter your email"
                          type="email"
                          id="inputfield"
                          value={values.email}
                          onChange={(e) =>
                            setValues({ ...values, email: e.target.value })
                          }
                        />
                      </Col>
                      <Col xs={12}>
                        <label for="date" className="text-dark">
                          Select a Date:
                        </label>
                        <input
                          type="date"
                          id="date"
                          name="date"
                          min={new Date().toISOString().split("T")[0]}
                          max={(() => {
                            const maxDate = new Date();
                            maxDate.setMonth(maxDate.getMonth() + 1);
                            return maxDate.toISOString().split("T")[0];
                          })()}
                          value={values.date}
                          onChange={(e) => {
                            setValues({
                              ...values,
                              date: e.target.value,
                            });
                          }}
                        ></input>
                      </Col>

                      <Col xs={12}>
                        <label className="text-dark mb-1">
                          Enter Your Query
                        </label>
                        <textarea
                          placeholder="enter your message"
                          id="textareanew"
                          rows={4}
                          cols={50}
                          value={values.query}
                          onChange={(e) => {
                            setValues({
                              ...values,
                              query: e.target.value,
                            });
                          }}
                        />
                      </Col>
                      <Col xs={12}>
                        <button
                          id="appointbtn"
                          disabled={loading}
                          onClick={handleSubmit}
                        >
                          {loading ? "...loading" : "Submit"}
                        </button>
                      </Col>
                    </Row>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default AppointmentHero;
