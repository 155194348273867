import React, { useContext } from 'react'
import HeadingNew from '../AboutUs/HeadingNew'
import { AuthContext } from '../../context/AllContext'

function VideoSectionNew() {
  const {isMobile} = useContext(AuthContext);

  return (
    <div className='d-flex justify-content-center align-items-center flex-column' style={{
      marginTop:isMobile?"50px":"100px"
    }}>
        <HeadingNew
        title={"Initiate, Transform & Flourish with "}
        small
        />
        <HeadingNew colored={"Silva Method"} />
        {/* <ReactPlayer
        url={"https://www.youtube.com/watch?v=gc0RXx43BH4"}
        style={{
            marginTop:"15px",
            borderRadius:"15px",
            marginBottom:"50px"
        }}
        

        /> */}
         <div className='container d-flex justify-content-center align-items-center'>
         <video src='https://silvabucket.s3.ap-south-1.amazonaws.com/Silva+Intro+Video.mp4' style={{
          width:"90%",
          height:"90%",
          borderRadius:"15px"

        }}
        autoPlay
        controls
        muted
        className='my-4'
        loop
        />

         </div>

    </div>
  )
}

export default VideoSectionNew