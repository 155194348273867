import React from 'react'
import HeadingNew from '../AboutUs/HeadingNew'

function Unauthorized() {
  return (
    <div className='mt-130 mb-100'>
        <HeadingNew title={'You are unauthorized to access this page'}/>
        <HeadingNew title={'Please login with the enrolled account.'} small/>
    </div>
  )
}

export default Unauthorized