import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'

function ScheduleTable({ data }) {

    const [days,setDays] = useState([]);
    const [curr,setCurr] = useState([]);




    useEffect(()=>{
      if(data && data?.days){
        const keysOnly = data.days.map(obj => Object.keys(obj)[0]);
        const valuesOnly = data.days.map(obj => Object.values(obj)[0]);
        setCurr(valuesOnly)
        setDays(keysOnly)
      }
    },[data])


    if(!days){
        return null
    }
    return (

        <div className="table-responsive py-2">
            <table className="table table-bordered-inside table-hover">
                <thead className="thead-dark tableheading">
                    <tr>
                        {/* <th scope="col">Day 1</th> */}
                        {/* <th scope="col">Day 2</th>
                        <th scope="col">Handle</th> */}
                        {
                            days.map((val,i)=>(
                                 <th scope="col" className='text-center p-2 tdrow' key={i}>
                                    Part {val}
                                 </th> 
                            ))
                        }
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {/* <td>Otto</td>
                        <td>@mdo</td> */}
                        {
                            curr.map((val,i)=>(
                                <td key={i} className='p-2 text-center tdrow2'>{val}</td>
                            ))
                        }
                    </tr>
                    
                </tbody>
            </table>
        </div>
    )
}

export default ScheduleTable