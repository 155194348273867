import React, { useContext, useMemo, useState } from 'react';
import HeadingNew from '../AboutUs/HeadingNew';
import AppointmentFormModal from './DownloadModal';
import { AuthContext } from '../../context/AllContext';
import { FaFileDownload } from "react-icons/fa";

function BannerDownload() {
    const [modalShow, setModalShow] = useState(false);

    const {isMobile} = useContext(AuthContext);


    const handleDownload = () => {
        // Handle download logic here
      
        setModalShow(true)
    };


    const styles = useMemo(()=>{

        return {
            bannerContainer: {
        
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: 'auto',
                padding:"40px 20px",
                // backgroundColor: '#4CAF50',
                background: " linear-gradient(to right, rgb(238 237 255), rgb(255 248 249))",
                color: 'white',
        
                textAlign: 'center',
                borderRadius: isMobile?"10px":'15px',
                marginTop: isMobile?"0px":"70px",
            },
            title: {
                fontSize: '36px',
                marginBottom: '20px',
        
            },
            button: {
                fontSize: '18px',
                padding: '10px 20px',
                backgroundColor: '#ffffff',
                color: '#4CAF50',
                border: 'none',
                borderRadius: '5px',
                marginTop: "20px",
                cursor: 'pointer',
                transition: 'background-color 0.3s ease',
            }
        }
    },[isMobile]);

    return (
        <div className='container'>
             <div style={styles.bannerContainer}>
            {/* <h1 style={styles.title}>Your Banner Title</h1> */}
            <HeadingNew title={"Get The full Curriculum of "} colored={"Silva Method Complete Program "} small />
            <button onClick={handleDownload} className='cssbuttons-io-button3 mt-4'>
            <span style={{
                    marginRight:"5px"
                }}>
                    <FaFileDownload color='white' size={20} />
                </span>
                Download
             
            </button>
            <AppointmentFormModal show={modalShow}
                onHide={() => setModalShow(false)} />
        </div>
        </div>
    );
}



export default BannerDownload;
