import React, { useContext, useState } from "react";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import toast from "react-hot-toast";
import { reqData } from "../../utils/baseUrl";
import { AuthContext } from "../../context/AllContext";
import { FaCross } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";

function AppointmentFormModal({ show, onHide }) {
    const [loading, setLoading] = useState(false);

    const {isMobile} = useContext(AuthContext);


    const [values, setValues] = useState({
        email: "",
        name: "",
        phone_whatsapp: "",

    });

    const handleSubmit = async () => {
        const { email, name, phone_whatsapp } = values;

        const pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        const isValidEmail = pattern.test(email);

        if (!isValidEmail) {
            toast.error("Please enter a valid email");
            return;
        }

        if (!name) {
            toast.error("Please enter a valid name");
            return;
        }
        if (!phone_whatsapp) {
            toast.error("Please enter a valid WhatsApp number");
            return;
        }

        const obj = {
            name,
            email,
            phone_whatsapp,
        };



        setLoading(true);

        const res = await reqData("/completeCourseCurriculumForm", "POST", obj);

        setLoading(false);
        if (res && res.error === false) {
            toast.success(res.messages);
            setValues({
                email: "",
                name: "",
                phone_whatsapp: "",
            });
            onHide(); // Close the modal after successful submission
            window.location.href="https://silvamethod.com/silva-complete-curriculum.pdf"
          
        } else {
            toast.error(res.messages);
        }
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header className="d-flex justify-content-between align-items-start">
                <Modal.Title style={{
                    color: "#000",
                    fontFamily: "Roboto",
                    paddingLeft:"30px",
                    paddingRight:"30px",
                    fontWeight:"bold",
                    lineHeight:isMobile?"1.7rem":"2rem"
                }}>Submit the form to get The Silva Method Complete Program Curriculum</Modal.Title>

                <span onClick={onHide}>
                    <IoMdClose color="black" />
                </span>
            </Modal.Header>
            <Modal.Body>

                <div
                    style={{
                        padding: "0 30px 30px 30px",
                    }}
                    className="downloadform"
                >
                    <Row className="gy-3 justify-content-center align-items-center">
                        <Col xs={12}>
                            <label className="mb-1 text-dark">Enter Full Name</label>
                            <input
                                placeholder="Enter full name"
                                type="text"
                                id="inputfield"
                                value={values.name}
                                onChange={(e) => {
                                    setValues({
                                        ...values,
                                        name: e.target.value,
                                    });
                                }}
                            />
                        </Col>
                        <Col xs={12}>
                            <label className="mb-1  text-dark">Enter WhatsApp Number with Country Code</label>
                            <PhoneInput
                                inputStyle={{
                                    width: "100%",
                                    height: "40px"
                                }}
                                countrySelectorStyleProps={{
                                    buttonContentWrapperStyle: {
                                        height: "40px"
                                    }
                                }}

                                placeholder="Enter country code and WhatsApp number"
                                defaultCountry="usa"
                                value={values.phone_whatsapp}
                                onChange={(phone) =>
                                    setValues({
                                        ...values,
                                        phone_whatsapp: phone,
                                    })
                                }
                            />
                        </Col>
                        <Col xs={12}>
                            <label className="mb-1  text-dark">Enter Your Email</label>
                            <input
                                placeholder="Enter your email"
                                type="email"
                                id="inputfield"
                                value={values.email}
                                onChange={(e) =>
                                    setValues({ ...values, email: e.target.value })
                                }
                            />
                        </Col>

                        <Col xs={12}>
                            <button
                                // id="appointbtn"

                                variant="primary"
                                disabled={loading}
                                onClick={handleSubmit}
                                className="cssbuttons-io-buttonnew"
                            >
                                {loading ? "...loading" : "Submit"}
                            </button>
                        </Col>
                    </Row>
                </div>

            </Modal.Body>
        </Modal>
    );
}

export default AppointmentFormModal;
