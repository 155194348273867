// import { Card, Col, Container, Image, Row } from "react-bootstrap";
// import { useContext, useState } from "react";
// import { FaCheckCircle } from "react-icons/fa";
// import { AuthContext } from "../../../context/AllContext";
// import HeadingNew from "../../AboutUs/HeadingNew";
// import { IoShieldCheckmarkSharp } from "react-icons/io5";
// import { useEffect } from "react";

// function ComboBenifitNew({ datanew }) {
//     const { isMobile, isTablet, isLaptop } = useContext(AuthContext);

//     const [loadMore,setLoadMore] = useState(false);

//     const handleScroll = () => {
//         const doc = document.querySelector(".cardtoscroll");
//         console.log(doc, "document")
//         if (doc) {
//             doc.scrollIntoView({
//                 behavior: "smooth"
//             })
//         }

//     }

//     useEffect(() => {
//         console.log("datanew", datanew)
//     }, [datanew])

//     if (!datanew) {
//         return null
//     }

//     return (
//         <div style={{
//             backgroundImage: `url("/img/bg/bgnewnew.jpg")`,
//             backgroundSize: "cover",
//             backgroundPosition: "center",
//             boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
//             paddingTop: 50,
//             paddingBottom: 50,
//         }}>
//             <div className="d-flex justify-content-center align-items-center" style={{
//                 marginBottom: isMobile ? "0px" : "30px"
//             }}>
//                 <div className="my-3">
//                     <HeadingNew title={"Benefits of The Silva Method Combo Program"} />
//                 </div>
//             </div>
//            <div style={{
//             display:"flex",
//             justifyContent:"center",
//             alignItems:"center"
//            }}>
//            <Row className="container justify-content-center align-items-center">
//                 <Col xs={12} md={6} className={isMobile?"order-2":"order-1"}>
//                     <div style={{
//                         display: "flex",
//                         justifyContent: "flex-start",
//                         alignItems: "flex-start",
//                         flexDirection: "column",
//                         marginTop: 20
//                     }}>
//                         {
//                           loadMore?(
//                             datanew?.course_benefits?.map((val, i) => (
//                                 <div className="d-flex gap-3">
//                                     <span>
//                                         <IoShieldCheckmarkSharp color="green" size={25} />
//                                     </span>

//                                     <p style={{
//                                         color: "black",
//                                         fontWeight: "500",
//                                         fontSize: (isTablet || isLaptop) ? "1.2rem" : isMobile ? "1rem" : "1rem"
//                                     }}>

//                                         {val.title}
//                                     </p>

//                                 </div>
//                             ))
//                           ):(
//                             datanew?.course_benefits?.slice(0,6).map((val, i) => (
//                                 <div className="d-flex gap-3">
//                                     <span>
//                                         <IoShieldCheckmarkSharp color="green" size={25} />
//                                     </span>

//                                     <p style={{
//                                         color: "black",
//                                         fontWeight: "500",
//                                         fontSize: (isTablet || isLaptop) ? "1.2rem" : isMobile ? "1rem" : "1rem"
//                                     }}>

//                                         {val.title}
//                                     </p>

//                                 </div>
//                             ))
//                           )
//                         }

//                     </div>
//                     <a style={{
//                         color:"black",
//                         marginTop:"10px",
//                         marginBottom:"10px",
//                         display:"block",
//                         width:"100px",
//                         cursor:"pointer",
//                         fontWeight:"600",
//                         fontSize:"1.1rem"

//                     }}
//                     onClick={()=>{
//                         setLoadMore(!loadMore)
//                     }}
//                     >
//                         {loadMore ? "Load Less":"Load More"}
//                     </a>

//                 </Col>
//                 <Col xs={12} md={6} className={isMobile?"order-1":"order-2"}>
//                    <div className={isMobile?"d-flex justify-content-center align-items-center":"d-flex justify-content-end align-items-end"}>
//                    <img src="/img/bg/medilivecombo.jpg"
//                         style={{
//                             width: isMobile?"100%":"90%",
//                             borderRadius: 15,
//                             boxShadow: "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset"
//                         }}
//                     />
//                    </div>
//                 </Col>

//             </Row>
//            </div>

//         </div>
//     );
// }

// export default ComboBenifitNew;

import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import HeadingNew from "../../AboutUs/HeadingNew";
import { FiCheckCircle } from "react-icons/fi";

function ComboBenefitNew({ datanew }) {
  return (
    <div className="combobenifitcontainer py-5">
      <div className="container py-5">
        <div className="mb-5">
          <HeadingNew
            title={"Benefits of The Silva Method Combo Program"}
            white
          />
        </div>
        <Row>
          {datanew?.course_benefits?.map((data, i) => (
            <Col lg={4} md={6} sm={12} className="p-2">
              <Card className="w-100 d-flex flex-row align-items-center p-2 gap-3 h-100">
                <div className="combo-icon">
                  <FiCheckCircle color="white" />
                </div>
                <p className="h6 text-white m-0">{data?.title}</p>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}

export default ComboBenefitNew;
