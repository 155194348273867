import React, { useContext } from 'react'
import HeadingNew from '../AboutUs/HeadingNew'

import { AuthContext } from '../../context/AllContext'
import { useNavigate } from 'react-router-dom'

function WhatIsSilvaMethodNew({scrollTo}) {
    const {isMobile} = useContext(AuthContext);

    const navigate = useNavigate()

    return (
        <div className='container' style={{
            marginTop:isMobile?"50px":"80px"
        }}>
            <HeadingNew title={"What is The "} colored={"Silva Method ?"} />
            <div className='my-3'>
              

                 <h5 className="mt-3 mb-3 text-center robotofont" style={{
                     lineHeight: isMobile?"1.3rem":"1.5rem",
                     fontSize: isMobile ? "0.9rem" : "1rem",
                 
                     color:"#000"
                 }}
                
                 >
                 The Silva Method, America’s first personal growth program, was developed by mind scientist and psychiorientologist Jose Silva after years of research. Unlike any other, Silva’s dynamic meditation empowers you to reprogram your mind for continuous improvement, taking charge of your subconscious mind, while staying fully conscious. Since 1966, this groundbreaking method has been the choice for those serious about self-improvement. With 10 million success stories, the Silva Method is your gateway to reclaiming natural healing, boosting intuition, and achieving extraordinary breakthroughs. From celebrities to CEOs, it’s the secret weapon for anyone ready to thrive.
                    
                 </h5>
               
                <div className=' d-flex justify-content-center align-items-center mt-4 flex-column'>
                    <button className='cssbuttons-io-button3' onClick={scrollTo}>
                        Begin Journey

                    </button>

                    <div className='d-flex gap-2 justify-content-center align-items-center mt-4'>
                    <div class="avatars">
                       {
                        isMobile? (
                            <>
                             <a onClick={()=>{
                            navigate("/success-stories")
                        }} style={{
                            cursor: "pointer"
                        }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/Dr%20Charan%20Surdhar.jpg" alt="" /></a>
                        <a onClick={()=>{
                            navigate("/success-stories")
                        }} style={{
                            cursor: "pointer"
                        }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/zsofia.jpg" alt="" /></a>
                        <a onClick={()=>{
                            navigate("/success-stories")
                        }} style={{
                            cursor: "pointer"
                        }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/kenshephard.jpg" alt="" /></a>
                        <a onClick={()=>{
                            navigate("/success-stories")
                        }} style={{
                            cursor: "pointer"
                        }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/Melissa.jpg" alt="" /></a>
                        <a onClick={()=>{
                            navigate("/success-stories")
                        }} style={{
                            cursor: "pointer"
                        }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/Joel%20Cruz.jpg" alt="" /></a>
                       
                            </>
                        ):(
                            <>
                             <a onClick={()=>{
                            navigate("/success-stories")
                        }} style={{
                            cursor: "pointer"
                        }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/Dr%20Charan%20Surdhar.jpg" alt="" /></a>
                        <a onClick={()=>{
                            navigate("/success-stories")
                        }} style={{
                            cursor: "pointer"
                        }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/zsofia.jpg" alt="" /></a>
                        <a onClick={()=>{
                            navigate("/success-stories")
                        }} style={{
                            cursor: "pointer"
                        }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/kenshephard.jpg" alt="" /></a>
                        <a onClick={()=>{
                            navigate("/success-stories")
                        }} style={{
                            cursor: "pointer"
                        }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/Melissa.jpg" alt="" /></a>
                        <a onClick={()=>{
                            navigate("/success-stories")
                        }} style={{
                            cursor: "pointer"
                        }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/Joel%20Cruz.jpg" alt="" /></a>
                        <a onClick={()=>{
                            navigate("/success-stories")
                        }} style={{
                            cursor: "pointer"
                        }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/Giselle.jpg" alt="" /></a>

                        <a onClick={()=>{
                            navigate("/success-stories")
                        }} class="avatars__item"><img class="avatar" src="https://silvamethod.com/img/K.%20Galway.jpg" alt="" /></a>
                            </>
                        )
                       }


                    </div>
                    <h6 style={{
                        color: "black"
                    }}>
                        Join 12M+
                        <br />
                        Active Students
                    </h6>
                </div>
                </div>

          

            </div>

        </div>
    )
}

export default WhatIsSilvaMethodNew