import React, { useContext, useEffect, useState } from 'react'
import HeadingNew from '../../AboutUs/HeadingNew'
import { Col, Row } from 'react-bootstrap'
import ParaComp from '../../SilvaManifestationProgram/ParaComp'
import { AuthContext } from '../../../context/AllContext'
import PaymentCard2 from '../newmembership/PaymentCard2'

function LastSectionMedi2() {
  const { userData } = useContext(AuthContext);
  const [shouldShow, setShouldShow] = useState(true);




  useEffect(() => {
    if (userData && userData.enrolled_course) {
      if (userData.enrolled_course.length === Number(userData.total_course)) {
        setShouldShow(false)
      }

    }
  }, [userData])





   

  if(!shouldShow){
    return null
  }
    return (
      <div className="normaldiv3">
        <div className='container d-flex justify-content-center align-items-center flex-column'>
          <Row className='justify-content-center align-items-center'>
            <Col xs={12} lg={6} xl={7} >
              <div className="container">
                <HeadingNew small title={"Mindfulness with"} start colored={"Dynamic Meditation :"} />
                <HeadingNew title={"Cultivating Presence and"} start colored={"Awareness"} />
                <ParaComp
                  white={false}
                  data={[
                    "By combining the principles of mindfulness with the dynamic and active nature of this meditation technique, you can deepen your practice, enhance self-awareness, and experience a greater sense of presence and peace. Embrace the dynamic nature of the practice while staying connected to the present moment, nurturing a mindful way of being in your life.",
                    "Join the Silva community and explore the transformative power of mindfulness in your dynamic meditation practice."
                  ]}
                />
              </div>
            </Col>
            <Col xs={12} lg={6} xl={5}>

             {
              shouldShow && (
                <PaymentCard2 />
              )
             }

            </Col>

          </Row>



        </div>
      </div>

    )

}

export default LastSectionMedi2