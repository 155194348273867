import React from 'react'
import { Card } from 'react-bootstrap';
import Slider from "react-slick";

function SliderSection() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <div>
            <Slider {...settings}>
            <Card style={{
                width:"100%",
                height:"300px"
            }}>
                <Card.Body>
                    <Card.Title>
                        Hello world
                    </Card.Title>

                </Card.Body>

            </Card>
            <Card style={{
                width:"100%",
                height:"300px"
            }}>
                <Card.Body>
                <Card.Title>
                        Hello world
                    </Card.Title>
                </Card.Body>

            </Card>
            <Card style={{
                width:"100%",
                height:"300px"
            }}>
                <Card.Body>
                <Card.Title>
                        Hello world
                    </Card.Title>
                </Card.Body>

            </Card>
            <Card style={{
                width:"100%",
                height:"300px"
            }}>
                <Card.Body>
                <Card.Title>
                        Hello world
                    </Card.Title>
                </Card.Body>

            </Card>
            </Slider>
          
        </div>
    )
}

export default SliderSection