import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import HeadingNew from '../../AboutUs/HeadingNew'
import { useContext } from 'react'
import { AuthContext } from '../../../context/AllContext'
import { handleScrollCombo, handleScrollCombo2 } from '../../../utils/handleScrollEmotions'

function LiveCourseSection() {
    const {isMobile} = useContext(AuthContext);

  
    return (
        <div className="cardiv5999 mt-sm-5 mt-xl-0">


            <Card style={{ width: "95%", padding: 10, borderRadius: 15 }}>

                <Card.Body>
                    <Row className='gap-sm-3 gap-xl-0'>
                        <Col xs={12} xl={6}>
                            <img src="/img/combo_course_image.png" className='image599banner' style={{

                                borderRadius: 15

                            }} />
                        </Col>
                        <Col xs={12} xl={6}>
                            <div className='d-flex flex-column gap-1'>
                                <HeadingNew small title={"Silva Method Complete Home Study Program"} start />
                                <p className='text-start'>
                                    Get access to complete Silva Method Digital course for a comprehensive learning
                                </p>
                                <div>
                                    <button className='aboutclassbtn3' onClick={handleScrollCombo}>
                                        View Curriculum
                                    </button>
                                </div>

                            </div>
                        </Col>
                    </Row>



                </Card.Body>
            </Card>
            <img src='/img/plusicon.png' style={{
                width: isMobile?50:100,
                height: isMobile?50:40,
                objectFit: "cover"
            }} />
            <Card style={{ width: "95%", padding: "20px", borderRadius: 15 }}>
                <div className="ribbon">Live</div>

                <Card.Body>
                    <Row className='gap-sm-3 gap-xl-0'>
                        <Col xs={12} xl={6}>
                            <img src='/img/live_interaction_image.png' className='image599banner' style={{

                                borderRadius: 15

                            }} />
                        </Col>
                        <Col xs={12} xl={6}>
                            <div className='d-flex flex-column gap-1'>
                                <HeadingNew small title={"Live Interactive Program"} start />
                                <p className='text-start'>
                                    Enjoy the live experience of learning in a transformative setting with cohorts
                                </p>
                                <div>
                                    <button className='aboutclassbtn3' onClick={handleScrollCombo2}>
                                        View Class Schedule
                                    </button>
                                </div>

                            </div>
                        </Col>
                    </Row>



                </Card.Body>
            </Card>

        </div>
    )
}

export default LiveCourseSection