import React, { useContext } from "react";
import { AuthContext } from "../../context/AllContext";

function HeadingNew({
  title,
  colored,
  small = false,
  boxed = false,
  boxedtext = "",
  white = false,
  bottomMargin = false,
  start = false,
  marginleft = false,
  underline = false,
  right,
}) {
  const { isBigScreen, isDesktopOrLaptop, isTablet, isMobile } =
    useContext(AuthContext);

  // Define the gradient animation keyframes
  const gradientAnimation = {
    background: "linear-gradient(to right, #6a11cb, #2575fc)",
    backgroundSize: "200% 200%",
    animation: "gradient 5s ease infinite",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  };

  return (
    <div>
      <h2
        style={{
          fontWeight: small ? "700" : "800",
          fontSize: isBigScreen
            ? small
              ? "25px"
              : "50px"
            : isDesktopOrLaptop
            ? small
              ? "25px"
              : "40px"
            : isTablet
            ? small
              ? "22px"
              : "36px"
            : isMobile
            ? small
              ? "20px"
              : "30px"
            : "22px",
          textAlign: right ? "right" : start ? "start" : "center",
          color: white ? "white" : "#0F131B",
          marginBottom: bottomMargin ? "25px" : "0",
          marginLeft: marginleft && !isMobile ? "18px" : "0px",
          lineHeight: !small
            ? isBigScreen
              ? "56px"
              : isDesktopOrLaptop
              ? "50px"
              : isTablet
              ? "42px"
              : "36px"
            : isBigScreen
            ? "35px"
            : "30px",
          letterSpacing: isMobile || isTablet ? "0.1px" : "1px",
          textDecoration:
            isMobile || isTablet ? "none" : underline ? "underline" : "none",
          textDecorationThickness: underline ? "0.2rem" : "0",
          textUnderlineOffset: underline ? "1rem" : "0",
          textDecorationColor: underline ? "rgb(216, 1, 251)" : "none",
          wordBreak: "break-word", // Ensures long words break into new lines
        }}
      >
        {title}{" "}
        {colored && (
          <div
            style={{
              ...gradientAnimation,
              fontWeight: "800",
              fontFamily: "Poppins",
              display: "inline-block",
            }}
          >
            {colored}
          </div>
        )}{" "}
        {boxed && (
          <p
            style={{
              padding: isMobile ? "12px 16px" : "16px 20px",
              color: "#fab80a",
              border: "none",
              backgroundColor: "white",
              fontSize: isBigScreen
                ? "40px"
                : isDesktopOrLaptop
                ? "30px"
                : isTablet
                ? "26px"
                : "20px",
              fontWeight: "800",
              borderRadius: "8px",
              display: "inline-block",
              wordBreak: "break-word", // Ensures long words break into new lines
            }}
          >
            {boxedtext}
          </p>
        )}
      </h2>
      <style>
        {`
          @keyframes gradient {
            0% {
              background-position: 0% 50%;
            }
            50% {
              background-position: 100% 50%;
            }
            100% {
              background-position: 0% 50%;
            }
          }
        `}
      </style>
    </div>
  );
}

export default HeadingNew;
