// Testimonial.js
import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import ReactHlsPlayer from 'react-hls-player';
import HlsVideoPlayer from '../Store/SingleLecturePage/HlsVideoPlayer';
import { MdOutlineStarPurple500 } from "react-icons/md";


const TestimonialCard = ({ data }) => {
    const [loadMore, setLoadMore] = useState(true)
    const [textData, setTextData] = useState("")

    const { type, text, image, video, name, designation } = data;



    useEffect(() => {
        if (text) {
            if (!loadMore) {
                setTextData(text)
            } else {
                setTextData(text.substring(0, 150) + "...")
            }
        }
    }, [loadMore, text]);


    const videoData={video_link:video}



    return (
        <div className={`eachdiv ${type === 'video' ? 'video' : ''}`} style={{padding: type==="video"?"0px":""}}>
            <div className="userdetails">
                {
                    type === "video" ? null : (
                        <div className="imgbox" style={{flex:"none"}}>
                            <img src={image} alt={name} style={{width:"100%",height:"100%",}}/>
                        </div>
                    )
                }
                <div className="detbox">
                    <p className="name" style={{
                        padding: 0,
                        margin: 0
                    }}>{name}</p>
                    <p className="designation" style={{
                        padding: 0,
                        margin: 0
                    }}>{designation}</p>
                   {type !== "video" && <p className='text-white'>
                            <MdOutlineStarPurple500  color='yellow' size={15}/>   
                            <MdOutlineStarPurple500  color='yellow' size={15}/>   
                            <MdOutlineStarPurple500  color='yellow' size={15}/>
                            <MdOutlineStarPurple500  color='yellow' size={15}/> 
                            <MdOutlineStarPurple500  color='yellow' size={15}/>    
                           {" "}
                    </p>}

                </div>
            </div>
            <div className="review ctlel">
                {type === 'video' && !video.includes(".m3u8") ? (
                    <video controls controlsList='nodownload' style={{display:"block"}}>
                        <source src={video} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                ) : type === 'video' && video?.includes(".m3u8")?(
                    <>
                       <HlsVideoPlayer
                        data={videoData}
                        style={{margin:0}}
                       />
                    </>
                ):(
                    <>
                        <p>{text ? text.length > 300 ? textData : text : ""}</p>
                    </>
                )}
            </div>
            {type && type !== "video" && text.length > 300 && (
                <button className='primary_btn3' onClick={() => setLoadMore(!loadMore)}>{loadMore ? "Read More" : "Read Less"}</button>
            )}
        </div>
    );
};

export default TestimonialCard;
