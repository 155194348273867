import React, { useCallback, useContext, useState } from 'react'
import { Image, Spinner } from 'react-bootstrap'
import { AuthContext } from '../../context/AllContext'
import { toast } from 'react-hot-toast';
import { requestData } from '../../utils/baseUrl';

function Replybox({closeFunc,comment,fetchCourseComments}) {
    const {userData} = useContext(AuthContext)
    const [reply,setReply] = useState("");
    const [loading,setLoading] = useState(false);

    const submitReply = async()=>{
      // console.log(comment,reply)

      if(!reply){
        toast.error("Please type a comment to reply",{
          position:"top-center"
        });
        return;
      }

      // console.log()

      setLoading(true)
      const res = await requestData("replyCourseComment","POST",{
        course_id:comment.course_id,
        comment_id:comment.id,
        comment:reply,
        name:(userData.first_name||"") + " "+(userData.last_name||""),
        email:userData.email
      })
      setLoading(false)

      if(res && res.error===false){
        toast.success(res.messages,{
          position:"top-center"
        })
        setReply("")
        fetchCourseComments()
      }else{
        toast.error(res.messages,{
          position:"top-center"
        })
      }


    }

    const handleReplyChange = useCallback((e)=>{
       setReply(e.target.value)
    },[reply])
  return (
    <div className="wrapper3">
              {/* <div className='smalldevicereplybtns'> */}
          
             <div className='replyfirstdiv'>
             <Image thumbnail className="wrapperimg" src={userData.profile_image} />
              <input type="text" placeholder="type a reply" className='replyinput' value={reply} onChange={handleReplyChange} style={{marginLeft:"7px"}} />
             </div>
             
             <div className='replyseconddiv'>
             <button onClick={closeFunc} className='cancelbtn'>Cancel</button>
              <button className='replybtn' onClick={submitReply}>
              {
                  loading ? (
                    <Spinner animation="border" variant="light" />
                  ):"Reply"
                }
              </button>
              
             </div>
              {/* </div> */}
              {/* <div className='smalldevicereplybtns'> */}
          
              {/* </div> */}
    </div>
  )
}

export default Replybox