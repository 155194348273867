import React from "react";
import { useContext } from "react";
import ResponsiveEmbed from "react-responsive-embed";
import { AuthContext } from "../../context/AllContext";

function UnstressToolBoxVideo() {
  const { isMobile } = useContext(AuthContext);

  return (
    <div className="py-5 textureback d-flex justify-content-center align-items-center flex-column">
      <h1 className="newpageheader">Silva Unstress ToolBox</h1>
      <p
        style={{
          fontWeight: "600",
          fontSize: "25px",
          color: "black",
          marginTop: "20px",
          textAlign: "center",
        }}
        className=""
      >
        Overcome the Biggest Threat to our Health with The Silva Unstress Toolbox{" "}
      </p>

      <div
        className="container mt-3"
        style={{
          maxWidth: isMobile ? "100%" : "50%",
        }}
      >
        <p
          style={{
            fontSize: isMobile ? "0.8rem" : "1rem",
            textAlign: "center",
            lineHeight: isMobile ? "30px" : "35px",
          }}
        >
          Chronic Stress is the "silent killer" of our health. So why tolerate
          its perpetual presence? Break free from the grips of stress and
          anxiety with Silva Unstress Toolbox and experience lasting relief,
          unwavering resilience, and inner bliss. Join this free masterclass to
          prioritize the most precious asset – your health.
        </p>
      </div>

      <div className="video-container22 mt-4">
        {/* <ResponsiveEmbed
                    ratio="16:9"
                    src="https://silva-method-official.b-cdn.net/Unstress%20Toolbox/Video%20sales%20letter%2C%20teachers.mp4"
                    allowFullScreen
                /> */}
        {/* <div style={{position:"relative",height:0,overflow:"hidden",maxWidth:"100%",paddingBottom:"56.25%"}}>
          <iframe
            src="https://silva-method-official.b-cdn.net/Unstress%20Toolbox/Video%20sales%20letter%2C%20teachers.mp4"
            style={{position:"absolute",top:0,left:0,width:"100%",height:"100%"}}
            frameborder="0"
            allowfullscreen
          ></iframe>
        </div> */}
         <video width={'100%'} height={'100%'} controls style={{borderRadius:'30px'}} controlsList="nodownload" poster="/img/dragan_video_thumbnail.png">
            <source src="https://silva-method-official.b-cdn.net/Unstress%20Toolbox/Video%20sales%20letter%2C%20teachers.mp4" type="video/mp4" />
          </video>
      </div>

      {/* content to exit */}
    </div>
  );
}

export default UnstressToolBoxVideo;



// import React from "react";
// import { useContext } from "react";
// import ResponsiveEmbed from "react-responsive-embed";
// import { AuthContext } from "../../context/AllContext";
// import { Col, Row } from "react-bootstrap";

// function UnstressToolBoxVideo() {
//   const { isMobile,isDesktopOrLaptop } = useContext(AuthContext);

//   return (
    
//     <div className="p-5">
//       <Row>
//         <Col sm={12} md={12} lg={isDesktopOrLaptop?6:4} className="d-flex align-items-center">
//           <div className="p-3 text-start">
//             <h1 className="newpageheader">Silva Unstress ToolBox</h1>
//             <p
//               style={{
//                 fontWeight: "600",
//                 fontSize: "20px",
//                 color: "black",
//                 textAlign: "center",
//               }}
//               className="my-5"
//             >
//               Overcome the Biggest Threat to our Health with The Unstress
//               Toolbox{" "}
//             </p>
//             <p
//               style={{
//                 fontSize: isMobile ? "0.8rem" : "1rem",
//                 textAlign: "start",
//                 lineHeight: isMobile ? "30px" : "35px",
//                 marginTop:'30px',
//               }}
//             >
//               Chronic Stress is the "silent killer" of our health. So why
//               tolerate its perpetual presence? Break free from the grips of
//               stress and anxiety with Unstress Toolbox and experience lasting
//               relief, unwavering resilience, and inner bliss. Join this free
//               masterclass to prioritize the most precious asset – your health.
//             </p>
//           </div>
//         </Col>
//         <Col sm={12} md={12} lg={isDesktopOrLaptop?6:8} className="d-flex align-items-center">
//           <div>
//             <video
//               width={"100%"}
//               height={"100%"}
//               controls
//               style={{
//                 borderRadius: "30px",
//                 boxShadow: "0 0 0 1px rgba(0, 0, 0, 0.12)",
//               }}
//               controlsList="nodownload"
//             >
//               <source
//                 src="https://silva-method-official.b-cdn.net/Unstress%20Toolbox/Video%20sales%20letter%2C%20teachers.mp4"
//                 type="video/mp4"
//               />
//             </video>
//           </div>
//         </Col>
//       </Row>
//     </div>
//   );
// }

// export default UnstressToolBoxVideo;

