import React, { useEffect } from 'react'
// import Slider1 from './Slider1'
// import Slider2 from './Slider2'
import HomeHeader from '../Home/Home/HomeHeader/HomeHeader'
import "./newlandingpage.css"

import HomePageHeroSection from './HomePageHeroSection'
import CustomFooter from '../Home/Home/HomeFooter/Footer'
// import Featured2 from '../LandingPages/EmotionalIntelligence/Featured2'
// import WhatIsSilvaMethodNew from './WhatIsSilvaMethodNew'
// import VideoSectionNew from './VideoSectionNew'
// import LiveBenefitsSection from './LiveBenefitsSection'
// import HomeHeading from '../Home/Home/HomeHeading'
// import HomeEvents from './HomeEvents'
import Featured from '../../components/featured/Featured'
import HeadingNew from '../AboutUs/HeadingNew'
import WhatIsSilvaMethodNew from './WhatIsSilvaMethodNew'
import VideoSectionNew from './VideoSectionNew'
import Slider2 from './Slider2'
import Slider1 from './Slider1'
import LiveBenefitsSection from './LiveBenefitsSection'
import HomeEvents from './HomeEvents'
import ComboImageSection from '../CoursePage/ComboImageSection'
import BreakThroughs from '../silvamembership/BreakThroughs'
import ComboTestimonials from '../LandingPages/SilvaComboCourse/ComboTestimonials'
import AboutSilvaMethod from './AboutSilvaMethod'
import TestimonialSliderCombo from '../LandingPages/ComboCourse2/TestimonialSliderCombo'
import BannerNewHome from './BannerNewHome'
import BecomeMember from '../Home/Home/BecomeMember'
import Newsletter from '../../components/Newsletter/Newsletter'
import MobileSection from './MobileSection'
import { polyfill, scrollIntoView } from 'seamless-scroll-polyfill';
import { useState } from 'react'
import SaleModal from '../Home/Home/Homestudycourses/SaleModal'
import { useLocation } from 'react-router-dom'
import BannerDownload from './BannerDownload'
import { useMediaQuery } from 'react-responsive'


function NewLandingPage() {

  // const [isMouseHovered,setIsMouseHovered] = useState(false);

  const [show, setShow] = useState(false);

  const location = useLocation()

  const token = location?.state?.token;
  const searchParams = new URLSearchParams(location.search);
  const tokenParam = searchParams.get('token');




  const onHide = () => {
    setShow(false)
  }

  const handleShow = () => {
    setShow(true)
  };



  const scrollTo = () => {
    polyfill()
    const doc = document.querySelector(".newpaymentcard");
    setTimeout(() => {

      scrollIntoView(doc, {
        behavior: "smooth",
        block: "end", inline: "nearest"
      });
    }, 300)
  }






  useEffect(()=>{
    if(!token && !tokenParam){
       setTimeout(()=>{
        handleShow()
       },3000)
    }else{
      setShow(false)
    }

  },[token,tokenParam])


  
  return (
    <>
      <div>

        <HomeHeader />
        <HomePageHeroSection />
        <div style={{
          background: "whitesmoke",
          paddingTop: "10px",
        }}>
          <Featured />

        </div>
        <BannerDownload />
        <WhatIsSilvaMethodNew scrollTo={scrollTo} />
        <VideoSectionNew />
        <Slider1 scrollTo={scrollTo} />
        <Slider2 scrollTo={scrollTo} />
        <LiveBenefitsSection scrollTo={scrollTo} />
        <HomeEvents scrollTo={scrollTo} />
        <ComboImageSection scrollTo={scrollTo} />
        <BreakThroughs scrollTo={scrollTo} />
        <ComboTestimonials scrollTo={scrollTo} />
        <AboutSilvaMethod scrollTo={scrollTo} />
        <MobileSection scrollTo={scrollTo} />
        <TestimonialSliderCombo scrollTo={scrollTo} />
        <BannerNewHome scrollTo={scrollTo} />
        <BecomeMember scrollTo={scrollTo} />
        <Newsletter scrollTo={scrollTo} />
        {/* <LiveBenefitsSection />
        <HomeEvents /> */}
      </div>
      {/* <ComboImageSection />
        <BreakThroughs />
        <ComboTestimonials />
        <AboutSilvaMethod />
        <TestimonialSliderCombo />
        <BannerNewHome />

        <BecomeMember />
        <MobileSection />
      <Newsletter /> */}
      <CustomFooter />
      <SaleModal show={show} onHide={onHide} handleShow={handleShow} scrollTo={scrollTo} />
    </>

  )
}

export default NewLandingPage