import React from "react";
import { Badge, Card, Col, ProgressBar, Row, Spinner } from "react-bootstrap";
import HeadingNew from "../AboutUs/HeadingNew";
import ParaComp from "../SilvaManifestationProgram/ParaComp";
import "./singlecourse.css";
import ReactStars from "react-stars";
import { useContext } from "react";
import { AuthContext } from "../../context/AllContext";
import PaymentCard from "./PaymentCard";
import HeadingNew34 from "./HeadingNew34";
import { GiStairsGoal } from "react-icons/gi";
import { Link, useParams } from "react-router-dom";
import NewCoursePaymentCard from "./NewCoursePaymentCard";

function CourseHeroSection({
  data,
  isCurrentCoursePurchased,
  lastCourse,
  courseLevel,
  getCourses
}) {
  const params = useParams();
  const { isMobile, isTablet, userData } = useContext(AuthContext);
  const handleScroll = () => {
    const descard = document.querySelector(".emptyhr");

    if (descard) {
      descard.scrollIntoView({
        behavior: "smooth",
      });
    }

    // else {
    //     document.querySelector(".commentsection")?.scrollIntoView({
    //         behavior: "smooth"
    //     })
    // }

    const courseCardElement = document.querySelector(".coursecardbodymain");

    // Set the box shadow using the style property
    courseCardElement.style.boxShadow = "10px 10px 5px 0px rgba(0,0,0,0.48)";
  };

  // console.log(data,'no')

  // console.log(courseStatus,'cst')

  return (
    <div
      className={
        data?.is_combo_course === "No"
          ? isCurrentCoursePurchased
            ? "homestudybgnew coursepurchaseddiv"
            : "homestudybgnew"
          : isCurrentCoursePurchased
            ? "homestudybgnew homestudybgnew2 coursepurchaseddiv"
            : "homestudybgnew homestudybgnew2"
      }
    >
      <div className="container">
        <Row className="justify-content-start align-items-center">
          {!isCurrentCoursePurchased && (
            <Col xs={12} lg={7}>
              <div className="container-fluid">
                <HeadingNew34
                  title={data?.course_title || ""}
                  start={true}
                  white
                />
               { data?.course_id !=='combo-plan' && <h6 className="white-color mt-3">
                  <span style={{ marginRight: "10px" }}>
                    <GiStairsGoal color="#fff" size={30} />
                  </span>
                  {courseLevel}
                </h6>}

                {/* {data?.is_combo_course === "No" ? ( */}
                <ParaComp
                  white
                  center={false}
                  data={[
                    data?.banner_description ||
                    "Overcome challenges , and embrace a life of abundance and  fulfillment. Join the Silva method community and unlock a world of possibilities today",
                  ]}
                />
                {/* ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.banner_description,
                    }}
                  ></div>
                )} */}
                {data?.course_id === 'begineer-combo-course' &&
                  <p className={'white-color'} style={{ fontSize: isTablet ? "1.2rem" : "1rem", fontWeight: "bold" }}>
                    {'Courses Included:'}
                    <div style={{display:"flex",gap:"25px"}}>
                      <p className={'white-color'} style={{ fontSize: isTablet ? "1.2rem" : "1rem", }}>☑️Silva Life System</p>
                      <p className={'white-color'} style={{ fontSize: isTablet ? "1.2rem" : "1rem", }}>☑️Silva Intuition System</p>
                    </div>
                    <div style={{display:"flex",gap:"25px"}}>
                    <p className={'white-color'} style={{ fontSize: isTablet ? "1.2rem" : "1rem", }}>☑️Silva Alpha Sound</p>
                    <p className={'white-color'} style={{ fontSize: isTablet ? "1.2rem" : "1rem", }}>☑️Silva Theta Sound</p>
                    </div>
                  </p>
                }

                {data?.course_id === 'advanced-combo-course' &&
                  <p className={'white-color'} style={{ fontSize: isTablet ? "1.2rem" : "1rem", fontWeight: "bold" }}>
                    {'Courses Included:'}
                    <div style={{display:"flex",gap:"25px"}}>
                      <p className={'white-color'} style={{ fontSize: isTablet ? "1.2rem" : "1rem", }}>☑️Silva Manifesting System</p>
                      <p className={'white-color'} style={{ fontSize: isTablet ? "1.2rem" : "1rem", }}>☑️Silva Mind-Body Healing Program:</p>
                    </div>
                    <div style={{display:"flex",gap:"25px"}}>
                    <p className={'white-color'} style={{ fontSize: isTablet ? "1.2rem" : "1rem", }}>☑️Silva Alpha Sound</p>
                    <p className={'white-color'} style={{ fontSize: isTablet ? "1.2rem" : "1rem", }}>☑️Silva Theta Sound</p>
                    </div>
                  </p>
                }
                <div className="ratingwithbox">
                  {/* {data?.is_combo_course === "No" && ( */}
                  <div className="singlecourselessonsbox">
                    <div className="lessons">
                      <h4>{data?.course_id === 'combo-plan'? '50+' : data?.total_chapters}</h4>
                      <span>
                        {(data?.course_id === 'combo-plan') ? "Chapters" : (data?.total_chapters < 2 ) ? "Chapter" : "Chapters"}
                      </span>
                    </div>
                    <span className="separator"></span>
                    <div className="chapters">
                      <h4>{data?.course_id === 'combo-plan' ? '205+': data?.total_lessons}</h4>
                      <span>
                        {data?.course_id === 'combo-plan'  ? "Lessons" : (data?.total_lessons < 2) ? "Lesson" : "Lessons"}
                      </span>
                    </div>
                  </div>
                  {/* )} */}
                  <div style={{ fontSize: "30px" }} className="ratingbox">
                    <h5 className="mt-1">
                      <Badge bg="light" text="dark">
                        {data?.course_id === "unstress-toolbox"
                          ? "Newly Launched"
                          : "BestSeller"}
                      </Badge>
                    </h5>
                    <h4 style={{ color: "#ffd700" }} className="ratingheading">
                      {data?.course_rating}
                    </h4>
                    <ReactStars
                      count={5}
                      value={data?.course_rating}
                      edit={false}
                    />
                    <h5 className="white-color mt-1">Ratings</h5>

                    {Number(data?.how_many_students) > 0 && (
                      <p className="ratingnumber">
                        ({data?.how_many_students} Students Enrolled)
                      </p>
                    )}
                  </div>
                </div>
                {!isCurrentCoursePurchased && !isMobile && (
                  <button
                    onClick={handleScroll}
                    className="btn btn-warning mt-1 mb-3 btn-lg"
                  >
                    Enroll Now
                  </button>
                )}
              </div>

              {userData &&
                userData.enrollment_status === "Enrolled" &&
                lastCourse &&
                (lastCourse.length === 0 ||
                  params.id !== lastCourse.course_id) &&
                (data?.course_id !== "combo-plan")(
                  <Link
                    to={`/store/course/${data && data?.course_id}/${data && data?.chapters[0]?.chapter_id
                      }/${data && data?.chapters[0]?.lession[0]?.lesson_id}`}
                  >
                    <button className="primary_btn2 mt-4 mx-2">
                      Start Program
                    </button>
                  </Link>
                )}

              {lastCourse &&
                Object.keys(lastCourse).length > 0 &&
                params.id === lastCourse.course_id &&
                data?.is_combo_course === "No" &&
                isCurrentCoursePurchased && (
                  <Link
                    to={`/store/course/${lastCourse.course_id}/${lastCourse.chapter_id}/${lastCourse.lesson_id}`}
                  >
                    <button
                      className="primary_btn2 mt-4 mx-2"
                      style={{
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                        fontWeight: "600",
                      }}
                    >
                      <b>
                        Continue from {lastCourse.lesson_id.replace(/-/g, " ")}
                      </b>
                    </button>
                  </Link>
                )}

              <div className="d-flex justify-content-center align-items-center mt-3">
                {!isCurrentCoursePurchased && (isMobile || isTablet) && (
                  <PaymentCard
                    datanew={data}
                    isCurrentCoursePurchased={isCurrentCoursePurchased}
                  />
                )}
              </div>
            </Col>
          )}
        </Row>

        <Row className="justify-content-center align-items-center">
          {isCurrentCoursePurchased && (
            <Col xs={12} lg={9}>
              <div className="container-fluid">
                <HeadingNew
                  title={data?.course_title || ""}
                  start={true}
                  white
                />
                <ParaComp
                  white
                  center={false}
                  data={[
                    "Overcome challenges, and embrace a life of abundance and  fulfillment. Join The Silva Method community and unlock a world of possibilities today",
                  ]}
                />

                <div className="ratingwithbox">
                  {data?.is_combo_course === "No" && (
                    <div className="singlecourselessonsbox">
                      <div className="lessons">
                        <h4>{data?.total_chapters}</h4>
                        <span>Chapters</span>
                      </div>
                      <span className="separator"></span>
                      <div className="chapters">
                        <h4>{data?.total_lessons}</h4>
                        <span>Lessons</span>
                      </div>
                    </div>
                  )}

                  <div style={{ fontSize: "30px" }} className="ratingbox">
                    <h5 className="mt-1">
                      <Badge bg="light" text="dark">
                        BestSeller
                      </Badge>
                    </h5>
                    <h4 style={{ color: "#ffd700" }} className="ratingheading">
                      {data?.course_rating}
                    </h4>
                    <ReactStars
                      count={5}
                      value={data?.course_rating}
                      edit={false}
                    />
                    <h5 className="white-color mt-1">Ratings</h5>
                    {Number(data?.course_total_no_of_rating) > 0 && (
                      <p className="ratingnumber">
                        ({data?.course_total_no_of_rating} Students Enrolled)
                      </p>
                    )}
                  </div>
                </div>
              </div>

              {userData &&
                userData.enrollment_status === "Enrolled" &&
                lastCourse &&
                (lastCourse.length === 0 ||
                  params.id !== lastCourse.course_id) &&
                data?.is_combo_course === "No" && (
                  <Link
                    to={`/store/course/${data && data?.course_id}/${data && data?.chapters[0]?.chapter_id
                      }/${data && data?.chapters[0]?.lession[0]?.lesson_id}`}
                  >
                    <button className="primary_btn2 mt-4 mx-2">
                      Start Program
                    </button>
                  </Link>
                )}

              {lastCourse &&
                Object.keys(lastCourse).length > 0 &&
                params.id === lastCourse.course_id &&
                data?.is_combo_course === "No" && (
                  <Link
                    to={`/store/course/${lastCourse.course_id}/${lastCourse.chapter_id}/${lastCourse.lesson_id}`}
                  >
                    <button
                      className="primary_btn2 mt-4 mx-2"
                      style={{
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                        fontWeight: "600",
                      }}
                    >
                      <b>
                        Continue from {lastCourse.lesson_id.replace(/-/g, " ")}
                      </b>
                    </button>
                  </Link>
                )}

              <div className="d-flex justify-content-center align-items-center mt-3">
                {!isCurrentCoursePurchased && (isMobile || isTablet) && (
                  <PaymentCard
                    datanew={data}
                    isCurrentCoursePurchased={isCurrentCoursePurchased}
                  />
                )}
              </div>
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
}

export default CourseHeroSection;
