import React, { useContext, useState } from 'react'
import { requestData } from '../../../utils/baseUrl';
import { useEffect } from 'react';
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import Slider from 'react-slick';
import PriceBoxRem from './PriceBoxRem';
import { AuthContext } from '../../../context/AllContext';
import { useParams } from 'react-router-dom';

function PaymentCard2() {

    const {id} = useParams()   
    const { userData,isTablet } = useContext(AuthContext);

    const [courseDetails, setCourseDetails] = useState();
    const [filteredData, setFilteredData] = useState(null)

    async function fetchCourse() {
        const res = await requestData("latestCourseList", "POST", { start_index: 0, no_of_records: 20 });
        if (res && res.error === false) {

            setCourseDetails(res.data.filter((cc,i)=>cc.course_id!=='silva-method-complete-course-with-live-interactive-program').sort((a, b) => a.precedence - b.precedence))
            // setPricePlan(Math.floor((res.data[0].price) - (((res.data[0].discount_percentage || 0) * parseInt(res.data[0].price)) / 100)))
        }
    }

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 5000,
        prevArrow: <BsArrowLeft />,
        nextArrow: <BsArrowRight />,
    };

    useEffect(() => {
        fetchCourse()
    }, [])









    // const renderer = ({ days, hours, minutes, seconds, completed }) => {
    //     if (completed) {
    //         // Render a completed state
    //         return <h2>Completed</h2>;
    //     } else {
    //         // Render a countdown
    //         return (
    //             <div className='d-flex justify-content-start align-items-center gap-3 paymentcardsss'>
    //                 <div className='d-flex justify-content-center align-items-center flex-column gap-2'>
    //                     <button className='newbtn'>
    //                         {days < 10 ? "0 " + days : days}
    //                     </button>
    //                     <h6 className='text-center text-dark'>Days</h6>
    //                 </div>

    //                 <div className='d-flex justify-content-center align-items-center flex-column gap-2'>
    //                     <button className='newbtn'>
    //                         {hours < 10 ? "0 " + hours : hours}
    //                     </button>
    //                     <h6 className='text-center text-dark'>Hours</h6>
    //                 </div>

    //                 <div className='d-flex justify-content-center align-items-center flex-column gap-2'>
    //                     <button className='newbtn'>
    //                         {minutes < 10 ? "0 " + minutes : minutes}
    //                     </button>
    //                     <h6 className='text-center text-dark'>Minutes</h6>
    //                 </div>


    //                 <div className='d-flex justify-content-center align-items-center flex-column gap-2'>
    //                     <button className='newbtn'>
    //                         {seconds < 10 ? "0 " + seconds : seconds}
    //                     </button>
    //                     <h6 className='text-center text-dark'>Seconds</h6>
    //                 </div>
    //             </div>
    //         );
    //     }
    // };


    useEffect(() => {


        if(courseDetails){
            if (userData) {
                if (userData.enrolled_course && userData.enrolled_course.length > 0 && userData.enrolled_course.length !== Number(userData.total_course)) {
                    const enrolled = userData.enrolled_course.map((val) => val.course_id);
                    const filtered = courseDetails.filter((co, i) => !enrolled.includes(co.course_id));
                    setFilteredData(filtered)
                }
            }else{
                const filtered = courseDetails.filter((co,i)=>co.course_id!==id);
                setFilteredData(filtered)
            }
        }

        

    }, [userData, courseDetails])


    return (
        <div className='container paymentcardslider' style={{marginTop:isTablet?"50px":"0px"}}>
            {
                (courseDetails && !filteredData) && (
                    <Slider {...settings}>
                        {courseDetails && courseDetails.map((course, i) => (
                            <PriceBoxRem course={course} />
                        ))}
                    </Slider>
                )
            }

            {
                (filteredData) && (
                    <Slider {...settings}>
                        {filteredData && filteredData.map((course, i) => (
                            <PriceBoxRem course={course} />
                        ))}
                    </Slider>
                )
            }



        </div>

    )
}

export default PaymentCard2