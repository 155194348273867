import React, { useContext } from "react";
import HeadingNew34 from "../../CoursePage/HeadingNew34";
import { Card, Col, Row } from "react-bootstrap";
import { AuthContext } from "../../../context/AllContext";
import { FaCheckCircle } from "react-icons/fa";
import HeadingNew from "../../AboutUs/HeadingNew";

function AddOn() {
  const { isDesktopOrLaptop, isBigScreen, isTablet, } = useContext(AuthContext);

  const addOns = [
    "Entry into our thriving community, fostering connections with like-minded individuals dedicated to the Silva Method.",
    "Enjoy a lifetime of learning with unrestricted access to our comprehensive Home Study Program & Live interactive programs.",
    "Stay at the forefront of personal development with direct access to the newly upgraded courses.",
    "Avail recorded guided meditations addressing specific problem areas to practice in your own space.",
  ];

  return (
    <div className="parallexnew23 py-0 pb-5">
      <div className="d-flex justify-content-center align-items-center pt-5">
        <div>
          {/* <HeadingNew34 title={"Add on related title"} /> */}
          {/* <h2
            style={{
              color: "#2b2f39",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            
          </h2> */}
          <HeadingNew title={"BONUS Included when You Enroll in this Program"} />
          {/* <h2
            style={{
              fontWeight: "800",
              fontSize: isBigScreen ? "40px" : isDesktopOrLaptop ? "40px" : isTablet ? "30px" : "20px",
              margin: "0 0 0 20px",
              textAlign: "center",
              marginTop: "10px",
              color: "#000"
            }}
          >

           
          </h2> */}

          {/* <h5 className="text-center" style={{ color: "#2b2f39" }}>
            Lorem Ipsum is simply dummy text of the printing .
          </h5> */}
        </div>
      </div>
      <div className="container mt-4">
        <Row className="d-flex justify-content-center align-items-center px-3">
          {addOns?.map((el, i) => (
            <Col sm={11} md={11} lg={5} className="mx-2">
              <Card
                className="w-100 p-3 text-center d-flex justify-content-center align-items-center cardbghover"
                style={{ borderRadius: "15px" }}
              >
                <Row>
                  <Col sm={12} md={2} lg={2}>
                    <div className="mt-0 mt-md-3">
                      <FaCheckCircle color="#2ecc71" size={30} />
                    </div>
                  </Col>
                  <Col sm={12} md={10} lg={10}>
                    <div className="text-start px-2">
                      <p className="parawhitenew">{el}</p>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}

export default AddOn;
