import React, { useContext, useEffect, useState } from 'react'
import { Card, ProgressBar } from 'react-bootstrap'
import { requestData4 } from '../../../../utils/baseUrl';
import { useMediaQuery } from 'react-responsive';
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import { Link } from 'react-router-dom';
import MoonLoader from "react-spinners/MoonLoader";
import LoggedInHeroSection2 from './LoginHeroSection2';
import { AuthContext } from '../../../../context/AllContext';


function SilvaCourseCards() {
    const { userData, isMobile } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [allCourses, setAllCourses] = useState([]);


    // const fetchAllCourses = async () => {
    //     setLoading(true);

    //     const res = await requestData4("courseListWithChild", "POST", {}, userData.student_id);
    //     setLoading(false);
    //     if (!res) {
    //         return false;
    //     }


    //     const enrolledCourses = [...userData.enrolled_course]

    //     const enrolledCoursesMapped = enrolledCourses.map((val) => val.course_id).filter((el, i) => el.course_id !== 'top-mental-training')



    //     const filteredArr = res.data.filter((val, i) => enrolledCoursesMapped.includes(val.course_id))


    //     let courses = []
    //     for (let course of filteredArr) {
    //         if (course.course_id !== "top-mental-training") {
    //             let allLessonsInCurrentCourse = [];
    //             course.chapters.forEach((chapter, i) => {
    //                 const lessionsInSingleChapter = chapter.lession;
    //                 allLessonsInCurrentCourse = [...allLessonsInCurrentCourse, ...lessionsInSingleChapter]
    //             })
    //             let numofcompleted = 0;
    //             let hasStarted = false;
    //             let isCourseCompleted = true
    //             allLessonsInCurrentCourse.forEach((ls, i) => {
    //                 // console.log(ls,"lslsls",course.course_title)
    //                 if (!ls.lesson_activity_status || ls.lesson_activity_status === "Started") {
    //                     isCourseCompleted = false
    //                 }
    //                 if (ls.lesson_activity_status) {
    //                     hasStarted = true
    //                 }
    //                 if (ls.lesson_activity_status === "Completed" && ls.lesson_activity_duration) {
    //                     // console.log("started")
    //                     hasStarted = true
    //                     ++numofcompleted
    //                 }
    //             })



    //             courses.push({
    //                 courseData: course,
    //                 isCourseStarted: isCourseCompleted ? false : hasStarted,
    //                 isCourseCompleted,
    //                 numofcompleted,
    //                 isCourseCompleted
    //             })
    //         }
    //         // let isCourseStarted=false;
    //         // let isCourseHasNotStarted = false;

    //     }

    //     const coursesNotStarted = courses.filter((cc, i) => cc.isCourseStarted === false).filter((el, i) => el.courseData.course_id !== 'top-mental-training');

    //     const coursesStarted = courses.filter((cc, i) => cc.isCourseStarted === true).filter((el, i) => el.courseData.course_id !== 'top-mental-training');

    //     // const complete = courses.filter((cc,i)=>cc.isCourseCompleted===true)
    //     setStartedCourses(coursesStarted);
    //     setnotStartedCourses(coursesNotStarted);
    //     setAllCourses(courses);
    //     // setCompletedCourses(complete)


    //     // console.log(courses, "courses")
    //     // setAllCourses(res.data);
    // }

    // useEffect(() => {
    //     // setTimeout(()=>{
    //     //     fetchAllCourses()
    //     // },5000)
    //     if (userData) {
    //         fetchAllCourses()
    //     }
    // }, [userData])



    const getAllCourses = async () => {
        setLoading(true);
        const res = await requestData4("latestCourseList", "POST", {
            "start_index": "0",
            "no_of_records": "20"
        });
        setLoading(false);
        const enrolledCourses = [...userData.enrolled_course];
        const enrolledCoursesMapped = enrolledCourses.map((val) => val.course_id).filter((el, i) => el.course_id !== 'top-mental-training');
        const filteredArr = res.data.filter((val, i) => enrolledCoursesMapped.includes(val.course_id) && val.is_combo_course === "No").reverse();
        setAllCourses(filteredArr);
        console.log(filteredArr,"filteredArr",userData.enrolled_course)
    };

    useEffect(() => {
        getAllCourses();
    }, [userData])




    if (loading) {
        return (
            <div style={{ height: "100%" }}>
                <div className='d-flex justify-content-center align-content-center align-items-center'>
                    <MoonLoader
                        color='black'
                        size={150}
                    />
                </div>
            </div>
        )
    }

    return (
        <div className={`mb-5 mt-5 container`}>



            {/* {
                userData && userData.enrollment_status === "Enrolled" && (startedCourses && startedCourses.length > 0) && (
                    <div className="coursesslider">
                        <LoggedInHeroSection2 text={"Ongoing Courses"} />

                        <div className="productcardsection5">

                            {
                                startedCourses.map((sc, i) => (


                                    <Link to={`/store/course/${sc.courseData.course_id}/`}>
                                        <Card className="text-start cardbody" style={{
                                            borderRadius: "20px",
                                            marginTop: "20px",
                                            width: isMobile ? "95%" : "300px",
                                            height: isMobile ? "100%" : "auto",
                                            marginLeft: isMobile ? "10px" : "0px"
                                        }}>
                                            <Card.Header className='bg-transparent'
                                                style={{ borderBottom: "none", paddingTop: "20px", fontSize: "1rem" }}
                                            >
                                                <div className='d-flex justify-content-start flex-column align-items-center gap-3'>
                                                    <div className=''>
                                                        <h6 className='text-dark'>
                                                            {sc.courseData.course_title}
                                                        </h6>
                                                        <p>
                                                            {sc.numofcompleted} of {sc.courseData.total_lessons} Lessons Completed
                                                        </p>

                                                        <ProgressBar variant="warning" now={Math.floor((parseInt(sc.numofcompleted) / sc.courseData.total_lessons) * 100)} />

                                                    </div>
                                                </div>
                                            </Card.Header>
                                            <Card.Img src={sc.courseData.web_image} style={{ width: "100%" }} />

                                            <Card.Footer className="bg-transparent d-flex justify-content-between gap-2">
                                                <span style={{ color: "black", fontWeight: "400" }}>
                                                    Total Chapters: {sc.courseData.total_chapters}
                                                </span>
                                                <span style={{ color: "black", fontWeight: "400" }}>
                                                    Total Lessons: {sc.courseData.total_lessons}
                                                </span>

                                            </Card.Footer>
                                        </Card>
                                    </Link>


                                ))
                            }
                        </div>

                    </div>
                )
            } */}



            {
                (allCourses && allCourses.length >= 1) && (
                    <div
                        className='coursesslider'
                    >
                        <LoggedInHeroSection2 text={"Your Purchased Course/s"} />


                        <div className='productcardsection2'>
                            {
                                allCourses.map((course, i) => (

                                    <Link to={`/store/course/${course?.course_id}/`}>
                                        {/* <Card className="text-start cardbody cardnewbody" style={{
                                            borderRadius: "20px", width: "300px", height: "auto",
                                           
                                        }}>
                                           
                                            <Card.Body className='d-flex justify-content-center align-items-center flex-column'>
                                                <Card.Img
                                                    src={course?.web_image}
                                                    style={{ width: "100%" }} />
                                                <div className='d-flex justify-content-between align-items-center' style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                                                    <div className='d-flex flex-column'>
                                                        <Card.Text className='text-dark'>
                                                            <h6 className='text-dark'>
                                                                {course?.course_title ? course?.course_title?.length <= 40 ? course?.course_title : course?.course_title.substring(0, 40) + "..." : ""}
                                                            </h6>
                                                        </Card.Text>
                                                    </div>

                                                </div>
                                            </Card.Body>
                                        </Card> */}
                                        <Card className='productsinglecard boxshadowclass' key={i} style={{
                                            backgroundImage: `url(${course?.web_vertical_image || "https://silvamethod.com/manage/assets/uploads/noimg.jpg"})`
                                        }}
                                        >
                                            <Card.Body>

                                                <div className='lastfourthcardsection'>
                                                    <h4 className='lastfourthcardheader'>
                                                        {
                                                            course?.course_title
                                                        }
                                                    </h4>
                                                    {
                                                        course?.total_chapters && course?.total_lessons ? (
                                                            <h5 className='lastfourthcardheader2'>
                                                                {course?.total_chapters} {course?.total_chapters < 2 ? "Chapter" : "Chapters"} | {course?.total_lessons} {course?.total_lessons < 2 ? "Lesson" : "Lessons"}
                                                            </h5>
                                                        ) : (
                                                            <h5 className='lastfourthcardheader2'>
                                                                48 Chapters | 238 Lessons
                                                            </h5>
                                                        )
                                                    }
                                                    <div className='lastfourthsection2'>
                                                        <button className='herodiv-becomememberbtn3'> View Course</button>
                                                        <span className='fourthsectionspan'>
                                                            Life Time Access
                                                        </span>

                                                    </div>

                                                </div>
                                            </Card.Body>

                                        </Card>
                                    </Link>



                                ))
                            }

                        </div>

                    </div>
                )
            }

        </div>
    )
}

export default SilvaCourseCards