import React, { useContext } from 'react'
import HeadingNew from '../AboutUs/HeadingNew'
import { Col, Row } from 'react-bootstrap'
import ParaComp from '../SilvaManifestationProgram/ParaComp'
import { IoMdCheckbox, IoMdCheckmarkCircle } from "react-icons/io";
import { AuthContext } from '../../context/AllContext';
import { useMediaQuery } from 'react-responsive';

function LiveBenefitsSection({ scrollTo }) {
    const { isMobile, isDesktopOrLaptop, isTablet } = useContext(AuthContext);
    const isNewDesktop = useMediaQuery({ minWidth: 1023, maxWidth: 1365 })

    const data = [
        "Worth $2400 - Now For Free!",
        "Life Time Access",
        "Trusted by 1M+ Silva Enthusiasts!"
    ]


    const data2 = [
        "4 Weekly Live Classes for Ongoing Growth",
        "Real-Time Support from Certified Instructors",
        " Join a community of 1M+ Silva Enthusiasts",
        "A Unique Learning Environment"

    ]
    return (
        <div className='container' style={{
            marginTop: isMobile ? "50px" : "100px",
            marginBottom: isMobile ? "40px" : "70px"
        }}>
            <Row className='justify-content-center align-items-center'>
                <Col xs={12} xl={5}>
                    <HeadingNew title={"Experience "} start={!isMobile} colored={"Silva"} />
                    <HeadingNew small title={"Like Never Before with Weekly Live Interactive Classes!"} start={!isMobile} />

                    {
                        (isMobile || isNewDesktop || isTablet) && (
                            // <img
                            //     src='/img/bg/bghero/all-device-img-3.webp'
                            //     style={{
                            //         borderRadius: "15px",

                            //     }}
                            //     className='my-4'
                            // />
                            <div className='d-flex justify-content-center align-items-center'>
                                <video
                                    src='/videos/Web-Video.mp4'
                                    poster='/img/bg/bghero/Image20240823121413.jpg'
                                    style={{
                                        width: isMobile ? "95%" : "100%",
                                        padding: "0px",
                                        margin: "0px",
                                        height: "100%",
                                        borderRadius: "15px"

                                    }}
                                    className='my-4'
                                    autoPlay
                                    loop
                                    muted
                                    controls
                                />
                            </div>


                        )
                    }


                    <div className='d-flex justify-content-center align-items-start flex-column flex-wrap gap-2 mt-3' style={{
                        marginLeft: isMobile ? "15px" : "0px"
                    }}>
                        {
                            data.map((val, i) => (
                                <div className='d-flex justify-content-start align-items-center gap-2'>

                                    <span>
                                        <IoMdCheckmarkCircle color='green' size={25} />
                                    </span>


                                    <h5 style={{
                                        color: "rgb(15, 19, 27)",
                                        fontWeight: "600",
                                        margin: "0px",
                                        padding: "0px",
                                    }}>
                                        {
                                            val
                                        }
                                    </h5>
                                </div>
                            ))
                        }

                    </div>

                    <div className='mt-3' style={{
                        paddingRight: "80px",
                        marginLeft: isMobile ? "15px" : "0px"
                    }}>
                        <ParaComp
                            data={[
                                <h5 className="mt-3 mb-3 text-start" style={{
                                    lineHeight: "1.5rem",
                                    fontSize: isMobile ? "0.9rem" : "1rem",

                                    color: "#000"
                                }}>
                                    Step into a new era of personal growth with Silva Method’s Weekly Live Sessions. Enjoy expert guidance from certified instructors, clear doubts, and connect with Silva enthusiasts worldwide—all at no extra cost. Join our vibrant WhatsApp community and tap into this incredible resource, free for life with the Silva Method Complete Program. Experience:
                                </h5>

                            ]}

                            center={false}
                            white={false}
                            margin={false}
                        />
                    </div>


                    <div className='d-flex justify-content-center align-items-start flex-column flex-wrap gap-2 mt-3 mb-4' style={{
                          marginLeft: isMobile ? "15px" : "0px"
                    }}>
                        {
                            data2.map((val, i) => (
                                <div className='d-flex justify-content-start align-items-center gap-2'>

                                    <span>
                                        <IoMdCheckmarkCircle color='green' size={25} />
                                    </span>
                                    <h5 style={{
                                        color: "rgb(15, 19, 27)",
                                        fontWeight: "600",
                                        margin: "0px",
                                        fontSize: "1rem",
                                        padding: "0px",
                                    }}>
                                        {
                                            val
                                        }
                                    </h5>
                                </div>
                            ))
                        }

                    </div>
                    <div className={isMobile ? "d-flex justify-content-center align-items-center" : "d-flex justify-content-start align-items-center"}>
                        <button className='cssbuttons-io-button3 mt-3 mb-4 ms-2' onClick={scrollTo}>
                            Become a Silva Member
                        </button>
                    </div>
                </Col>
                {

                    (!isMobile && !isNewDesktop && !isTablet) && (
                        <Col xs={12} xl={7}>
                            <div className='d-flex justify-content-center align-items-center'>
                                <video
                                    src='/videos/Web-Video.mp4'
                                    style={{
                                        width: "95%",
                                        padding: "0px",
                                        margin: "0px",
                                        height: "100%",
                                        borderRadius: "15px"
                                    }}
                                    className='my-4'
                                    autoPlay
                                    loop
                                    muted
                                    controls

                                />
                            </div>
                        </Col>
                    )
                }
            </Row>

        </div>
    )
}

export default LiveBenefitsSection