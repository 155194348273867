import React, { useState } from 'react'
import { Fade } from "react-awesome-reveal";
import Masonry from 'react-masonry-css';
import ReactHlsPlayer from 'react-hls-player';
import TestimonialCard from '../../newbooking/TestimonialCard';
import HeadingNew from '../../AboutUs/HeadingNew';
import ParaComp from '../../SilvaManifestationProgram/ParaComp';
import { useContext } from 'react';
import { AuthContext } from '../../../context/AllContext';
import { useNavigate } from 'react-router-dom';

function ComboTestimonials() {

    const navigate = useNavigate()

    const {isMobile}  = useContext(AuthContext)
    const videoTestiomonials = [


        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/e483775b-00be-4d08-98f3-f2cad0e4752b/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/7175f34c-af40-43ac-b565-44663239d18f/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/0ed3e928-7883-4357-8675-a46749627dbe/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/c2f5c60d-4126-422f-960d-b52055699057/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/3e4c2779-d471-4ee7-995c-9560369e0e20/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/113d1ae4-6646-48b8-94bc-b6e420e5308c/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/507fe8cf-f18f-4e38-92c1-bc7fddb91532/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/42a9baec-616b-4e75-9985-c4c441d58749/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/6b90eda0-48a8-448a-8b0b-9d4ab243c1f4/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/dfdedc9f-0ab2-40c5-80e7-1df5ceed009b/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/9ad6bb0e-e8b5-46da-a311-eb6f32d830f6/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/ffb3642c-9e31-4b3d-998a-2fee451f96bc/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/f85118d2-25b4-46f7-915d-3dc09fa2e56e/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/69b9fd6c-408c-49e2-9c83-1d8d38c7ce57/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/052a214f-5c9e-423f-bd18-755a89c5c223/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/98fcb9eb-122f-4e58-9817-981dbb48df05/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/d0bda075-45f7-4ba6-93af-80c5e998a887/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/576afb95-5ee9-4510-afb8-3d4be5ea7dbe/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/0e27e42e-7312-4c99-92a8-368463592ae5/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/13a54da2-19e2-4553-8e70-e8efed71d3da/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/3b7499db-9f57-47a0-9650-dc2e06b895f0/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/e7aa40e4-2149-4cc1-beff-962dcc6e7c48/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/5c3ca80f-2a6c-4199-b548-ea4c3f3d4f38/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/e0f17428-c008-4508-8940-d0d822f1fe16/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/9eae6aac-069a-4dd1-9dda-582c7029d5b3/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/34844380-9481-4ced-830d-576ddf4ce13b/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/b4f07226-4d11-4a79-b98a-923ad54d2178/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/23ae9d60-653e-424a-ac4f-89c7ece9db9f/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/42fc55b4-769e-4300-bf90-d8a769b7cf90/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/c5758fea-538a-43c8-bfd9-d2a55da1c37b/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/37a5dd15-b8cf-4e08-a095-8e13c35ca141/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/be2a5a1c-4e14-4144-b787-9bf333870074/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/b85e4528-0ba2-4b54-bd79-028020f90a64/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/a36f4a31-fa75-4b1f-8c8a-cc7280709bfd/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/dae84244-466c-41b8-8a14-be4b98034513/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/d5573927-cb7e-4339-b8da-b640222242b0/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/84e41eb1-17a1-4f2e-8e30-f17cf9c73add/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/7eb69234-1141-4dc2-ae9d-fa8b75487e24/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/e993e6fa-b421-4e4f-9ed5-d5f279eb443b/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/adb74194-c467-4775-a2c6-2bb62db476c3/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/a640c2a0-3d34-4979-8c78-38075709ac95/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/44dd2d77-9713-446d-8b1f-34be0758d35b/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/b6457194-5262-4a7f-b8f7-24d3e51bcb26/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/6ec68636-305a-4b15-aafa-16760f4ed1eb/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/857ca3c3-5969-4b6c-afa3-5b6f49da4cf7/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/f096fc4e-1346-4869-8579-3cd96652c911/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/bb3a7d49-b205-43a0-baa6-ccf57433a720/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/0c1eb7e6-0aa3-4154-9f87-4bc1329f5c30/playlist.m3u8",
            name: "",
            designation: "",
        },
        {
            type: "video",
            text: "",
            image: "",
            video:
                "https://vz-47e4ab86-ce6.b-cdn.net/293850cf-822f-4ca2-8c97-948bafaa4940/playlist.m3u8",
            name: "",
            designation: "",
        }
    ];


    const [showMore,setShowMore] = useState(false);



    const breakpointColumnsObj = {
        default: 3,
        1100: 2,
        700: 1,
    };


    return (
        <div className='pb-5' style={{
            marginTop:isMobile?"50px":"100px"
        }}>

            <div className="container">
            <div className='my-4 d-flex justify-content-center align-items-center flex-column gap-3'>
            <HeadingNew title={"Success Stories from Our "} colored={"Students"} />
            <div className='w-100 w-md-75 w-lg-75 d-flex justify-content-center align-items-center flex-column'>
            <div style={{
                    width: isMobile ? "95%" : "60%",
                    position: "relative"
                }}>

                   <h5 className="mb-3 text-center robotofont" style={{
                     lineHeight: isMobile?"1.3rem":"1.5rem",
                     fontSize:isMobile?"0.9rem":"1rem"
                 }}>
                                For over five decades, the Silva Method has impacted millions of lives worldwide, including celebrities, CEOs, and everyday individuals seeking personal breakthroughs. More than 12 million people in 110 countries have experienced what Silva Mind control meditation can do. Get inspired by our global community


                 </h5>

            </div>
            </div>
            </div>
                <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                >

                    {
                        showMore && (
                            videoTestiomonials.map((item, index) => (
                                <Fade duration={700}>
                                 <TestimonialCard key={index} data={item} />
                                </Fade>
                            ))
                        )
                    }
                    {
                        !showMore && (
                            videoTestiomonials.slice(0,isMobile?3:6).map((item, index) => (
                                <Fade duration={700}>
                                 <TestimonialCard key={index} data={item} />
                                </Fade>
                            ))
                        )

                    }

                </Masonry>
                <div className='d-flex justify-content-center align-items-center'>
                <button className='cssbuttons-io-button3' onClick={() => {
                    navigate("/success-stories")
                }}>{showMore ? "Show Less" : "Show More"}</button>
                </div>
            </div>
        </div>
    )
}

export default ComboTestimonials


// 


