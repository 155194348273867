import "../../../../App.css";
import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
// import video from "../../../../videos/silva-intro-video.mp4";
import { TypeAnimation } from 'react-type-animation';
// import mobileVideo from "../../../../videos/mobilehomevideo(375 X 634).mp4"
// import biggerMobileVideo from "../../../../videos/biggermobilehomevideo(425 X 634 ).mp4"

const TEXTS = ['Initiate', 'Transform', 'Flourish'];

function Homeherodiv() {
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1280px)' })

  const [index, setIndex] = useState(0);

  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const getVideoSrc = width => {
    // if (width >= 1024) return "https://silvabucket.s3.ap-south-1.amazonaws.com/Silva+Intro+Video.mp4";
    // if (width >= 424) return "https://silvabucket.s3.ap-south-1.amazonaws.com/silva-intro-video.mp4";
    return "https://silvabucket.s3.ap-south-1.amazonaws.com/Silva+Intro+Video.mp4";
  };

  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      2000, // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);


  // position: absolute;
  // top: 24%;
  // left: 20%;
  // transform: translate(-50%, -50%)
  const onLoadedData = () => {
    setIsVideoLoaded(true);
  };

  return (
    <div className='bgdiv' style={{
      display: 'flex', position: "relative", flexDirection: isDesktopOrLaptop ? 'row' : 'column-reverse',
      justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px'
    }}>
      <div style={{ marginLeft: isDesktopOrLaptop ? "20px" : "10px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", paddingTop: isDesktopOrLaptop ? "0" : "20px" }}>
        <h1 className="text-white mb-5" style={{fontSize:isDesktopOrLaptop ?"3rem":"2rem"}}>Silva Method</h1>
        
        {isDesktopOrLaptop ?
          <h2 className='effecttext'>
            {/* <TextTransition springConfig={presets.wobbly}>{TEXTS[index % TEXTS.length]}</TextTransition> */}
            {/* <TextLoop interval={2000} springConfig={{ stiffness: 180, damping: 8 }} children={["Initiate","Transform","Flourish"]} /> */}

            <TypeAnimation
              sequence={[
                'Initiate', // Types 'One'
                1000, // Waits 1s
                'Transform', // Deletes 'One' and types 'Two'
                1000, // Waits 2s
                'Flourish', // Types 'Three' without deleting 'Two'
                1000
              ]}
              wrapper="h2"
              cursor={true}
              repeat={Infinity}
              style={{ color: "white" }}
            />
          </h2>
          : <>
            {/* <TypeAnimation
              sequence={[
                'Initiate', // Types 'One'
                1000, // Waits 1s
                'Transform', // Deletes 'One' and types 'Two'
                2000, // Waits 2s
                'Flourish', // Types 'Three' without deleting 'Two'
              ]}
              wrapper="h1"
              cursor={true}
              repeat={Infinity}
              style={{ color:"white" }}
            /> */}
            <h2 style={{ color: 'white', fontSize: '25px' }}>Initiate..Flourish..</h2>
            <h2 style={{ color: 'white', fontSize: '25px' }}>Transform..</h2>
            {/* <h2 style={{color:'white'}}></h2> */}
          </>}

        {/* <h1 style={{ color: 'white', width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <TextTransition springConfig={presets.wobbly}>{TEXTS[index % TEXTS.length]}</TextTransition>
        </h1> */}



        <span className={"white-color"} style={{ fontSize: "1.1rem", textAlign: "center", width: "80%", marginBottom: "10px" }}>
          Step into enlightenment with 100+ Silva certified instructors
        </span>
        <Link to="/silva-method-courses" state={{ scroll: true }} style={{ marginBottom: isDesktopOrLaptop ? "0px" : "10px" }} className='herodiv-becomememberbtn'>Get Access to All Courses</Link>
      </div>
      <div style={{ width: isDesktopOrLaptop ? '70%' : '100%', height: "100%", display: "inline-flex" }} className='videohomediv'>

        {/* <video x-webkit-airplay="allow" style={{ width: "100%" }} preload='auto' playsInline loop muted autoPlay
        poster={require("../../../../images/frame.webp")} controls controlsList='nodownload'>
    <source media="(max-width: 424px)" srcset={mobileVideo} />
    <source media="(max-width: 767px)" srcset={biggerMobileVideo} />
    <source src={video} />
  </video> */}
        {/* <img
          src={require("../../../../images/frame.webp")}
          className="video-thumb tiny"
          alt="thumb"
          style={{ opacity: isVideoLoaded ? 0 : 1 }}
        /> */}
        <video
          x-webkit-airplay="allow" style={{ width: "100%" }} playsInline loop muted autoPlay
          onLoadedData={onLoadedData}
          poster={require("../../../../images/frame.webp")} controls controlsList='nodownload'
        >
          <source src={"/videos/silva-intro-video.mp4"}
          />
        </video>

        {/* <video x-webkit-airplay="allow" style={{ width: "100%" }} preload='auto' playsInline loop muted autoPlay
          onLoadedData={onLoadedData}
          poster={require("../../../../images/frame.webp")} controls controlsList='nodownload' src={getVideoSrc(window.innerWidth)} /> */}

      </div>
    </div>
  )
}

export default Homeherodiv