import React, { useContext, useState } from 'react'
import { Card, Image } from 'react-bootstrap'
import { BsFillReplyFill } from "react-icons/bs";
import Replybox from './Replybox';
import { AuthContext } from '../../context/AllContext';

function CommentsComp({ comments, fetchCourseComments }) {


    const [isOpenReply, setIsOpenReply] = useState(false);
    const [currentReply, setCurrentReply] = useState(null)
    const { userData } = useContext(AuthContext)

    const openReplyBox = (comm) => {
        if (currentReply) {
            if ((currentReply.comment !== comm.comment) && (currentReply.name !== comm.name)) {
                setCurrentReply(comm)
                return
            }
        }
        if (isOpenReply) {
            setIsOpenReply(false)
        } else {
            setCurrentReply(comm)
            setIsOpenReply(true)
        }
    }
    return (
        // <div className='commentswrapper'>
        <Card className='commentswrapper' style={{ width: "100%", borderRadius: "10px" }}>
            <Card.Body className="d-flex justify-content-center align-items-center flex-column">
                {
                    comments && comments.map((c, i) => (
                        <div className='singlecommentwrap'>
                            <div>
                                <Image src={c.image || require("../../images/profile-picture-vector.jpg")} className='wrapperimg2' />
                            </div>
                            <div style={{ width: "100%" }}>
                                {
                                    c.name.split(" ")[0] && c.name.split(" ")[1] && c.name.split(" ")[0] === c.name.split(" ")[1] ? (
                                        <h6>{c.name.split(" ")[0].split("@")[0]}</h6>
                                    ) : (
                                        <h6>{c?.name?.split("@")[0]}</h6>
                                    )
                                }

                                <p>
                                    {c.comment}
                                </p>
                                <div className='mb-3'>
                                    {
                                        userData && (
                                            <p className='opencommentbox' onClick={() => openReplyBox(c)}>
                                                <span> <BsFillReplyFill color='black' /></span>
                                                Reply
                                            </p>
                                        )
                                    }
                                    {
                                        isOpenReply && ((c.id === currentReply.id)) && (
                                            <div className='my-3'>
                                                <Replybox closeFunc={() => setIsOpenReply(false)} comment={c} fetchCourseComments={fetchCourseComments} />
                                            </div>
                                        )

                                    }
                                    {
                                        c.comment_reply && c.comment_reply.map((reply, i) => (
                                            <div style={{ borderLeft: "1px solid #b2bec3", paddingLeft: "20px" }}>
                                                <div className='singlecommentwrap my-3'>
                                                    <div>
                                                        <Image src={reply.image || require("../../images/profile-picture-vector.jpg")} className='wrapperimg2' />
                                                    </div>
                                                    <div>
                                                        <h4>
                                                        {
                                                                reply?.name?.split(" ")[0] && reply?.name?.split(" ")[1] && reply?.name?.split(" ")[0] === reply?.name?.split(" ")[1] ? (
                                                                    <h6>{reply.name.split(" ")[0].split("@")[0]}</h6>
                                                                ) : (
                                                                    <h6>{reply?.name?.split("@")[0]}</h6>
                                                                )
                                                            }
                                                        </h4>
                                                        <p>
                                                            {reply.comment}
                                                        </p>
                                                    </div>
                                                </div>

                                            </div>

                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    ))
                }
            </Card.Body>
        </Card>
        // </div>
    )
}

export default CommentsComp